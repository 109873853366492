import { Injectable } from "@angular/core";
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { map } from "rxjs/operators";
import { StorageService } from "app/main/module/common/service/storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class LoadService {
    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    currentPage = 0;

    protected table = "load";
    protected orderBy = "startTimeNum";
    protected isOrderByNegative = true;

    baseUrl = environment.baseUrl;

    apiUrl = this.baseUrl + "/api/v1/";
    // apiUrl = 'http://127.0.0.1:5000/api/v2/' ;

    constructor(
        private userService: StorageService,
        public httpClient: HttpClient
    ) {}

    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }

    getAllLoadDataByLabId(page) {
        const token = this.userService.getCookie("token");
        //  // // console.log(n);
        const lab_id = this.userService.getCookie("lab_id");
        //   const headers = new HttpHeaders().set('Authorization', `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this.httpClient.get(
            this.apiUrl + "loads?lab_id=" + lab_id + "&page=" + page,
            httpOptions
        );
    }

    searchLoad(searchText) {
        const token = this.userService.getCookie("token");
        const lab_id = this.userService.getCookie("lab_id");
        // // // console.log(d);
        const page = 1;
        //  // // console.log(t);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this.httpClient.get(
            this.apiUrl + "loads?lab_id=" + lab_id + "&load_id=" + searchText,
            httpOptions
        );
    }

    getLoadDetailByLabId(loadId) {
        const token = this.userService.getCookie("token");
        const lab_id = this.userService.getCookie("lab_id");
        //  // // console.log(d);
        //   const headers = new HttpHeaders().set('Authorization', `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this.httpClient.get(
            this.apiUrl + "loads?lab_id=" + lab_id + "&load_id=" + loadId,
            httpOptions
        );
    }

    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }

    // getCollection(ref, queryFn?): Observable<any[]> {
    //     return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
    //         return actions.map(a => {
    //             const data = a.payload.doc.data();
    //             const id = a.payload.doc.id;
    //             const doc = a.payload.doc;
    //             return { id, ...data as {}, doc };
    //         });
    //     }));
    // }

    // first(): any {
    //     this.itemsSubject = new BehaviorSubject([]);
    //     this.itemsObservable = this.itemsSubject.asObservable();
    //     this.pageSubject = new BehaviorSubject<number>(0);
    //     this.pageObservable = this.pageSubject.asObservable();
    //     let query = null;
    //     if (this.isOrderByNegative) {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .where(this.orderBy, '<', 0)
    //             .limit(this.ROWS));
    //     } else {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .limit(this.ROWS));
    //     }

    //     query.subscribe(data => {
    //         this.pageSubject.next(0);
    //         this.latestEntry = data[data.length - 1].doc;
    //         this.startingEntry = data[0].doc;
    //         this.itemsSubject.next(data);
    //         // scoresRef.unsubscribe();
    //     });
    // }

    // next(): void {

    //     let query = null;
    //     if (this.isOrderByNegative) {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .startAfter(this.latestEntry)
    //             .where(this.orderBy, '<', 0)
    //             .limit(this.ROWS));
    //     } else {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .startAfter(this.latestEntry)
    //             .limit(this.ROWS));
    //     }

    //     query.subscribe(data => {
    //         try {
    //             // And save it again for more queries
    //             this.latestEntry = data[data.length - 1].doc;
    //             this.startingEntry = data[0].doc;
    //             this.currentPage++;
    //             this.pageSubject.next(this.currentPage);
    //         } catch (e) {//dp something
    //         }
    //         this.itemsSubject.next(data);
    //         // scoresRef.unsubscribe();
    //     });
    // }

    // prev(): void {
    //     let query = null;
    //     if (this.isOrderByNegative) {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .startAfter(this.startingEntry)
    //             .where(this.orderBy, '<', 0)
    //             .limit(this.ROWS));
    //     } else {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .startAfter(this.startingEntry)
    //             .limit(this.ROWS));
    //     }

    //     query.subscribe(data => {
    //         try {
    //             data.reverse();
    //             // And save it again for more queries
    //             this.latestEntry = data[data.length - 1].doc;
    //             this.startingEntry = data[0].doc;
    //             this.currentPage--;
    //             this.pageSubject.next(this.currentPage);
    //         } catch (e) {//dp something
    //         }
    //         this.itemsSubject.next(data);
    //         // scoresRef.unsubscribe();
    //     });
    // }

    // getCollectionPath(): string {
    //     return 'lab/' + this.userService.getCookie("lab") + '/' + this.table;
    // }

    // getQuery(machineId: string, type: string, statusKey: string, status: string) {
    //     const query = this.getCollection(this.getCollectionPath(), ref => ref
    //         .where(this.orderBy, '<', 0)
    //         .where('machineIdStr', '==', machineId)
    //         .where('type', '==', type)
    //         // .where(statusKey, '==', status)
    //         .limit(this.ROWS));
    //     return query;
    // }

    // getLoadById(barcode): Observable<any[]> {
    //     const patientCollectionPath = 'lab/' + this.userService.getCookie("lab") + '/' + 'load';
    //     return this.afs.collection(patientCollectionPath, ref => ref.where('barcode', '==', barcode)).snapshotChanges()
    //         .pipe(map(actions => {
    //             return actions.map((a: any) => {
    //                 return a.payload.doc.data();
    //             });
    //         }));
    // }

    // getCassetteByBarcodeAndLoadNo(barcode, loadNo): Observable<any[]> {
    //     const patientCollectionPath = 'lab/' + this.userService.getCookie("lab") + '/' + 'cassette';

    //     return this.afs.collection(patientCollectionPath, ref => ref.where('barcode', '==', barcode).where('loadNo', '==', loadNo)).snapshotChanges()
    //         .pipe(map(actions => {
    //             return actions.map((a: any) => {
    //                 return a.payload.doc.data();
    //             });
    //         }));

    // }

    // .collection(LAB)
    // .document(labKey)
    // .collection(Key.LOAD)
    // .orderBy(sortBy)
    // .whereEqualTo("machineIdStr", machineId)
    // .whereEqualTo("type", type)
    // .whereEqualTo(statusKey, status)
    // .limit(100);
}
