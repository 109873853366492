import {Component, Inject, OnInit, KeyValueDiffers} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Label} from '../label';


@Component({
  selector: 'app-labelresultpopup',
  templateUrl: './labelresultpopup.component.html',
  styleUrls: ['./labelresultpopup.component.scss']
})
export class LabelresultpopupComponent implements OnInit {

    rowData: any;
    startDate: any;
    endDate: any;
    daysCount: number;
  constructor( public dialogRef: MatDialogRef<LabelresultpopupComponent>,
               @Inject(MAT_DIALOG_DATA) public data:Label) {

  }

  ngOnInit(): void {
        this.rowData = this.dialogRef.componentInstance.data.labelValue[0].data.data;
        this.startDate = this.dialogRef.componentInstance.data.days[0];
        this.endDate =  this.dialogRef.componentInstance.data.days[1];
      this.daysCount =  this.dialogRef.componentInstance.data.count? this.dialogRef.componentInstance.data.count : 1;

  }

}
