import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import "rxjs/add/operator/toPromise";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Load } from "../../load/load";
import { Globals } from "../../../common/global";
import { TeamService } from "../../team/team.service";
import * as moment from "moment-timezone";
import { ConfigService } from "../../../common/config/config.service";
import { ApiService } from "../../../common/service/api.service";
const timeZone = require("moment-timezone");
import { Subscription } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { Style } from "util";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Maintenance } from "../maintenance";
import { MaintenanceService } from "../maintenance.service";
import { Location } from "@angular/common";

@Component({
    selector: "app-maintenace-history",
    templateUrl: "./maintenace-history.component.html",
    styleUrls: ["./maintenace-history.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class MaintenaceHistoryComponent implements OnInit {
    patient: string;
    //   navigationType = this.route.snapshot.data.navType;
    navigationType = "all";
    checkHide = this.getSubscriptionType();
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns
        .filter((c) => c.hide === false)
        .map((c) => c.columnDef);
    public dataSource = new MatTableDataSource<any>();
    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    isLoading = true;
    alertSign = "";
    enableInfoMsg = false;
    protected extraCta = {
        visibility: false,
        text: "",
    };

    points = [];
    signatureImage;
    staffDropDown: any;
    staffForm: FormGroup;
    staffName = "";

    perPageData = this.global.perPageData;

    token: number;
    queryParam = {};

    isInitComplete = false;
    subscription: Subscription;
    dataN: Array<any> = [];
    isNextPressed = false;
    todayDate: Date = new Date();
    static oldData: Array<any> = [];
    static cursor: any;
    isMoreDataAvailable = true;
    pmsIntegration: boolean;
    loadingSpinner: boolean = true;
    labConfig: any;
    dateTime: any;
    getCountrycode: any = 0;
    page: number;
    searchText: string;
    history: any;
    totaldata: any;
    hasFirstDataShown = false;
    maintenanceLoadData: Maintenance[] = [];
    prev: number;
    next: number;
    count: number;
    errorMessage: any;
    paginationDisabled: boolean;

    constructor(
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        public snackBar: MatSnackBar,
        public global: Globals,
        public configService: ConfigService,
        public team: TeamService,
        private userSevice: StorageService,
        private httpClient: HttpClient,
        private paginationService: MaintenanceService,
        private apiService: ApiService
    ) {
        //  super(snackBar, configService, global);
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.isInitComplete = true;
        this.dataSource.data = [];
        this.maintenanceLoadData = [];
        // this.getCassetteConfig();
        this.isLoading = true;
        this.prev = 0;
        this.next = 1;
        this.page = 0;
        this.count = 1;
        const lab_id = this.userSevice.getCookie("lab_id");
        if (lab_id) {
            this.getAllData(1);
        } else {
            this.logout();
        }
    }

    getAllData(page) {
        this.isInitComplete = true;
        this.paginationService.getAllDatamaintenanceHistory(page).subscribe(
            (a) => {
                // // console.log(,);
                Object.entries(a).find(([key, value]) => {
                    if (key == "message" && value == "No data Found") {
                        this.paginationDisabled = true;
                    }

                    if (key == "data") {
                        this.isInitComplete = false;
                        this.dataN = value;
                        this.dataN.forEach((element) => {
                            this.maintenanceLoadData.push({
                                cellName: element.machine_id,
                                type: element.load_type,
                                machineName: element.machine_name,
                                cycleNo: element.cycle_number,
                                barcode: element.load_id,
                                createdBy: element.started_by,
                                createdOn: element.started_by.timestamp,
                                startedBy: element.started_by,
                                updatedOn: element.updated_by
                                    ? element.updated_by.timestamp
                                    : "-",
                                updatedBy: element.updated_by
                                    ? element.updated_by.name
                                    : "-",
                                aiResult: element.load_report_url,
                                status: element.jarvis_result,
                                aiLink: element.load_report_url,
                                id: "",
                                docId: "",
                            });
                        });
                    } else {
                        // // // console.log(e);
                    }
                });

                this.dataSource.data = this.maintenanceLoadData;
                //  // // console.log(a);
            },

            (err) => {
                this.errorMessage = err.error.message;
                // // console.log(e);
                if (err.status == 401) {
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
            }
        );
    }
    logout(): void {
        localStorage.clear();
        this.userSevice.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
    }

    changeDate(date): any {
        // // // console.log(e);
        var timestamp = parseInt(date);
        return (date = this.apiService.getDateStringMomentByTz(timestamp));
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector("mat-table");
            table.scrollBy({
                behavior: "smooth",
                left: 0,
                top: table.scrollHeight,
            });
        }
    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1;
        this.next++;
        this.prev++;
        this.getAllData(this.next);
        // // console.log(v);
    }

    getRowBackgroundColor(element): string {
        // if (element.deleted == true) {
        //     return "lightgrey";
        // } else
        return "#FFFFFF";
    }

    getRowFontColor(element) {
        if (element.deleted == true) {
            return "italic";
        } else return "bold";
    }

    getFontColor(element) {
        if (element.deleted == true) {
            return "#808080";
        } else return "#000000";
    }
    getCrossWord(element) {
        if (element.deleted == true) {
            return "line-through";
        } else return "overline";
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                hide: false,
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Name",
                header: "Machine Name",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //   `${element.cellName}`,
                    this.replaceWithEmpty(element.machineName),
            },
            {
                columnDef: "CycleNo",
                header: "Cycle No",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //   `${element.cycleNo}`,
                    this.replaceWithEmpty(element.cycleNo),
            },

            {
                columnDef: "Type",
                header: "Type",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //   `${element.type}`,
                    this.replaceWithEmpty(element.type),
            },
            {
                columnDef: "MaintenanceNo",
                header: "Maintenance No",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.barcode}`,
                    this.replaceWithEmpty(element.barcode),
            },

            {
                columnDef: "CreatedOn",
                header: "Created On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.updatedOn}`,
                    // this.getDateStringMomentByTz(element.updatedOn),
                    this.getDateStringMomentByTz(element.createdOn),
            },

            {
                columnDef: "CreatedBy",
                header: "Created By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    //    `${element.createdBy.name}`,
                    this.replaceWithCreatedBy(element.startedBy),
            },

            {
                columnDef: "MaintainedOn",
                header: "Updated On",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${this.getDateStringMomentByTz(element.updatedOn)}`,
                // this.getDateStringMomentByTz(element.updatedOn),
                // this.getupdatedbytime(element.updatedBy)
            },

            {
                columnDef: "MaintainedBy",
                header: "Updated By",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.updatedBy}`,
                // this.replaceWithEmptyupdated(element.updatedBy)
            },
            {
                columnDef: "Result",
                header: "Result",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    // `${element.aiResult}`,
                    this.replaceWithEmpty(element.status),
            },

            // {
            //     columnDef: 'details', header: 'Details',
            //     cell: (element: Maintenance, index: number) => `${element.barcode}`
            // },
            {
                columnDef: "aiimages",
                header: " ",
                hide: false,
                cell: (element: Maintenance, index: number) =>
                    `${element.barcode ? element.barcode : ""}`,
            },
        ];
    }

    viewMaintenaceDetail(barcode) {
        // // console.log(e);
        this.router.navigate(["/maintenancedetail"], {
            queryParams: { barcode: barcode },
        });
    }

    replaceWithCreatedBy(startedBy) {
        // // // console.log(y);

        if (startedBy != undefined) {
            // // // console.log(y);
            if (startedBy.name != undefined) {
                return startedBy.name;
            } else {
                return " ";
            }
        } else {
            return "-";
        }
    }

    replaceWithUpdatedBy(updatedBy) {
        // // // console.log(y);

        if (updatedBy != undefined) {
            // // // console.log(y);
            if (updatedBy.name != undefined) {
                return updatedBy.name;
            } else {
                return " ";
            }
        } else {
            return "-";
        }
    }

    replaceWithEmptyupdated(updatedby) {
        if (updatedby != undefined) {
            const exitingValue = updatedby.name;
            if (updatedby.name == undefined) {
                return "-";
            } else {
                return exitingValue;
            }
        } else {
            return "-";
        }
    }

    replaceWithEmpty(value) {
        const exitingValue = value;

        if (value == undefined) {
            return "-";
        } else {
            return exitingValue;
        }
    }

    getupdatedbytime(updatedBy) {
        if (updatedBy != undefined) {
            // // // console.log(y);
            if (updatedBy.time != undefined) {
                const time = this.getDateStringMomentByTz(updatedBy.time);
                return time;
            } else {
                return " ";
            }
        } else {
            return "-";
        }
    }
    getDateStringMomentByTz(time): string {
        // // // console.log(e);

        if (time != undefined && time != "-") {
            var timestamp = parseInt(time);
            if (timestamp) {
                if (timestamp < 0) timestamp = timestamp * -1;
                const localTimeZone = moment(timestamp).tz(
                    this.userSevice.getCookie("defaultTz")
                );
                return localTimeZone.format("DD-MM-YYYY | HH:mm");
            }
        } else {
            return time;
        }
    }
    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }

    updateRow(row): any {
        // setDeriveFields(row);
    }

    // ngOnDestroy() {
    //     this.subscription.unsubscribe();
    //     MaintenaceHistoryComponent.oldData = this.dataSource.data;
    //     // MaintenanceHistoryComponent.cursor = this.infiniteScroll.getCursor();
    // }

    getTitleMatIcon(): string {
        return "art_track";
    }

    getTitle(): string {
        return "Sterilizers Maintenance History";
    }

    getSearchText(): string {
        return "Search Patient via name";
    }

    addZeroToHourAndMinutes(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
        // if (time < 10) {
        //     return '0' + time;
        // }
        // return time;
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    private handleError(error: Error): void {
        // this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    changeTimestampToDate(timestamp) {
        if (timestamp) {
            // new Date('03/17/2021');
            // return timeZone(timestamp).tz(this.userSevice1Child.getCookie('defaultTz')).format('DD/MM/YYYY');
            return moment(timestamp).format("DD/MM/YYYY | HH:mm");
        }
        return "";
    }

    protected getPaginationService() {
        return this.paginationService;
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        // this.handleEnterSearch(event);
    }

    getSubscriptionType() {
        // // console.log(e);
        switch (this.navigationType) {
            case "screening":
                return true;
            default:
                return false;
        }
    }
}
