import {
    Component,
    Inject,
    Input,
    OnInit,
    ChangeDetectorRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PatientService } from "../patient.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Patient } from "../patient";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "app/main/module/common/service/storage.service";
const timeZone = require("moment-timezone");
import {
    DateAdapter,
    ErrorStateMatcher,
    MAT_DATE_FORMATS,
} from "@angular/material/core";
import {
    APP_DATE_FORMATS,
    AppDateAdapter,
} from "../../report/format-datepicker";
import * as moment from "moment";
import { ApiService } from "../../../common/service/api.service";
import { format } from "date-fns";
import { DateTime } from "luxon";

@Component({
    selector: "app-edit-patient-dialog",
    templateUrl: "./edit-patient-dialog.component.html",
    styleUrls: ["edit-patient-dialog.component.scss"],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
})
export class EditPatientDialogComponent implements OnInit {
    [x: string]: any;
    page = 0;
    patientForm: FormGroup;
    platform = "web";
    comboId: string;
    @Input()
    min: "D" | null;
    today = new Date();
    dobStamp: any;
    dobDate: any;
    dobString: any;
    rowId: any;
    deleted: boolean = false;
    addedOn: any;
    addedBy: any;
    memberInfo: any;
    hasEditDataShown: boolean;
    errorMessage: any;
    selectedDate: any;

    constructor(
        public dialogRef: MatDialogRef<EditPatientDialogComponent>,
        private _formBuilder: FormBuilder,
        private changeDetectorRefs: ChangeDetectorRef,
        public patientService: PatientService,
        public snackBar: MatSnackBar,
        public route: Router,
        private userService: StorageService,
        private cookieService: CookieService,
        private apiService: ApiService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: Patient
    ) {
        this.patientForm = this._formBuilder.group({
            // firstName: ['', [Validators.required, Validators.pattern(/^\S+/)], ''],
            firstName: [
                "",
                [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]/)],
            ],
            id: ["", [Validators.required, Validators.pattern(/^\S+/)], ""],
            lastName: ["", [Validators.required, Validators.pattern(/^\S+/)]],
            emailId: [
                "",
                [
                    Validators.email,
                    Validators.pattern(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                    ),
                ],
            ],
            // mobileNo: ['', [ Validators.pattern(/^\d{10}$/)], ''],
            mobileNo: ["", [Validators.pattern("^((\\+91-?))?[0-9]{10}$")]],
            dob: [""],
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
        // window.location.reload();
        // this.router.navigate(['/patient']);
    }
    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.comboId = this.dialogRef.componentInstance.data.comboId;
        // const comboData = this.dialogRef.componentInstance.data.comboId.toLowerCase();
        const comboData = this.dialogRef.componentInstance.data.comboId;
        // // console.log(a);

        this.patientService.getPatientdataById(comboData).subscribe(
            (a) => {
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        this.memberInfo = value;
                        // // console.log(o);
                        this.memberInfo.forEach((element) => {
                            // // console.log(t);
                            this.selectedDate = element.date_of_birth;

                            const tempdob = this.selectedDate.split("/");
                            var newDate = new Date(
                                tempdob[2],
                                tempdob[1] - 1,
                                tempdob[0]
                            ).getTime();
                            // // console.log(e);
                            const dob = timeZone(Math.abs(newDate))
                                .tz("Asia/Kolkata")
                                .format("MM/DD/YYYY");
                            // // // console.log(b);
                            const calDobRequireFormate = new Date(dob);
                            // // console.log(e);

                            this.patientForm.setValue({
                                firstName: element.first_name,
                                id: element.patient_id,
                                lastName: element.last_name,
                                emailId: element.email_id,
                                // mobileNo: ['', [ Validators.pattern(/^\d{10}$/)], ''],
                                mobileNo: element.mobile_number,
                                dob: calDobRequireFormate,
                                // dob: 'Mon May 01 2023 00:00:00 GMT+0530 (India Standard Time)',
                            });
                            this.hasEditDataShown = true;
                        });
                    } else {
                        // // console.log(e);
                    }
                });
            },

            (err) => {
                this.errorMessage = err.error.message;
            }
        );
    }

    editPatientDetail() {
        // // console.log(b);
        if (this.patientForm.value.dob != "Invalid Date") {
            const date = new Date(this.patientForm.value.dob);
            this.dobString = moment(
                this.patientForm.value.dob,
                "DD/MM/YYYY"
            ).format("DD/MM/YYYY");
            this.dobStamp = date.getTime();
        } else {
            this.dobString = "";
            // // console.log(");
        }

        // return new Promise((resolve, reject) => {
        const patientData = {
            patient_id: this.comboId,
            update_data: {
                first_name: this.patientForm.value.firstName
                    .trim()
                    .toUpperCase(),
                last_name: this.patientForm.value.lastName.trim().toUpperCase(),
                patient_id: this.patientForm.value.id.trim().toUpperCase(),
                email_id: this.patientForm.value.emailId,
                mobile_number: this.patientForm.value.mobileNo
                    ? this.patientForm.value.mobileNo.toString()
                    : "",
                date_of_birth: this.dobString,
                // docId: this.patientForm.value.id + this.patientForm.value.firstName + this.patientForm.value.lastName,
                platform: this.platform,
                deleted: this.deleted,
                // updated_by: this.userService.getCookie("email")
                //     ? this.userService.getCookie("email")
                //     : "NA",
                //  updated: (-1 * new Date().getTime()),
            },
        };

        this.patientService.updatePatientData(patientData).subscribe((data) => {
            // // console.log(a);
            Object.entries(data).find(([key, value]) => {
                if (
                    key == "status" &&
                    (value == "success" || value == "Success")
                ) {
                    const msg = "Member Updated Successfully";
                    this.openSnackBar(msg, "OK");
                    this.hasEditDataShown = false;
                    // this.loadingSpinner=false;
                    this.patientForm.reset();
                    window.location.reload();
                    // this.getAllData();
                } else {
                }
            });
        });
    }

    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    numericOnly(event): boolean {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }
}
