import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {
    debounceTime,
    distinctUntilChanged,
    finalize,
    map,
    startWith,
    switchMap,
    tap,
} from "rxjs/operators";

import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { fromEvent, Observable, Subject, Subscription } from "rxjs";
import { Location } from "@angular/common";
import { fuseAnimations } from "@fuse/animations";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "../../../common/service/storage.service";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
// import { AngularFirestore } from "@angular/fire/firestore";
import { PatientService } from "../../patient/patient.service";
import { Patient } from "../../patient/patient";
import { Cassette } from "../cassette";
import { StatusService } from "../../status/status.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogUtil } from "../../../../util/DialogUtil";
import { MatDialog } from "@angular/material/dialog";
import * as copy from "copy-to-clipboard";
import { BaseComponent } from "../../../base/base.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { ApiService } from "../../../common/service/api.service";
import { TextUtils } from "../../../../util/TextUtils";
import { CookieService } from "ngx-cookie-service";
import { CassetteListService } from "../list/cassette-list.service";
import { time } from "@ngtools/webpack/src/benchmark";
import { AssignService } from "../assign/assign.service";
const timeZone = require("moment-timezone");

export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: "assign-table",
    templateUrl: "./assign.component.html",
    styleUrls: ["./assign.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AssignComponent
    extends BaseComponent
    implements OnInit, OnDestroy
{
    ELEMENT_DATA: Cassette[] = [];
    searchMoviesCtrl = new FormControl();
    filteredMovies: any;
    selectedPatient: string;
    dataSource = new MatTableDataSource<Cassette>();
    selection = new SelectionModel<Cassette>(true, []);
    departmentList: Array<any> = [];
    getStatus: any;
    getError: any;
    assigncassetteData: Cassette[] = [];
    stateCtrl = new FormControl();
    filteredStates: Observable<State[]>;
    // filteredPatients: Observable<Patient[]>;

    filteredPatients: Array<any> = [];
    // departmentArray: Array<any> = [];
    urlData = "";
    HYPHEN = "-";
    HASH = "#";
    enableAssign = false;
    settingFormOne: FormGroup;
    department_name: any;
    recived_name: any;

    states: State[] = [
        {
            name: "Arkansas",
            population: "2.978M",
            // https://commons.wikimedia.org/wiki/File:Flag_of_Arkansas.svg
            flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arkansas.svg",
        },
        {
            name: "California",
            population: "39.14M",
            // https://commons.wikimedia.org/wiki/File:Flag_of_California.svg
            flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg",
        },
    ];
    columns = [
        {
            columnDef: "Sr",
            header: "Sr.",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
        {
            columnDef: "Barcode",
            header: "Barcode",
            cell: (element: Cassette, index: number) => `${element.barcode}`,
        },
        {
            columnDef: "type2",
            header: "Type",
            cell: (element: Cassette, index: number) => `${element.type}`,
        },
        {
            columnDef: "Load No",
            header: "Load No",
            cell: (element: Cassette, index: number) => `${element.loadNo}`,
        },
        {
            columnDef: "Cycle No",
            header: "Cycle No",
            cell: (element: Cassette, index: number) => `${element.cycleNo}`,
        },
        {
            columnDef: "Result",
            header: "Result",
            cell: (element: Cassette, index: number) => `${element.platform}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getLoadColor(element)}`,
        },
        {
            columnDef: "Date",
            header: "Date|Time",
            cell: (element: Cassette, index: number) =>
                `${this.changeDate(element.dateTimeNum)}`,
        },
        // {
        //     columnDef: "Time",
        //     header: "Time",
        //     cell: (element: Cassette, index: number) => `${element.time}`,
        // },

        //   {
        //       columnDef: "BD",
        //       header: "BD",
        //       cell: (element: Cassette, index: number) =>
        //           `${element.barcodeBd ? element.barcodeBd : ""}`,
        //       textColor: (element: Cassette, index: number) =>
        //           `${this.getSingleLoadColor(element.bd_status)}`,
        //   },
        //   {
        //       columnDef: "BI",
        //       header: "BI(Spore Test)",
        //       cell: (element: Cassette, index: number) =>
        //           `${element.barcodeBi ? element.barcodeBi : ""}`,
        //       textColor: (element: Cassette, index: number) =>
        //           `${this.getSingleLoadColor(element.bi_status)}`,
        //   },
        //   {
        //       columnDef: "EI",
        //       header: "PCD",
        //       cell: (element: Cassette, index: number) =>
        //           `${element.barcodeEi ? element.barcodeEi : ""}`,
        //       textColor: (element: Cassette, index: number) =>
        //           `${this.getSingleLoadColor(element.ei_status)}`,
        //   },

        {
            columnDef: "StatusCassette",
            header: "Status",
            cell: (element: Cassette, index: number) => `${element.status}`,
        },
        // `${this.page * 10 + index + 1}`
        {
            columnDef: "Remove",
            header: "Remove",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
    ];
    displayedColumns = this.columns.map((c) => c.columnDef);

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    @ViewChild("input", { static: true }) input: any;

    @ViewChild("filter", { static: true })
    filter: ElementRef;
    isLoading = false;
    errorMsg: string;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    page = 0;

    patient: Patient;
    barcode: string;
    searchText: string;
    map = new Map<string, Cassette>();
    isEnterPressed = false;
    isAssignedPressed = false;
    platform = "web";
    settingDto: any;
    selected: any;
    enablePatientEmail = "";
    enableLabCheckoutEmail = "";

    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Cassette[]>;
    listLength: number = 0;
    currentDepartment: string;
    receiverName: string;
    errorMessage: any;
    isLoginFailed: boolean;
    patientlist: Array<any> = [];
    result: string;
    status: string;
    resultassigned: Object;
    can_assign_if_incubator_pending: any;
    searchInputSubscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        public apiservice: ApiService,
        private userService: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: AssignService,
        private patientService: PatientService,
        private changeDetectorRefs: ChangeDetectorRef,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private statusService: StatusService,
        private location: Location,
        public dialog: MatDialog,
        private el: ElementRef,
        private configService: ConfigService,
        private globals: Globals,
        private apiService: ApiService,
        private cookieService: CookieService,
        public cassetteListService: CassetteListService
    ) {
        super(configService, globals);
        // Set the private defaults
        // // console.log(');

        this._unsubscribeAll = new Subject();
        this.filteredStates = this.stateCtrl.valueChanges.pipe(
            startWith(""),
            map((state) =>
                state ? this._filterStates(state) : this.states.slice()
            )
        );
        // // console.log(');
        this.getCassetteConfig();
    }

    changeDate(date): any {
        // // // console.log(e);
        var timestamp = parseInt(date);
        return (date = this.apiService.getDateStringMomentByTz(timestamp));
    }

    onKeyDown(event: KeyboardEvent): void {
        // // console.log(t);
        if (event.key === "Backspace") {
            // Check if the input value is empty
            // // console.log(t);
            if (!this.selectedPatient || this.selectedPatient.trim() === "") {
                this.filteredPatients = [];
            }
        }
    }

    ngOnInit(): void {
        this.selectedPatient = "";
        this.assigncassetteData = [];
        this.dataSource.data = [];
        this.listLength = 0;
        //   this.settingFormOne = this.formBuilder.group({
        //       departmentListName: ['', Validators.required],
        //       receiverName: ['', Validators.pattern('^[a-zA-Z \-\']+')]
        //   });

        // this.searchPharmacyPatient('Alaa');
        this.input.nativeElement.focus();
        // // console.log((););
        this.urlData = this.route.snapshot.queryParams.barcode;
        if (this.urlData !== "" && this.urlData !== undefined) {
            this.barcode = this.urlData;
            this.selectBarcode();
        }

        this.searchPharmacyPatient();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if (this.searchInputSubscription) {
            this.searchInputSubscription.unsubscribe();
        }
    }

    searchPharmacyPatient() {
        this.searchInputSubscription = fromEvent(
            this.filter.nativeElement,
            "input"
        )
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                switchMap((event: any) => {
                    const enteredValue = event.target.value.trim();
                    if (!enteredValue) {
                        this.filteredPatients = [];
                        return [];
                    }
                    return this.paginationService.searchPatient(enteredValue);
                })
            )
            .subscribe(
                (response: any) => {
                    this.filteredPatients = [];
                    const data = response?.data;
                    if (data) {
                        this.patientlist = data;
                        this.patientlist.forEach((el) => {
                            this.filteredPatients.push({
                                patient_id: el.patient_id,
                                first_name: el.first_name,
                                last_name: el.last_name,
                            });
                        });
                        // // console.log(s);
                    }
                },
                (err) => {
                    this.errorMessage = err.error.message;
                    this.isLoginFailed = true;
                }
            );
    }

    keyPressAlphanumeric(event) {
        var inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-Z \-\']/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    back(): void {
        this.location.back();
    }

    //   onclickgetDocuments() {
    //       const searchKeywrd = this.selectedPatient.valueOf();
    //       // // console.log(d);
    //       // this.filteredPatients = this.apiservice.getDocumentsApi().subscribe({
    //       this.apiservice.getDocumentsApi().subscribe({
    //           next: data => {
    //               // // // console.log(a);
    //               data['data'].forEach(element => {
    //                   // this.departmentList.push(element.display_name)
    //                   if (searchKeywrd.substring(0).includes(element.display_name.substring(0))) {
    //                       // // // console.log(e);
    //                       var obj = {
    //                           "id": element._id,
    //                           "firstName": element.display_name,
    //                           "lastName": element.name
    //                       }
    //                       this.filteredPatients.push(obj)
    //                   } else {
    //                       this.openSnackBar("Department Name Not Found.", "OK");
    //                   }
    //                   // this.filteredPatients = element.display_name
    //                   // // console.log(s);
    //               });

    //           },
    //           error: HttpErrorResponse => {
    //               this.getStatus = HttpErrorResponse.error.status;
    //               this.getError = HttpErrorResponse.error.error;
    //           }
    //       });
    //       return this.filteredPatients;
    //   }

    private _filterStates(value: string): State[] {
        const filterValue = value.toLowerCase();
        return this.states.filter(
            (state) => state.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    @HostListener("document:keydown", ["$event"])
    handleKeyboardEven(event: KeyboardEvent) {
        const key = event.key;
        if (key === "Backspace" || key === "Delete") {
        }
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        const key = event.key;
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("Barcode")
        ) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.barcode.length > 0) {
                this.selectBarcode();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.barcode = "";
    }

    clearInputSearchPatient() {
        this.selectedPatient = "";
        this.filteredPatients = [];
    }

    ngAfterViewInit(): void {
        // this.barcode = this.urlData;
    }

    onClickAssign(): void {
        // // console.log(t);
        const inputString = this.selectedPatient;

        const firstDelimiterIndex = inputString.indexOf("#");
        const secondDelimiterIndex = inputString.indexOf(
            "#",
            firstDelimiterIndex + 1
        );

        const variable1 = inputString.substring(0, firstDelimiterIndex);
        // // console.log(1);
        const variable2 = inputString.substring(
            firstDelimiterIndex + 1,
            secondDelimiterIndex
        );
        const variable3 = inputString.substring(secondDelimiterIndex + 1);
        // const idNumber = parseInt(variable1, 10);
        // // // console.log(r);

        const fullName = `${variable2} ${variable3}`;
        // // // console.log(e);

        this.submit(variable1, fullName);
    }

    submit(patientId, patient_fullName): void {
        const list: string[] = [];
        //department name, reciver name, comment

        this.selection.selected.forEach((element) => {
            // // console.log(e);
            if (list.indexOf(element.barcode) < 0) {
                list.push(element.barcode);
                // // console.log(t);
            }
        });
        this.assign(patientId, patient_fullName);
    }

    selectBarcode(): void {
        // // console.log(e);
        if (this.barcode === "" || this.barcode === undefined) {
            alert("Please enter barcode first");
            return;
        }
        const value = this.barcode.replace(/\s/g, "").toUpperCase();
        // // console.log(e);

        this.barcode = "";
        let isCassetteAlready = false;
        this.dataSource.data.forEach((cassette) => {
            // // console.log(e);
            // // console.log(e);
            if (cassette.barcode.includes(value)) {
                isCassetteAlready = true;
            }
        });
        if (isCassetteAlready) {
            alert(" This Sets has been already added " + value);
            return;
        } else {
        }

        this.paginationService.getCassetteEntryObserable(value).subscribe(
            (a) => {
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        if (value && value.length > 0) {
                            //       // // // console.log(e);

                            const cass: Cassette = value;

                            // // console.log(s);
                            //       // this.updateRow(cass);
                            this.enableAssign = true;
                            this.addCassetteDirect(cass);
                            //       const eStatus: CassetteStatus =
                            //           this.statusService.getCassetteStatus(cass);
                            //       if (this.statusService.canAssign(cass)) {
                            //           // // // console.log(G);
                            //           if (
                            //               eStatus ===
                            //               CassetteStatus.IN_PROGRESS_BI_PENDING
                            //           ) {
                            //               // // console.log(');
                            //               const dialogUtil = new DialogUtil();
                            //               dialogUtil.openConfirmationDialog(
                            //                   this.dialog,
                            //                   "Incubator Result for the cassette is pending. Do you still want to assign this cassette?",
                            //                   {
                            //                       onConfirm: () => {
                            //                           this.addCassetteDirect(cass);
                            //                       },
                            //                       onCancel: () => {
                            //                           this.enableAssign = false;
                            //                       },
                            //                       onAlways: () => { },
                            //                   }
                            //               );
                            //           } else {
                            //               // // // console.log(');
                            //               this.addCassetteDirect(cass);
                            //           }
                            //       } else if (eStatus === CassetteStatus.USED) {
                            //         //   // // console.log(');
                            //           this.enableAssign = false;
                            //           alert("Cassette is already assigned: " + value);
                            //       } else {
                            //           alert(
                            //               "Cassette current status is " +
                            //               eStatus.valueOf()
                            //           );
                            //       }
                        } else {
                            alert("Cassette(s) not found " + value);
                        }
                    }
                });

                // subs1.unsubscribe();
            },

            (error) => {
                //dp something
            },
            () => {
                //dp something
            }
        );
        this.barcode = "";
    }

    addCassetteDirect(cass) {
        cass.forEach((element) => {
            this.result = this.getCassetteResult(element);
            // // console.log(t);

            if (element.cassette_status == "Assigned") {
                if (this.result == "Pending") {
                    this.status = "Used(P)";
                } else {
                    this.status = "Used";
                }
            } else if (element.cassette_status == "Used") {
                if (this.result == "Pending") {
                    this.status = "Used(P)";
                } else {
                    this.status = "Used";
                }
            } else if (
                element.cassette_status != "Used" &&
                element.cassette_status != "Assigned" &&
                element.cassette_status != "Spoiled"
            ) {
                this.status = this.getLoadStatus(element);
            } else if (element.cassette_status == "Spoiled") {
                this.status = "Spoiled";
            }
            if (element.cassette_type == undefined) {
                element.cassette_type = "-";
            }
            if (this.can_assign_if_incubator_pending == true) {
                // // console.log( );
                if (
                    this.status == "Available" ||
                    this.status == "In Progress(P)"
                ) {
                    this.assigncassetteData.push({
                        assigned_to_department: element.assigned_to_department,
                        barcode: element.barcode,
                        added: element.added_by,
                        startedBy: element.created_by,
                        assigned: element.assigned_by,
                        updated: element.updated_by,
                        spoiled: element.spoiled_by,
                        comment: "",
                        receiverName: "",
                        departmentName: "",
                        department: element.assigned_to_department,
                        print_title: element.print_title,
                        barcodeBd: element.bd_barcode,
                        barcodeBi: element.bi_barcode,
                        barcodeEi: element.ei_barcode,
                        machineName: element.machine_name,
                        status: this.status,
                        bd_status: element.bd_status,
                        bi_status: element.bi_status,
                        ei_status: element.ei_status,
                        isAssignDone: element.assigned_by,
                        unassigned_by: element.unassigned_by,
                        isSpoiled: element.is_spoiled,
                        type: element.cassette_type,
                        version: 0,
                        cycleNo: element.cycle_number,
                        loadNo: element.load_number,
                        program: element.cassette_program,
                        dateTimeNum: element.timestamp,
                        loadIdStr: "",
                        machineIdStr: "",
                        plateform: "",
                        platform: this.result,
                        verified: "",
                        createdUser: "",
                        patientId: "",
                        patientName: "",
                        cassetteID: "",
                        updatedBy: "",
                        time: "",
                        date: element.timestamp,
                        id: "",
                        docId: "",
                    });
                } else {
                    window.alert(
                        "You can't add " +
                            element.barcode +
                            " Cassette, Its current status is already " +
                            this.status
                    );
                }
            } else if (
                this.can_assign_if_incubator_pending == false &&
                this.status == "Available"
            ) {
                this.assigncassetteData.push({
                    assigned_to_department: element.assigned_to_department,
                    barcode: element.barcode,
                    added: element.added_by,
                    startedBy: element.created_by,
                    assigned: element.assigned_by,
                    updated: element.updated_by,
                    spoiled: element.spoiled_by,
                    comment: "",
                    receiverName: "",
                    departmentName: "",
                    department: element.assigned_to_department,
                    print_title: element.print_title,
                    barcodeBd: element.bd_barcode,
                    barcodeBi: element.bi_barcode,
                    barcodeEi: element.ei_barcode,
                    machineName: element.machine_name,
                    status: this.status,
                    bd_status: element.bd_status,
                    bi_status: element.bi_status,
                    ei_status: element.ei_status,
                    isAssignDone: element.assigned_by,
                    unassigned_by: element.unassigned_by,
                    isSpoiled: element.is_spoiled,
                    type: element.cassette_type,
                    version: 0,
                    cycleNo: element.cycle_number,
                    loadNo: element.load_number,
                    program: element.cassette_program,
                    dateTimeNum: element.timestamp,
                    loadIdStr: "",
                    machineIdStr: "",
                    plateform: "",
                    platform: this.result,
                    verified: "",
                    createdUser: "",
                    patientId: "",
                    patientName: "",
                    cassetteID: "",
                    updatedBy: "",
                    time: "",
                    date: element.timestamp,
                    id: "",
                    docId: "",
                });
            } else {
                window.alert(
                    "You can't add " +
                        element.barcode +
                        " Cassette, Its current status is already " +
                        this.status +
                        " Or Incubator Pending Is False"
                );
            }
        });

        this.dataSource.data = this.assigncassetteData;
        // // // console.log(a);
        this.listLength = this.dataSource.data.length;
        // // // console.log(a);
        this.dataSource.filter = "";
        this.selection.toggle(cass);
        // // console.log(d);
    }

    assign(patientId, patient_fullName): void {
        this.isAssignedPressed = true;
        const time = new Date().getTime();
        const barcodes = [];
        this.assigncassetteData.forEach((element) => {
            barcodes.push(element.barcode);
            // // console.log(s);
        });

        let i = 0;
        for (i of barcodes) {
            // // console.log(d);

            const updateData = {
                barcode: i,
                update_data: {
                    cassette_status: "Assigned",
                    //   assigned_to_department: this.department_name,
                    is_assigned_to_patient: true,
                    // assigned_to_patient:patient_fullName,
                    patient: {
                        id: patientId.toString(),
                        name: patient_fullName,
                        timestamp: time.toString(),
                    },
                    timestamp: time.toString(),
                    patient_assigned_by: {
                        id: this.userService.getCookie("email"),
                        name: this.userService.getCookie("name"),
                        timestamp: time.toString(),
                    },
                    patient_unassigned_by: {},
                },
            };

            // // console.log(a);
            this.paginationService.assignSets(updateData).subscribe((a) => {
                // // console.log(a);
                //   this.resultassigned = a;
                //   if ( this.resultassigned) {
                //     this.showAlert("Cassette(s) assigned to patient successfully");
                this.barcode = "";
                // }
            });
        }
        this.selection.clear();
        this.selectedPatient = "";
        while (this.dataSource.data.length > 0) {
            this.dataSource.data.pop();
            this.assigncassetteData = [];
            this.listLength = 0;
            this.currentDepartment = "";
            this.receiverName = "";
        }
        this.dataSource.filter = "";

        this.isAssignedPressed = false;

        // this.ngOnInit();
        // }
        // })

        this.openSnackBar("Cassette(s) assigned successfully.", "OK");

        // check for config

        //     this.apiService
        //         .shareBarcodeLinkUrl(
        //             this.userService.getCookie("lab"),
        //             barcodes,
        //             //  this.departmentList.name,
        //             this.userService.getCookie("center")
        //         )
        //         .then((dataResult) => {
        //             if (
        //                 this.enablePatientEmail === "Yes" &&
        //                 this.enableLabCheckoutEmail === "Yes"
        //             ) {
        //                 const message = "Mail has been sent to lab and patient !";
        //                 this.openSnackBar(message, "OK");
        //             } else if (this.enableLabCheckoutEmail === "Yes") {
        //                 const message = "Mail has been sent to lab!";
        //                 this.openSnackBar(message, "OK");
        //             } else if (this.enablePatientEmail === "Yes") {
        //                 const message = "Mail has been sent to patient!";
        //                 this.openSnackBar(message, "OK");
        //             }
        //         });

        //     this.barcode = "";
    }

    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => {},
            onCancel: () => {},
            onAlways: () => {},
        });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }
    updateRow(row): any {
        row.date = timeZone(Math.abs(row.timestamp))
            .tz(this.userService.getCookie("defaultTz"))
            .format("DD-MM-YYYY");
        row.time = timeZone(Math.abs(row.timestamp))
            .tz(this.userService.getCookie("defaultTz"))
            .format("HH:mm");
        //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    getCassetteConfig() {
        this.apiService.getCassetteconfigdata().subscribe((a) => {
            // // console.log(a);
            Object.entries(a).find(([key, value]) => {
                if (key == "data") {
                    value.forEach((element) => {
                        this.can_assign_if_incubator_pending =
                            element.cassette.can_assign_if_incubator_pending;
                        // // console.log(g);
                        Object.entries(element.cassette).find(
                            ([key, value]) => {}
                        );
                    });
                }
            });
        });
    }

    displayFn(patient: Patient) {
        if (patient) {
            return patient.first_name;
        }
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }

    copyCassette() {
        var copyText = "";
        // // console.log(h);
        if (this.dataSource.data.length === 0) {
            alert("No cassette added");
            return;
        }

        for (var i = 0; i < this.dataSource.data.length; i++) {
            const cassette = this.dataSource.data[i];
            // // console.log(e);
            copyText = copyText + "Barcode: " + cassette.barcode;
            copyText = copyText + ", Program: " + cassette.program;
            copyText = copyText + ", Type: " + cassette.type;
            copyText = copyText + ", Sterilizer: " + cassette.machineName;
            copyText = copyText + ", Cycle Number: " + cassette.cycleNo;
            copyText = copyText + ", Load Number: " + cassette.loadNo;
            // cassette.added.time = moment(cassette.added.time).format("DD-MM-YYYY | HH:mm");
            // // console.log("););
            if (cassette.startedBy) {
                copyText =
                    copyText +
                    ", Load Start Date | Time: " +
                    this.changeDate(cassette.startedBy.timestamp);
                //   timeZone(cassette.startedBy.timestamp)
                //       .tz(this.userService.getCookie("defaultTz"))
                //       .format("DD-MM-YYYY | HH:mm");
                copyText =
                    copyText + ", Load Started By: " + cassette.startedBy.name;
            } else if (cassette.added) {
                copyText =
                    copyText +
                    ", Load Start Date | Time: " +
                    this.changeDate(cassette.added.timestamp);
                //   timeZone(cassette.added.timestamp)
                //       .tz(this.userService.getCookie("defaultTz"))
                //       .format("DD-MM-YYYY | HH:mm");
                copyText =
                    copyText + ", Load Started By: " + cassette.added.name;
            }

            // cassette.updated.time = moment(cassette.updated.time).format("DD-MM-YYYY | HH:mm");
            copyText =
                copyText +
                ", Processed Date | Time: " +
                this.changeDate(cassette.updated.timestamp);
            copyText = copyText + ", Processed By: " + cassette.updated.name;

            const assignedTime = timeZone(timeZone.now())
                .tz(this.userService.getCookie("defaultTz"))
                .format("DD-MM-YYYY | HH:mm");
            // copyText = copyText + ', Assigned Time: ' + assignedTime;
            copyText =
                copyText +
                ", Assigned By: " +
                this.userService.getCookie("name");

            copyText = copyText + "\n";
        }

        copy(copyText);
        // // console.log(t);
        alert("Copied");
    }

    // remove row from the to be assigned table
    remove(id) {
        const index = id - 1; // index starts from 0
        // const index = this.dataSource.data.indexOf(id);  // got index
        this.ELEMENT_DATA = this.dataSource.data.splice(index, 1); // what id to delete and which one to delete
        this.dataSource = new MatTableDataSource(this.dataSource.data); // refresh the data
        // removing cassette from selction array as well
        this.selection.selected.splice(index, 1);
    }

    has2Hash(searchPatient: string) {
        return searchPatient.split("#").length === 3;
    }
}
