import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Globals } from "app/main/module/common/global";
@Component({
    selector: "app-opendialouge",
    templateUrl: "./opendialouge.component.html",
    styleUrls: ["./opendialouge.component.scss"],
})
export class OpendialougeComponent implements OnInit {
    contentLength: string = "";
    selectionLength: string = "";
    finalBarcode: string = "";
    successMessage: string = "";
    showFinalPrint: string = "";
    generatedPrintTitle: string = "";

    constructor(
        public dialogRef: MatDialogRef<OpendialougeComponent>,
        private route: ActivatedRoute,
        public globals: Globals,
        public dialog: MatDialog
    ) {}
    message = "you all selected";
    ngOnInit() {
        this.contentLength = sessionStorage.getItem("contentLength");
        // // console.log(h);
        this.selectionLength = sessionStorage.getItem("selectionLength");
        // // console.log(h);
        this.successMessage = sessionStorage.getItem("successMessage");
        this.finalBarcode = sessionStorage.getItem("lastUsedBarcode");
        this.showFinalPrint = sessionStorage.getItem("showFinalPrint");
        this.generatedPrintTitle = sessionStorage.getItem(
            "generatedPrintTitle"
        );
    }
}
