import { Injectable } from "@angular/core";
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Patient } from "./patient";
import { map } from "rxjs/operators";
import { StorageService } from "../../common/service/storage.service";
import { reject } from "lodash";
import { resolve } from "path";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class PatientService {
    table = "patient";
    orderBy = "firstName";
    ROWS = 10;
    private searchSubject: BehaviorSubject<Patient[]>;
    public searchObservable: Observable<Patient[]>;
    public searchedText: string = null;
    currentPage = 0;
    baseUrl = environment.baseUrl;

    apiUrl = this.baseUrl + "/api/v1/";
    apiUrlPython = this.baseUrl + "/api/v1/patients?";
    constructor(
        private userServiceChild: StorageService,
        private httpClient: HttpClient
    ) {
        this.table = "patient";
        this.orderBy = "firstName";
    }

    getAllpatient(page) {
        const token = this.userServiceChild.getCookie("token");
        // const lab_id = this.userServiceChild.getCookie('lab');
        const labId = this.userServiceChild.getCookie("lab_id");
        // // console.log(d);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        //    const email = this.userService.getCookie("email")
        //    // // console.log(l);
        return this.httpClient.get(
            this.apiUrlPython +
                "page=" +
                page +
                "&per_page=10" +
                "&lab_id=" +
                labId,
            { headers }
        );
    }

    getPatientdataById(id) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        //  // // console.log(d);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        //    const email = this.userService.getCookie("email")
        //    // // console.log(l);
        return this.httpClient.get(
            this.apiUrlPython + "lab_id=" + lab_id + "&patient_id=" + id,
            { headers }
        );
    }

    searchPatient(searchText) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        const page = 1;
        // // console.log(t);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        //    const email = this.userService.getCookie("email")

        return this.httpClient.get(
            this.apiUrlPython +
                "lab_id=" +
                lab_id +
                "&page=" +
                page +
                "&search=" +
                searchText,
            { headers }
        );
    }
    updatePatientData(updatedata) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        //  // // console.log(a);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        //    const email = this.userService.getCookie("email")
        //    // // console.log(l);
        return this.httpClient.put(
            this.apiUrlPython + "lab_id=" + lab_id,
            updatedata,
            { headers }
        );
    }

    addPatient(patientData) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        // // // console.log(a);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.post(
            this.apiUrlPython + "lab_id=" + lab_id,
            patientData,
            { headers }
        );
    }

    deletePatientFromLab(patient_id, labId) {
        const token = this.userServiceChild.getCookie("token");
        //  // // console.log(d);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.delete(
            this.apiUrlPython + "lab_id=" + labId + "&patient_id=" + patient_id,
            { headers }
        );
    }

    // getCollection(ref, queryFn?): Observable<any[]> {
    //     return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
    //         return actions.map(a => {
    //             const data = a.payload.doc.data();
    //             const id = a.payload.doc.id;
    //             const doc = a.payload.doc;
    //             return { id, ...data as {}, doc };
    //         });
    //     }));
    // }
    // getSearchCollection(patient): Observable<any[]> {
    //     if (patient) {
    //     return this.getCollection(this.getCollectionPath(), (ref) =>
    //     ref .where("deleted", "==", false).where( this.patientSearchQuery(patient.toUpperCase()),
    //     "==",
    //     true

    //     )

    //     .limit(1000)

    //     );

    //     // .limit(this.ROWS));

    //     } else {

    //     return this.getCollection(this.getCollectionPath(), (ref) =>

    //     ref.where("deleted", "==", false).limit(this.ROWS)

    //     );

    //     }

    //     }

    // getPatient(patient): Observable<Patient[]> {
    //     this.searchSubject = new BehaviorSubject([]);
    //     this.searchObservable = this.searchSubject.asObservable();
    //     const ref = this.getSearchCollection(patient.toUpperCase())
    //         .subscribe(async data => {
    //             if (data && data.length > 0) {
    //                 this.currentPage = 0;
    //                 this.searchSubject.next(data);
    //             }
    //             // scoresRef.unsubscribe();
    //         });
    //     return this.searchObservable;
    // }

    // getCovidConfig(labId: string) {
    //     const getCollectionPath = 'lab/' + labId + '/covid';

    //     return new Promise<any>((resolve, reject) => {
    //         try {
    //             ///common/email-alert
    //             const ref = this.afs.collection(getCollectionPath).doc('config');
    //             ref.get().subscribe((doc: any) => {
    //                 resolve(doc.data());
    //             })
    //         } catch (e) {
    //             reject(e);
    //         }
    //     });

    // }

    // getSearchQueryWithText(): any {
    //     return this.getCollection(this.getCollectionPath(), ref => ref
    //         .where(this.patientSearchQuery(this.searchedText.toUpperCase()), '==', true)
    //         .limit(1000));
    // }

    // getSearchQueryWithOutText(): any {
    //     return this.getCollection(this.getCollectionPath(), ref => ref
    //         .orderBy(this.orderBy)
    //         .limit(this.ROWS));
    // }

    getCollectionPath(): string {
        return (
            "lab/" + this.userServiceChild.getCookie("lab") + "/" + this.table
        );
    }

    patientSearchQuery(patient): string {
        return "anyMap." + patient;
    }

    // getPatientById(docId) {
    //     const patientCollectionPath = this.getCollectionPath();
    //     return new Promise((resolve, reject) => {
    //         this.afs.collection(patientCollectionPath).doc(docId).valueChanges().subscribe(data => {
    //             resolve(data);
    //         });
    //     });
    // }

    // getPatientByIdWithoutLogin(docId, labName) {
    //     const patientCollectionPath = 'lab/' + labName + '/' + this.table;
    //     return new Promise((resolve, reject) => {
    //         this.afs.collection(patientCollectionPath).doc(docId).valueChanges().subscribe(data => {
    //             resolve(data);
    //         });
    //     });
    // }

    // checking data on the basis of id field
    // getPatientByFieldId(docId) {
    //     const patientCollectionPath = this.getCollectionPath();
    //     return new Promise((resolve, reject) => {
    //         const data1 = this.getCollection(patientCollectionPath, ref => ref
    //             .where('id', '==', docId)).subscribe(data => {
    //             if (data && data.length) {
    //                 resolve(data);
    //             } else {
    //                 resolve(null);
    //             }
    //         });
    //     });
    // }

    // deletePatientById(docId) {
    //     const patientCollectionPath = this.getCollectionPath();
    //     return this.afs.collection(patientCollectionPath).doc(docId).delete();

    // }

    // getPatientLastCovidResultForAssignCassette(patientId) {
    //     const patientCollectionPath = 'lab/' + this.userServiceChild.getCookie("lab") + '/covid/result/patientResult';
    //     return new Promise((resolve, reject) => {
    //         const data1 = this.getCollection(patientCollectionPath, ref => ref
    //             .where('patientId', '==', patientId)
    //             .orderBy('addedOn', 'asc')
    //             .limit(1)).subscribe(data => {
    //             if (data && data.length) {
    //                 resolve(data);
    //             } else {
    //                 resolve(null);
    //             }
    //         });
    //     });
    // }

    // updatePersonalInfo(patientData, docId, labId) {

    //     const collection = 'lab/' + labId + '/' + this.table;
    //     return new Promise((resolve, reject) => {
    //         this.afs.collection(collection).doc(docId).update(patientData).then((resultData) => {
    //             // // console.log(a);
    //             const data = { msg: 'Updated SuccessFully', status: 'success' };
    //             resolve(data);

    //         }).catch((error) => {
    //             reject(error);
    //         });
    //     });
    // }
}
