
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  editMode: boolean;
  row?: TableRow;
}

@Component({
  selector: 'app-addrow-dialouge',
  templateUrl: './addrow-dialouge.component.html',
  styleUrls: ['./addrow-dialouge.component.scss']
})
export class AddrowDialougeComponent implements OnInit {
  addRowForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddrowDialougeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder
  ) {
    this.addRowForm = this.formBuilder.group({
      quantity: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      instruments: ['', Validators.required],
    });

    // if (data.editMode && data.row) {
    //   this.addRowForm.patchValue({
    //     quantity: data.row.quantity,
    //     instrument: data.row.instrument,
    //   });
    // }
  }
  ngOnInit() {
    // this.dataSource = new MatTableDataSource<TableRow>(this.tableData);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.addRowForm.valid) {
      const newRow = {
        quantity: this.addRowForm.get('quantity').value,
        instruments: this.addRowForm.get('instruments').value,
        code: '',
        selected: false,
      };
      this.dialogRef.close(newRow);
    }
  }
}



