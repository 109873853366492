// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// dev1
export const environment = {
    production: false,
    hmr: false,
    baseUrl: "https://backend-delta.sterilwize.com",
    // baseUrl: "http://192.168.0.83:5001",
};
