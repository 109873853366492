import { Injectable } from "@angular/core";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

@Injectable({
    providedIn: "root",
})
export class ButtonServiceService {
    isButtonClicked: Boolean = false;
    isButtonClosed: Boolean = false;
    constructor(private _fuseSidebarService: FuseSidebarService) {}

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleOpen();
        this.isButtonClosed = true;
        this.isButtonClicked = false;
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarClosed(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleFold();
        this.isButtonClicked = !this.isButtonClicked;
        // // console.log(d);
    }
}
