import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";

import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "app/main/module/common/service/storage.service";
import { Load } from "../load";
import { LoadService } from "../load.service";
import { LoadListColumns } from "./columns";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import {
    customLoadType,
    customProgram,
    getLoadColor,
    getLoadStatus,
    getSingleLoadColor,
} from "./loadutils";
import { ApiService } from "app/main/module/common/service/api.service";
import { MatSnackBar } from "@angular/material/snack-bar";

const timeZone = require("moment-timezone");

@Component({
    selector: "load-table",
    templateUrl: "load-list.component.html",
    styleUrls: ["load.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class LoadlistComponent implements OnDestroy, AfterViewInit, OnInit {
    allData: Array<any> = [];
    errorMessage: any;
    isLoginFailed: boolean;
    columns = this.getColumn();
    displayedColumns = this.columns.map((c) => c.columnDef);

    loadData: Load[] = [];
    dataSource = new MatTableDataSource<any>(this.loadData);
    dataSourceFilters = new MatTableDataSource(this.loadData);
    dataSourceWithPageSize = new MatTableDataSource(this.loadData);
    prev: number = 1;
    next: number = 1;
    page: number;
    count: number;
    totalPage: boolean;
    @ViewChild("table") table: ElementRef;
    isLoading: boolean = false;
    isNextPressed: boolean = false;
    searchText: any;
    isEnterPressed: boolean;
    constructor(
        public snackBar: MatSnackBar,
        private userSevice: StorageService,
        private apiService: ApiService,
        public loadListService: LoadService,
        private changeDetectorRefs: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadData = [];
        this.dataSource.data = [];
        this.isLoading = true;
        this.prev = 0;
        this.next = 1;
        this.page = 0;
        this.count = 1;
        const lab_id = this.userSevice.getCookie("lab_id");
        if (lab_id) {
            this.getAllData(1);
        } else {
            this.logout();
        }
    }

    getAllData(page) {
        this.loadListService.getAllLoadDataByLabId(page).subscribe(
            (a) => {
                Object.entries(a).find(([key, value]) => {
                    if (key == "message" && value == "No data Found") {
                        this.totalPage = true;
                        this.isLoading = false;
                    }
                    if (key == "data") {
                        this.allData = value;
                        this.allData.forEach((element) => {
                            this.isLoading = false;
                            this.changeDetectorRefs.markForCheck();
                            this.changeDetectorRefs.detectChanges();
                            this.loadData.push({
                                machine_name: element.machine_name,
                                _id: "",
                                load_id: element.load_id,
                                bd_barcode: element.bd_barcode,
                                bi_barcode: element.bi_barcode,
                                ei_barcode: element.ei_barcode,
                                load_status: element.load_status,
                                statusBd: element.bd_status,
                                statusBi: element.bi_status,
                                statusEi: element.ei_status,
                                type: element.load_type,
                                version: 0,
                                cycle_number: element.cycle_number,
                                load_number: element.load_number,
                                loadIdStr: "",
                                machineIdStr: "",
                                aiLink: "",
                                cassetteIds: [],
                                machineReport: "",
                                machineReportText: "",
                                pendingEis: [],
                                program: element.load_program,
                                startTimeNum: "",
                                started_by: element.started_by,
                                updated_by: element.updated_by,
                                time: "",
                                date: "",
                                machineReportUrl: element.ma,
                                image_url: element.image_url,
                                load_report_url: element.load_report_url,
                                started_timestamp: element.started_timestamp,
                                updated_timestamp: element.updated_timestamp,
                            });
                        });
                    } else {
                        // // // console.log(e);
                    }
                });

                this.dataSource.data = this.loadData;
            },

            (err) => {
                this.errorMessage = err.error.message;
                // console.log(e);
                if (err.status == 401) {
                    // // console.log(");,
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
                this.logout();
                this.isLoginFailed = true;
            }
        );
    }

    logout(): void {
        localStorage.clear();
        this.userSevice.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    getColumn(): any[] {
        // return LoadListColumns(0);
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: Load, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Sterilizer",
                header: "Sterilizer",
                cell: (element: Load, index: number) =>
                    `${element.machine_name}`,
            },
            {
                columnDef: "Type",
                header: "Type",
                cell: (element: Load, index: number) =>
                    customLoadType(element.type),
            },
            {
                columnDef: "Program",
                header: "Program",
                cell: (element: Load, index: number) =>
                    customProgram(element.type, element.program),
            },
            // {columnDef: 'Load No', header: 'Load No', cell: (element: Load, index: number) => `${element.load_number}`},
            {
                columnDef: "Cycle No",
                header: "Cycle No",
                cell: (element: Load, index: number) =>
                    `${element.cycle_number}`,
            },
            {
                columnDef: "Barcode",
                header: "Barcode",
                cell: (element: Load, index: number) => `${element.load_id}`,
            },
            {
                columnDef: "BD",
                header: "BD",
                cell: (element: Load, index: number) =>
                    `${element.bd_barcode ? element.bd_barcode : "-"}`,
                textColor: (element: Load, index: number) =>
                    `${getSingleLoadColor(element.statusBd)}`,
            },
            {
                columnDef: "BI",
                header: "BI(Spore Test)",
                cell: (element: Load, index: number) =>
                    `${element.bi_barcode ? element.bi_barcode : "-"}`,
                textColor: (element: Load, index: number) =>
                    `${getSingleLoadColor(element.statusBi)}`,
            },
            {
                columnDef: "EI",
                header: "PCD",
                cell: (element: Load, index: number) =>
                    `${element.ei_barcode ? element.ei_barcode : ""}`,
                textColor: (element: Load, index: number) =>
                    `${getSingleLoadColor(element.statusEi)}`,
            },
            {
                columnDef: "User",
                header: "User",
                cell: (element: Load, index: number) =>
                    `${element.started_by.name}`,
            },
            {
                columnDef: "Date",
                header: "Date|Time",
                cell: (element: Load, index: number) =>
                    `${this.changeDate(element.updated_timestamp)}`,
            },
            // {columnDef: 'Time', header: 'Time', cell: (element: Load, index: number) => `${this.changeTime(element.started_by.timestamp)}`},
            {
                columnDef: "Status",
                header: "Status",
                cell: (element: Load, index: number) =>
                    `${getLoadStatus(element)}`,
                textColor: (element: Load, index: number) =>
                    `${getLoadColor(element)}`,
            },
            {
                columnDef: "details",
                header: "More Details",
                cell: (element: Load, index: number) =>
                    `${element.load_number}`,
            },
            {
                columnDef: "AI Image",
                header: "View Image",
                cell: (element: Load, index: number) =>
                    `${element.image_url ? element.image_url : ""}`,
            },

            {
                columnDef: "view",
                header: "View Report",
                cell: (element: Load, index: number) =>
                    `${
                        element.machineReportUrl
                            ? element.machineReportUrl
                            : element.load_report_url
                            ? element.load_report_url
                            : ""
                    }`,
            },
        ];
    }

    onClickSearch(searchText) {
        // const searchText = this.searchText.toUpperCase();;
        this.searchText = searchText;
        this.isLoading = true;
        // this.hasEditDataShown=false;
        // this.loadingSpinner=false;
        // // console.log(t);
        if (searchText == "") {
            window.location.reload();
        } else {
            this.loadData = [];
            this.dataSource.data = [];
            this.loadListService.searchLoad(searchText).subscribe(
                (a) => {
                    // // console.log(a);
                    Object.entries(a).find(([key, value]) => {
                        if (key == "data") {
                            this.allData = value;
                            // // // console.log(r);
                            this.allData.forEach((element) => {
                                //  // // console.log(d);
                                this.isLoading = false;

                                this.loadData.push({
                                    machine_name: element.machine_name,
                                    _id: "",
                                    load_id: element.load_id,
                                    bd_barcode: element.bd_barcode,
                                    bi_barcode: element.bi_barcode,
                                    ei_barcode: element.ei_barcode,
                                    load_status: element.load_status,
                                    statusBd: "",
                                    statusBi: "",
                                    statusEi: "",
                                    type: element.load_type,
                                    version: 0,
                                    cycle_number: element.cycle_number,
                                    load_number: element.load_number,
                                    loadIdStr: "",
                                    machineIdStr: "",
                                    aiLink: "",
                                    cassetteIds: [],
                                    machineReport: "",
                                    machineReportText: "",
                                    pendingEis: [],
                                    program: "",
                                    startTimeNum: "",
                                    started_by: element.started_by,
                                    updated_by: element.updated_by,
                                    time: "",
                                    date: "",
                                    machineReportUrl: element.ma,
                                    image_url: element.image_url,
                                    load_report_url: element.load_report_url,
                                    started_timestamp:
                                        element.started_timestamp,
                                    updated_timestamp:
                                        element.updated_timestamp,
                                });
                            });
                            const msg = "Data Found";
                            this.openSnackBar(msg, "OK");
                        } else if (key == "status" && value == "failed") {
                            // else {
                            // // console.log(e);
                            this.isLoading = false;
                            const msg = "No Data Found";
                            this.openSnackBar(msg, "OK");
                            // window.location.reload();
                        }
                    });
                    //  // // console.log(a);

                    this.dataSource.data = this.loadData;
                    //  // // console.log(a);
                },

                (err) => {
                    this.errorMessage = err.error.message;
                    // // console.log(e);
                    this.isLoginFailed = true;
                }
            );
        }
    }

    openSnackBar(message: string, action: string): void {
        // this.isLoading = true;
        // this.hasFirstDataShown = true;
        this.snackBar.open(message, action, {
            duration: 1000,
        });
    }

    refreshSearch(event) {
        if (event.code === "Backspace" && this.searchText.length === 1) {
            this.searchText = "";
            this.onClickSearch(this.searchText);
        }
    }

    changeDate(date): any {
        // // // console.log(e);

        var timestamp = parseInt(date);

        return (date = this.apiService.getDateStringMomentByTz(timestamp));
    }

    changeTime(time): any {
        var timestamp = parseInt(time);

        return (time =
            this.apiService.getDateStringMomentByTzOnlyTime(timestamp));
    }

    clearInputSearch() {
        this.searchText = "";
        this.ngOnInit();
    }

    changeTimestampToDate(timestamp: string): string {
        if (timestamp) {
            // new Date('03/17/2021');
            return timeZone(timestamp)
                .tz(this.userSevice.getCookie("defaultTz"))
                .format("DD/MM/YYYY");
            // return moment(timestamp).format("DD/MM/YYYY");
        }
        return "";
    }

    viewLOad(barcode) {
        this.router.navigate(["/loaddetail"], {
            queryParams: { load_barcode: barcode },
        });
    }

    openDetail(row) {
        // // console.log(w);
        // window.open(row.fileUrl, "_blank");
        window.open(row, "_blank");
    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1;
        this.next++;
        this.prev++;
        this.getAllData(this.next);
        // // console.log(v);

        // this.isLoading = true;
        // this.isNextPressed = true;
        // this.count = this.count + 1;
        // this.next = this.prev + 1;
        // this.prev = this.next-1
        // this.page++
        // this.getAllData(this.next)
        // // // console.log(v);
        // this.next ++
        // this.prev ++
        // this.scrollToBottom();
        // this.scrollToBottom();
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector("mat-table");
            table.scrollBy({
                behavior: "smooth",
                left: 0,
                top: table.scrollHeight,
            });
        }
    }

    onPrevPress() {
        this.count = this.count - 1;
        this.prev = this.next - 1;
        this.prev--;
        this.next--;
        this.getAllData(this.prev);
        // // console.log(t);
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }

    handleEnterSearch(event: KeyboardEvent) {
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("search")
        ) {
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch(this.searchText);
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    ngAfterViewInit(): void {}
    ngOnDestroy(): void {}
}
