import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { Globals } from "../global";
import { HttpClient, HttpHeaders } from "@angular/common/http";
const moment = require("moment-timezone");
import { StorageService } from "./storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    // Dev = /api/v1/
    //prod = /api/v1/

    baseUrl = environment.baseUrl;

    apiUrl = this.baseUrl + "/api/v1/";
    apiUrlPython = this.baseUrl + "/api/v1/";

    constructor(
        public globals: Globals,
        public httpClient: HttpClient,
        public storageServise: StorageService,
        private cookieService: CookieService
    ) {}
    // token = this.storageServise.getCookie('token');
    timeZone = this.storageServise.getCookie("defaultTz");

    getTeamMemberOnDashboard() {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // console.log(e);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(
            this.apiUrl +
                "teams/total?lab_id=" +
                labId +
                "&lab_timezone=" +
                this.timeZone,
            { headers }
        );
    }

    getLoadOnDashboard(period) {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // console.log(e);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(
            this.apiUrl + "loads/total?lab_id=" + labId + "&period=" + period,
            { headers }
        );
    }

    getLoadStatusOnDashboard(period) {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(
            this.apiUrl + "loads/period?lab_id=" + labId + "&period=" + period,
            { headers }
        );
    }

    getLabLogo(labPath) {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // console.log(h);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(
            this.apiUrl + "minio?bucket_name=" + labId + "&path=" + labPath,
            { headers }
        );
    }

    getLabelOnDashboard() {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(
            this.apiUrl +
                "labels/total?lab_id=" +
                labId +
                "&lab_timezone=" +
                this.timeZone,
            { headers }
        );
    }

    getPatientOnDashboard() {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(
            this.apiUrl +
                "patients/total?lab_id=" +
                labId +
                "&lab_timezone=" +
                this.timeZone,
            { headers }
        );
    }

    getLabs() {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(this.apiUrl + "lab?lab_id=" + labId, {
            headers,
        });
    }
    getALlLabs() {
        const labId = this.storageServise.getCookie("lab_id");
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(this.apiUrl + "lab", { headers });
    }
    getLabIdDetail(token, labId) {
        // // // console.log(d);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.get(this.apiUrl + "lab?lab_id=" + labId, {
            headers,
        });
    }

    getAllLabID(token, email) {
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        // // console.log(l);
        return this.httpClient.get(this.apiUrl + "users?email=" + email, {
            headers,
        });
    }

    getAllRoleByName(userRole) {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const email = this.storageServise.getCookie("email");
        // // console.log(l);
        return this.httpClient.get(this.apiUrl + "role?name=" + userRole, {
            headers,
        });
    }

    getAllRole() {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const email = this.storageServise.getCookie("email");
        // // console.log(l);
        return this.httpClient.get(this.apiUrl + "role", { headers });
    }

    getAllRoleConfiguration() {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const email = this.storageServise.getCookie("email");
        // // console.log(l);
        return this.httpClient.get(this.apiUrl + "role", { headers });
    }

    getLabconfigdata() {
        const token = this.storageServise.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        const center = this.storageServise.getCookie("center");
        // // console.log(r);
        return this.httpClient.get(
            this.apiUrl + "labConfiguration?lab_id=" + labId,
            { headers }
        );
    }

    getCassetteconfigdata() {
        const token = this.storageServise.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        const center = this.storageServise.getCookie("center");
        // console.log( );
        return this.httpClient.get(
            this.apiUrl +
                "labConfiguration?lab_id=" +
                labId +
                "&center=" +
                center,
            { headers }
        );
    }

    updateLastBarcodeUsed(lastbarcode) {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(,);
        const body = {
            lab_id: labId,
            update_data: {
                statistics: {
                    last_cassette_barcode: lastbarcode,
                },
            },
        };
        return this.httpClient.put(this.apiUrlPython + "lab", body, {
            headers,
        });
    }

    getDocumentsApi() {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(d);
        return this.httpClient.get(
            this.apiUrlPython + "departments?lab_id=" + labId,
            { headers }
        );
    }

    getSet_templatesApi(departments, categories, subcategories) {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(d);
        return this.httpClient.get(
            this.apiUrlPython +
                "templates?lab_id=" +
                labId +
                "&departments=" +
                departments +
                "&categories=" +
                categories +
                "&subcategories=" +
                subcategories,
            { headers }
        );
    }
    getCategoryApi() {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(d);
        return this.httpClient.get(
            this.apiUrlPython + "categories?lab_id=" + labId,
            { headers }
        );
    }

    getLabelprintersApi() {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(d);
        return this.httpClient.get(
            this.apiUrlPython + "printers?lab_id=" + labId,
            { headers }
        );
    }
    insertCheckedTemplates(insertCheckedchecked) {
        // // console.log(d);
        const token = this.storageServise.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(d);
        // // console.log(d);
        return this.httpClient.post(
            this.apiUrlPython + "cassetteslab_id=" + labId,
            insertCheckedchecked,
            { headers }
        );
    }

    getSubCategoryApi() {
        const token = this.storageServise.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(d);
        return this.httpClient.get(
            this.apiUrlPython + "subcategories?lab_id=" + labId,
            { headers }
        );
    }

    getDateStringMomentByTz(timestamp: any): string {
        if (timestamp) {
            if (timestamp < 0) timestamp = timestamp * -1;
            const localTimeZone = moment(timestamp).tz(
                this.storageServise.getCookie("defaultTz")
            );
            return localTimeZone.format("DD-MM-YYYY | HH:mm");
        }
        return "";
    }

    getCassetteByBarcodeAndLoadNo(checkedIds) {
        // // console.log(s);
        const token = this.storageServise.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.storageServise.getCookie("lab_id");
        // // console.log(d);
        // // console.log(d);
        return this.httpClient.post(
            this.apiUrlPython + "cassettes/list?lab_id=" + labId,
            checkedIds,
            { headers }
        );
    }

    getDateStringMomentByTzOnlyDate(timestamp: number): string {
        if (timestamp < 0) timestamp = timestamp * -1;
        const localTimeZone = moment(timestamp).tz(
            this.storageServise.getCookie("defaultTz")
        );
        return localTimeZone.format("DD-MM-YYYY");
    }
    getDateStringMomentByTzOnlyTime(timestamp: number): string {
        if (timestamp < 0) timestamp = timestamp * -1;
        const localTimeZone = moment(timestamp).tz(
            this.storageServise.getCookie("defaultTz")
        );
        return localTimeZone.format("HH:mm");
    }

    getDateStringMomentByTzOnlyTimeHMS(timestamp: number): string {
        if (timestamp < 0) timestamp = timestamp * -1;
        const localTimeZone = moment(timestamp)
            .tz(this.storageServise.getCookie("defaultTz"))
            .format("HH:mm:ss");
        return localTimeZone;
    }
    getDateStringMomentByTzOnlyTimeWithAMPM(timestamp: number): string {
        if (timestamp < 0) timestamp = timestamp * -1;
        const localTimeZone = moment(timestamp).tz(
            this.storageServise.getCookie("defaultTz")
        );
        return localTimeZone.format("HH:mm A");
    }

    swapMonthandDate(dateStr) {
        const strDate = dateStr.split("/")[0];
        const strMonth = dateStr.split("/")[1];
        const strYear = dateStr.split("/")[2];
        return `${strMonth}/${strDate}/${strYear}`;
    }
}
