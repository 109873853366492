import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { LoginComponent } from "app/main/module/auth/login/login.component";
import { SharedModule } from "../../common/shared.module";
import { ConfigService } from "../../common/config/config.service";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

const routes = [
    {
        path: "login",
        component: LoginComponent,
    },
];

@NgModule({
    declarations: [LoginComponent],
    providers: [ConfigService],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        FuseSharedModule,
        CommonModule,
        BrowserAnimationsModule,

        // NgxSpinnerModule
    ],
    bootstrap: [LoginComponent],
})
export class LoginModule {}
