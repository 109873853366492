import { TextUtils } from "../../util/TextUtils";
import { AfterViewInit, HostListener, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseComponent } from "./base.component";
import { ConfigService } from "../common/config/config.service";
import { Globals } from "../common/global";
// import {PaginationService} from '../common/pagination/pagination.service';

export abstract class BaseListComponent
    extends BaseComponent
    implements AfterViewInit, OnDestroy
{
    isLoading = true;
    protected searchText: string;
    isEnterPressed = false;
    private _unsubscribeAll: Subject<any>;
    page = 0;
    itemsObservable: Observable<any[]>;
    public dataCount: number;
    public dataSource = new MatTableDataSource<any>();
    hasFirstDataShown = false;
    hasSearchedText = false;
    lastSnackTime = 0;
    searchTxt = "";
    enableInfoMsg = false;

    constructor(
        public listSnackBar: MatSnackBar,
        private listConfigService: ConfigService,
        private listGobals: Globals
    ) {
        super(listConfigService, listGobals);
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.getPaginationService().first();
        this.getPaginationService().pageSubject.next(0);
        this.getPaginationService().pageObservable.subscribe((pageNumber) => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });

        this.itemsObservable = this.getPaginationService().itemsObservable;
        this.itemsObservable.subscribe((data) => {
            if (data && data.length > 0) {
                this.isLoading = false;
                this.dataCount = data.length;
                this.dataSource.data = data;
                data.forEach((value) => this.updateRow(value));
                this.hasFirstDataShown = true;
                if (this.searchText && this.searchText.length > 0) {
                    this.hasSearchedText = true;
                } else {
                    this.hasSearchedText = false;
                }
            } else {
                this.hasSearchedText = true;
                this.hasFirstDataShown = false;
                this.enableInfoMsg = false;
                setTimeout(() => {
                    this.hasFirstDataShown = true;
                    this.enableInfoMsg = true;
                }, 6000);
            }
        });
        this.getPaginationService().notFoundObservable.subscribe((data) => {
            if (data) {
                this.isLoading = false;
                setTimeout(() => {
                    this.openSnackBar("No record found", "OK");
                }, 3000);
            }
        });
        if (this.searchTxt) {
            this.searchText = this.searchTxt.toUpperCase();
            this.onClickSearch();
        }
    }

    updateRow(value) {}

    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    handleEnterSearch(event: KeyboardEvent) {
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("search")
        ) {
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    nextPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.getPaginationService().next();
    }

    prevPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.getPaginationService().prev();
    }

    public onClickSearch() {
        // // console.log(t);
        // alert('Search Text' + this.searchText);
        this.getPaginationService().searchedText = TextUtils.removeSpace(
            this.searchText
        )
            .toUpperCase()
            .replace(/\./g, "");
        this.getPaginationService().search();
        if (this.searchText) {
            localStorage.setItem(
                "searchPatient",
                JSON.stringify(this.searchText)
            );
            const searchPatient = JSON.parse(
                localStorage.getItem("searchPatient")
            );
            // // console.log(t);
        }
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.searchText = "";
        this.onClickSearch();
    }

    refreshSearch(event) {
        if (event.code === "Backspace" && this.searchText.length === 1) {
            this.searchText = "";
            this.onClickSearch();
        }
    }

    protected abstract getPaginationService();

    openSnackBar(message: string, action: string): void {
        const currentTime = new Date().getTime();
        if (currentTime - this.lastSnackTime < 6000) {
            return;
        }
        this.lastSnackTime = currentTime;
        this.listSnackBar.open(message, action, {
            duration: 6000,
        });
    }
}
