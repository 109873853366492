import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from "../../common/global";
// import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from "../../common/service/storage.service";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable()
export class ReportService {
    baseUrl = environment.baseUrl;

    apiUrl = this.baseUrl + "/api/v1/";

    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
        // private afs: AngularFirestore,
        private userService: StorageService
    ) {}

    getPdfUrlReportByReportId(from, to, apiName) {
        // const token = this.userService.getCookie('token');
        const lab_id = this.userService.getCookie("lab_id");
        // // // console.log(d);
        const page = 1;
        const token = this.userService.getCookie("token");
        //  // // console.log(d);
        const headers = new HttpHeaders().set("Authorization", `${token}`);

        return this.httpClient.get(
            this.apiUrl +
                apiName +
                "lab_id=" +
                lab_id +
                "&from=" +
                from +
                "&to=" +
                to,
            { headers }
        );
    }

    downloadPdf(url: string): Observable<Blob> {
        return this.httpClient.get(url, {
            responseType: "blob",
        });
    }

    // allTeamData() {
    // const labId = this.userService.getCookie('lab')
    //  const   collectionName = 'lab/' + labId + '/center/center1/dailyTasks';
    // const data= new Promise<any>((resolve, reject) => {
    //         this.afs.collection(collectionName).valueChanges().subscribe(data => {
    //             if (data) {
    //                 resolve(data.map((value: any) => value ));
    //             }
    //         });
    //     });
    //   // // console.log( );
    // }
    // downloadPdf(getLoadData, labsList, reportId, patientId, patientName, timezone) {
    //     const data = {getLoadData: getLoadData, labsList: labsList, reportId: reportId, patientId: patientId, patientName: patientName,timezone: timezone, tokenId: this.globals.secretKey };
    //     return this.httpClient.post(this.globals.constUrl + 'pdfAllReport', data, { responseType: 'blob' });
    // }

    // downloadPdfCenterWize(centerName,getLoadData, labsList, reportId, patientId, patientName, timezone) {
    //     // // console.log(();,'centerName=',centerName,'getLoadData=',getLoadData, 'labsList=', labsList, 'reportId =', reportId,'timezone=', timezone);
    //     const data = { centerName:centerName,getLoadData: getLoadData, labsList: labsList, reportId: reportId, patientId: patientId, patientName: patientName,timezone: timezone, tokenId: this.globals.secretKey };
    //     return this.httpClient.post(this.globals.constUrl + 'pdfAllReport', data, { responseType: 'blob' });
    // }
}
