import { Component, NgModule, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { ApiService } from "app/main/module/common/service/api.service";
import { Observable } from "rxjs";
import { MaintenanceService } from "../maintenance.service";
import { Location } from "@angular/common";
import { StorageService } from "app/main/module/common/service/storage.service";
import * as moment from "moment";
@NgModule({
    imports: [
        RouterModule.forRoot([]),
        // other modules...
    ],
    // ...
})
@Component({
    selector: "app-maintenacedetail",
    templateUrl: "./maintenacedetail.component.html",
    styleUrls: ["./maintenacedetail.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class MaintenacedetailComponent implements OnInit {
    urlData = "";
    loadObservableData: Observable<any>;
    cstObservableData: Observable<any>;
    maintenaceDetailsData: Array<any>;
    barcode: any;
    program: any;
    status: any;
    cycle_number: any;
    loadNo: any;
    machineName: any;
    startedName: any;
    startedTime: any;
    completedName: any;
    completedTime: any;
    ailinkImage: Array<any>;
    biLinkImages: Array<any>;
    comments: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private pageservice: MaintenanceService,
        private userService: StorageService,
        private location: Location,
        public dialog: MatDialog,
        public apiService: ApiService
    ) {}

    //   ngOnDestroy(): void {
    //      this.loadObservableData
    // }

    ngOnInit(): void {
        this.urlData = this.route.snapshot.queryParams.barcode;
        // // console.log(a);
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.getAllDetailByUrl(this.urlData);
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    getAllDetailByUrl(loadId) {
        // // console.log(d);
        this.pageservice.getmaintenanceById(loadId).subscribe((resultData) => {
            // console.log(a);

            Object.entries(resultData).find(([key, value]) => {
                if (key == "data") {
                    this.maintenaceDetailsData = value;
                    this.maintenaceDetailsData.forEach((elementInst) => {
                        this.barcode = elementInst.load_id;
                        this.program = elementInst.load_type;
                        this.status = elementInst.load_status;
                        if (elementInst.comments) {
                            this.comments = elementInst.comments;
                        } else {
                            this.comments = "-";
                        }
                        if (elementInst.cycle_number) {
                            this.cycle_number = elementInst.cycle_number;
                        } else {
                            this.cycle_number = " -";
                        }

                        this.loadNo = elementInst.load_number;
                        this.machineName = elementInst.machine_name;
                        this.startedName = elementInst.started_by.name;
                        this.startedTime = this.getDateStringMomentByTz(
                            elementInst.started_by.timestamp
                        );
                        // console.log(e);
                        if (this.status == "InProcess") {
                        } else {
                            this.completedName = elementInst.updated_by.name;
                            this.completedTime = this.getDateStringMomentByTz(
                                elementInst.updated_by.timestamp
                            );
                        }

                        this.ailinkImage = elementInst.aLink;

                        this.biLinkImages = elementInst.bLink;
                    });
                }
            });

            // // // console.log(s);
        });
    }

    ngAfterViewInit(): void {
        // // console.log(");

        this.pageservice
            .getmaintenanceById(this.urlData)
            .subscribe((resultData) => {
                // // console.log(a);

                Object.entries(resultData).find(([key, value]) => {
                    if (key == "data") {
                        this.maintenaceDetailsData = value;
                        this.maintenaceDetailsData.forEach((elementInst) => {
                            this.barcode = elementInst.load_id;
                            this.program = elementInst.load_type;
                            this.status = elementInst.load_status;
                            if (elementInst.comments) {
                                this.comments = elementInst.comments;
                            } else {
                                this.comments = "-";
                            }
                            if (elementInst.cycle_number) {
                                this.cycle_number = elementInst.cycle_number;
                            } else {
                                this.cycle_number = " -";
                            }

                            this.loadNo = elementInst.load_number;
                            this.machineName = elementInst.machine_name;
                            this.startedName = elementInst.started_by.name;
                            this.startedTime = this.getDateStringMomentByTz(
                                elementInst.started_by.timestamp
                            );
                            // console.log(e);
                            if (this.status == "InProcess") {
                            } else {
                                this.completedName =
                                    elementInst.updated_by.name;
                                this.completedTime =
                                    this.getDateStringMomentByTz(
                                        elementInst.updated_by.timestamp
                                    );
                            }

                            this.ailinkImage = elementInst.aLink;

                            this.biLinkImages = elementInst.bLink;
                        });
                    }
                });

                // // // console.log(s);
            });
    }

    getDateStringMomentByTz(time): string {
        // // // console.log(e);

        if (time != undefined && time != "-") {
            var timestamp = parseInt(time);
            if (timestamp) {
                if (timestamp < 0) timestamp = timestamp * -1;
                const localTimeZone = moment(timestamp).tz(
                    this.userService.getCookie("defaultTz")
                );
                return localTimeZone.format("DD-MM-YYYY | HH:mm");
            }
        } else {
            return time;
        }
    }

    goBack() {
        this.router.navigate(["/maintenanceHistory"]).then(() => {
            //  this.ngOnDestroy();
            this.ngOnInit();
            //  window.location.reload();
        });
    }

    openDetail(Url) {
        window.open(Url, "_blank");
    }
}
