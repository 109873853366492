import { Injectable } from "@angular/core";
import { Cassette, cassetteInfo } from "../cassette/cassette";
import { Globals } from "../../common/global";
import { CassetteConfig } from "../../common/config/cassetteConfig";
import { LabService } from "../lab/lab.service";

@Injectable({ providedIn: "root" })
export class StatusService {
    canAssignIfIncubatorPending: boolean;
    constructor(private globals: Globals, private labService: LabService) {
        // this.labService.getCassetteConfig('cassette').valueChanges().subscribe((cassetteConfig: any) => {
        //     this.globals.cassetteConfig = cassetteConfig;
        // });
    }

    getStatus(value: string): EStatus {
        // // // console.log(e);
        switch (value) {
            case "S":
                return EStatus.SUCCESS;
            case "Scheduled":
                return EStatus.SCHEDULED;
            case "I":
                return EStatus.IN_PROGRESS;
            case "P":
                return EStatus.PENDING;
            case "Started":
                return EStatus.STARTED;
            case "F":
                return EStatus.FAIL;
        }
        return EStatus.NOT_APPLICABLE;
    }

    getColor(status: EStatus): string {
        switch (status) {
            case EStatus.SUCCESS:
                return StatusColor.green;
            case EStatus.IN_PROGRESS:
                return StatusColor.blue;
            case EStatus.SCHEDULED:
                return StatusColor.blueviolet;
            case EStatus.PENDING:
                return StatusColor.dark_orange;
            case EStatus.STARTED:
                return StatusColor.blue;
            case EStatus.FAIL:
                return StatusColor.red;
            case EStatus.SPOILED:
                return StatusColor.light_gray;
        }
        return StatusColor.gray;
    }
    getBarcodeColor(barcode) {
        if (barcode) return StatusColor.blue;
    }

    getMessage(status: EStatus): string {
        // // // console.log(s);
        switch (status) {
            case EStatus.SUCCESS:
                return StatusMessage.SUCCESS;
            case EStatus.IN_PROGRESS:
                return StatusMessage.IN_PROGRESS;
            case EStatus.SCHEDULED:
                return StatusMessage.SCHEDULED;
            case EStatus.PENDING:
                return StatusMessage.PENDING;
            case EStatus.STARTED:
                return StatusMessage.STARTED;
            case EStatus.FAIL:
                return StatusMessage.FAIL;
            case EStatus.SPOILED:
                return StatusMessage.SPOILED;
        }
        return StatusColor.gray;
    }

    getStatusNew(
        statusBd: string,
        statusBi: string,
        statusEi: string
    ): EStatus {
        // // console.log(d);

        if (statusBd == undefined) {
            statusBd = "";
        } else if (statusBi == undefined) {
            statusBi = "";
        } else if (statusEi == undefined) {
            statusEi = "";
        }
        // // // console.log(i);
        // // // console.log(s);
        // return EStatus.SCHEDULED;
        if (this.isStatusInScheduled(status)) {
            return EStatus.SCHEDULED;
        } else if (this.isAnyFail(statusBd, statusBi, statusEi)) {
            return EStatus.FAIL;
        } else if (this.isEiInProgress(statusEi)) {
            return EStatus.IN_PROGRESS;
        } else if (this.isBiPending(statusBi)) {
            return EStatus.PENDING;
        } else if (this.isAllSuccess(statusBd, statusBi, statusEi)) {
            return EStatus.SUCCESS;
        } else if (this.isEiInScheduled(statusEi)) {
            //Scheduled
            return EStatus.SCHEDULED;
        }
        return EStatus.IN_PROGRESS;
    }

    hasNewStatus(statusBd: string, statusBi: string, statusEi: string) {
        return (
            this.getStringOrDefault(statusBd, "") ||
            this.getStringOrDefault(statusBi, "") ||
            this.getStringOrDefault(statusEi, "")
        );
    }

    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }
    getStatusNewTest(
        status: string,
        statusBd: string,
        statusBi: string,
        statusEi: string
    ): EStatus {
        // // // console.log(s);
        // return EStatus.SCHEDULED;
        if (status == "Scheduled") return EStatus.SCHEDULED;
        // if (status == 'Ready')
        //     return EStatus.SCHEDULED;
        if (this.hasNewStatus(statusBd, statusBi, statusEi))
            return this.getStatusNew(statusBd, statusBi, statusEi);

        switch (status) {
            case "Incubator_Pending":
            case "Started":
            case "InProcess":
            case "IncubatorPending":
                return EStatus.PENDING;
            case "Failed":
            case "Incubator_Failed":
                return EStatus.FAIL;
            case "Success":
            case "Ready":
            case "Assigned":
            case "Incubator_Success":
                return EStatus.SUCCESS;
            case "Used":
                return EStatus.SUCCESSPATIENT;
            default:
                return EStatus.IN_PROGRESS;
        }
    }

    isAnyFail(statusBd: string, statusBi: string, statusEi: string): boolean {
        if (statusBd && statusBd === "F") {
            return true;
        }
        if (statusBi && statusBi === "F") {
            return true;
        }
        if (statusEi && statusEi === "F") {
            return true;
        }
        return false;
    }

    isEiInProgress(statusEi: string): boolean {
        if (statusEi && statusEi === EStatus.IN_PROGRESS) {
            return true;
        } else {
            return false;
        }
    }

    isEiInScheduled(statusEi: string): boolean {
        if (statusEi && statusEi === EStatus.SCHEDULED) {
            return true;
        } else {
            return false;
        }
    }

    isStatusStarted(statusEi: string): boolean {
        if (statusEi && statusEi === EStatus.SCHEDULED) {
            return true;
        } else {
            return false;
        }
    }

    isStatusInScheduled(status: string): boolean {
        if (status && status === EStatus.SCHEDULED) {
            return true;
        } else {
            return false;
        }
    }

    isBiPending(statusBi: string): boolean {
        return statusBi && statusBi === EStatus.PENDING;
    }

    isAllSuccess(statusBd, statusBi, statusEi): boolean {
        if (statusBd && statusBd !== EStatus.SUCCESS) {
            return false;
        }
        if (statusBi && statusBi !== EStatus.SUCCESS) {
            return false;
        }
        if (statusEi && statusEi !== EStatus.SUCCESS) {
            return false;
        }
        return true;
    }

    getCassetteStatus(cassette: Cassette): CassetteStatus {
        if (
            cassette.isSpoiled != null &&
            cassette.isSpoiled != undefined &&
            cassette.isSpoiled == true
        ) {
            return CassetteStatus.RESET;
        }
        const eStatus: EStatus = this.getStatusNew(
            cassette.bd_status,
            cassette.bi_status,
            cassette.ei_status
        );
        //  // // console.log(s);
        const isAssignDone = cassette.isAssignDone;
        // const isAssignDonePatient = cassette.is
        //  // // console.log(e);

        switch (eStatus) {
            case EStatus.FAIL:
                // // // console.log(");
                return isAssignDone
                    ? CassetteStatus.USED_FAILED
                    : CassetteStatus.Failed;
            case EStatus.PENDING:
                //  // // console.log(G);
                return isAssignDone
                    ? CassetteStatus.USED_IN_PROGRESS
                    : CassetteStatus.IN_PROGRESS_BI_PENDING;
            case EStatus.IN_PROGRESS:
                // // // console.log(");
                return CassetteStatus.IN_PROGRESS;
            case EStatus.SUCCESS:
                //  // // console.log(E);
                return isAssignDone
                    ? CassetteStatus.USED
                    : CassetteStatus.AVAILABLE;
            case EStatus.SUCCESSPATIENT:
                //  // // console.log(E);
                return isAssignDone
                    ? CassetteStatus.PATIENTUSED
                    : CassetteStatus.AVAILABLE;
            case EStatus.SPOILED:
                // // // console.log(");
                return CassetteStatus.RESET;
            default:
                // // // console.log(");
                return CassetteStatus.IN_PROGRESS;
        }
    }

    getCassetteStatusNew(cassette: cassetteInfo): CassetteStatus {
        if (
            cassette.is_spoiled != null &&
            cassette.is_spoiled != undefined &&
            cassette.is_spoiled == true
        ) {
            return CassetteStatus.RESET;
        }
        const eStatus: EStatus = this.getStatusNew(
            cassette.bd_status,
            cassette.bi_status,
            cassette.ei_status
        );
        // // console.log(s);
        const isAssignDone = cassette.is_assigned_to_patient;
        // const isAssignDonePatient = cassette.is
        // // console.log(e);

        switch (eStatus) {
            case EStatus.FAIL:
                // // console.log(");
                return isAssignDone
                    ? CassetteStatus.PATIENTUSED_FAILED
                    : CassetteStatus.Failed;
            case EStatus.PENDING:
                // // console.log(G);
                return isAssignDone
                    ? CassetteStatus.PATIENTUSED_IN_PROGRESS
                    : CassetteStatus.IN_PROGRESS_BI_PENDING;
            case EStatus.IN_PROGRESS:
                // // // console.log(");
                return CassetteStatus.IN_PROGRESS;
            case EStatus.SUCCESS:
                //  // // console.log(E);
                return isAssignDone
                    ? CassetteStatus.PATIENTUSED
                    : CassetteStatus.AVAILABLE;
            case EStatus.SUCCESSPATIENT:
                //  // // console.log(E);
                return isAssignDone
                    ? CassetteStatus.PATIENTUSED
                    : CassetteStatus.AVAILABLE;
            case EStatus.SPOILED:
                // // // console.log(");
                return CassetteStatus.RESET;
            default:
                // // // console.log(");
                return CassetteStatus.IN_PROGRESS;
        }
    }

    canAssign(cassette: Cassette): boolean {
        const cassetteStatus: CassetteStatus = this.getCassetteStatus(cassette);
        // const cassetteStatus=cassette.status;

        if (
            this.isBiPending(cassette.bi_status) &&
            this.isEiInProgress(cassette.ei_status)
        ) {
            // // // console.log(s);

            return false;
        } else if (cassetteStatus === CassetteStatus.IN_PROGRESS_BI_PENDING) {
            // // // console.log(s);

            const cassetteConfig = this.globals.cassetteConfig;
            return (
                cassetteConfig != null &&
                cassetteConfig.canAssignIfIncubatorPending
            );
        } else if (cassetteStatus === CassetteStatus.AVAILABLE) {
            // // // console.log(s);
            return true;
        }
        return false;
    }

    getCassetteResult(status: EStatus): string {
        switch (status) {
            case EStatus.SUCCESS:
                return CassetteResult.SUCCESS;
            case EStatus.IN_PROGRESS:
                return CassetteResult.IN_PROGRESS;
            case EStatus.PENDING:
                return CassetteResult.PENDING;
            case EStatus.STARTED:
                return CassetteResult.STARTED;
            case EStatus.FAIL:
                return CassetteResult.FAIL;
            case EStatus.SPOILED:
                return CassetteResult.SPOILED;
        }
        return CassetteResult.NOT_APPLICABLE;
    }
}
