import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenaceHistoryComponent } from './maintenace-history/maintenace-history.component';
import { WaterlineTestComponent } from './waterline-test/waterline-test.component';
import { MaintenacedetailComponent } from './maintenacedetail/maintenacedetail.component';
import { AuthGuard } from '../../auth/auth.guard';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {SharedModule} from '../../common/shared.module';
import {SignaturePadModule} from '@ng-plus/signature-pad';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser'
import { OthermachineComponent } from './othermachine/othermachine.component';
import { MaintenanceService } from './maintenance.service';

const routes = [
  {
      path     : 'maintenance/maintenanceHistory',
      component: MaintenaceHistoryComponent,
      canActivate: [AuthGuard],
    //   resolve:{
    //       navType:MaintenaceService
    //   }
  },
  {
      path     : 'maintenance/waterlineTest',
      component: WaterlineTestComponent,
      canActivate: [AuthGuard],
  },
  {
    path     : 'maintenance/othermachine',
    component: OthermachineComponent,
    canActivate: [AuthGuard],
},

{
  path     : 'maintenancedetail',
  component: MaintenacedetailComponent,
  canActivate: [AuthGuard],
},
];

@NgModule({
  declarations: [
    MaintenaceHistoryComponent,
     WaterlineTestComponent,
     OthermachineComponent,
     MaintenacedetailComponent,

  ],
  imports: [
      RouterModule.forChild(routes),
      TranslateModule,
      FuseSharedModule,
      SharedModule,
      AsapMaterialModule,
      NgxDaterangepickerMd,
      NgxChartsModule,
      BrowserModule,
     BrowserAnimationsModule,
     
      AgmCoreModule.forRoot({
          apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
      }),

      FuseSharedModule,
      FuseWidgetModule,
      SignaturePadModule,
      MatDatepickerModule,
      MatNativeDateModule,

      // NgxMaterialTimepickerModule

  ],
  entryComponents: [
      // AddPatientDialogComponent,
      // ShareAssessmentDialogComponent,
  ],
  exports     : [
      MaintenaceHistoryComponent,
      WaterlineTestComponent,
      MaintenacedetailComponent,
      OthermachineComponent

  ],
  providers   : [
    // MaintenanceHistoryInfiniteScrollService,
    MaintenanceService
    // WaterlineInfiniteScrollService,
    // WaterlineService,
    // OthermachineService,
    // OthermachineInfiniteScrollService
     
]
})

export class MaintenanceModule
{
}


// @NgModule({
//   declarations: [MaintenanceHistoryComponent, WaterlineTestComponent],
//   // imports: [
//   //   CommonModule
//   // ]
//   providers: [
//     MaintenaceService
// ]
// })
// export class MaintenanceModule { }
