import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { CassetteConfig } from './cassetteConfig';
import { LabConfig } from "./labConfig";
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable({ providedIn: 'root' })
export class ConfigService {
    private itemsSubject: BehaviorSubject<CassetteConfig[]>;
    public itemsObservable: Observable<CassetteConfig[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    table = 'config';
    currentPage = 0;

    constructor(private userService: StorageService) {
    }

    // getCollection(ref, queryFn?): Observable<any[]> {
    //     return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
    //         return actions.map(a => {
    //             const data = a.payload.doc.data();
    //             const id = a.payload.doc.id;
    //             const doc = a.payload.doc;
    //             return { id, ...data as {}, doc };
    //         });
    //     }));
    // }

    // first(): void {
    //     const ref = this.getCollection(this.getCollectionPath(), ref => ref)
    //         .subscribe(data => {
    //             this.pageSubject.next(0);
    //             this.latestEntry = data[data.length - 1].doc;
    //             this.startingEntry = data[0].doc;
    //             this.itemsSubject.next(data);
    //             // scoresRef.unsubscribe();
    //         });
    // }


    getCollectionPath(): string {
        return 'lab/' + this.userService.getCookie("lab") + '/' + this.table;
    }



    // getCassetteConfig(id: string) {
    //     return this.afs.doc<CassetteConfig>(`${this.getCollectionPath()}/${id}`);
    // }

    // getLabConfig(id: string) {
    //     return this.afs.doc<LabConfig>(`${this.getCollectionPath()}/${id}`);
    // }

    // getVideoConfig() {
    //     return this.afs.doc(`common/config`);
    // }

    // getLabNameSideBar(labId) {
    //     return this.afs.doc(`lab/${labId}`);
    // }

}
