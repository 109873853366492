import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {LoadComponent} from './loadlist/load.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {StatusService} from '../status/status.service';
import {AddLoadComponent} from './addload/addload.component';
import {SharedModule} from '../../common/shared.module';
import {AuthGuard} from '../../auth/auth.guard';
import {LoadListService} from './loadlist/load-list.service';
import { LoaddetailComponent } from './loaddetail/loaddetail.component';
import {LoadlistComponent} from "./loadlist/loadlist.component";
// import {LoadInfiniteListService} from "./loadlist/load-infinite-list.service";


const routes = [
    {
        path     : 'load',
        component: LoadlistComponent    ,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        LoadComponent,
        LoadlistComponent,
        AddLoadComponent,
        LoaddetailComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        SharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,


        // MatButtonModule,
        // MatExpansionModule,
        // MatFormFieldModule,
        // MatIconModule,
        // MatInputModule,
        // MatPaginatorModule,
        // MatRippleModule,
        // MatSelectModule,
        // MatSortModule,
        // MatSnackBarModule,
        // MatTableModule,
        // MatTabsModule,

        NgxChartsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule

    ],
    exports     : [
        LoadComponent,
        LoadlistComponent,
        AddLoadComponent,
        LoaddetailComponent
    ],
    providers   : [
        StatusService,
        LoadListService,
        // LoadInfiniteListService
]
})

export class LoadModule
{
}
