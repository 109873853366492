import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { fuseAnimations } from "@fuse/animations";
import {
    AddrowDialougeComponent,
    DialogData,
} from "../addrow-dialouge/addrow-dialouge.component";
import { MatCheckboxChange } from "@angular/material/checkbox";
interface TableRow {
    quantity: number;
    instruments: string;
    code: string;
    selected: boolean;
}
@Component({
    selector: "app-new-prepare-sets",
    templateUrl: "./new-prepare-sets.component.html",
    styleUrls: ["./new-prepare-sets.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class NewPrepareSetsComponent implements OnInit {
    tableData: TableRow[] = [];
    displayedColumns: string[] = [
        "quantity",
        "instruments",
        "code",
        "select",
        "addNewRow",
    ];
    tableForm: FormGroup;
    columnValues: any;
    dataSource: MatTableDataSource<TableRow>;
    constructor(private fb: FormBuilder, private dialog: MatDialog) {
        // Initialize the table with some initial data
        this.tableData = [
            {
                quantity: 10,
                instruments: "Instrument 1",
                code: "Code 1",
                selected: false,
            },
            {
                quantity: 5,
                instruments: "Instrument 2",
                code: "Code 2",
                selected: false,
            },
            {
                quantity: 8,
                instruments: "Instrument 3",
                code: "Code 3",
                selected: false,
            },
        ];

        // Create the form controls for quantity and instruments
        this.tableForm = this.fb.group({
            quantity: ["", Validators.required],
            instruments: ["", Validators.required],
        });
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<TableRow>(this.tableData);
    }

    getColumnValues() {
        this.columnValues = this.dataSource.data.map((row: any) => {
            return {
                quantity: row.quantity,
                instruments: row.instruments,
                code: row.code,
                selected: row.selected,
            };
        });
        // // console.log(s);
    }
    // Function to add a new row to the table
    // addNewRow(): void {
    //   const newRow: TableRow = {
    //     quantity: this.tableForm.value.quantity,
    //     instruments: this.tableForm.value.instruments,
    //     code: '',
    //     selected: false,
    //   };
    //   this.dataSource.data.push(newRow);
    //   this.tableForm.reset();
    // }

    openAddRowDialog() {
        const dialogRef = this.dialog.open(AddrowDialougeComponent, {
            width: "500px",
        });

        dialogRef.afterClosed().subscribe((result: TableRow) => {
            if (result) {
                // result.code = '';
                this.dataSource.data.push(result);
                this.dataSource.data = [...this.dataSource.data]; // To trigger the data update
            }
        });
    }

    // Function to handle the select all checkbox
    toggleAllSelection(event: MatCheckboxChange): void {
        const checked = event.checked;
        this.dataSource.data.forEach((row) => (row.selected = checked));
    }

    // Function to handle quantity change in a row
    onQuantityChange(row: TableRow): void {
        // Add your logic here if you want to perform any action when the quantity changes
        // // console.log(y);
    }
}
