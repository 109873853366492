import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {
    debounceTime,
    distinctUntilChanged,
    finalize,
    switchMap,
    takeUntil,
} from "rxjs/operators";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { StatusService } from "../../status/status.service";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { EmailListService } from "../list/EmailList.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from "../../../base/base-list.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { EmailDto } from "../email.dto";
import { Load } from "../../load/load";
import { Subject } from "rxjs";
import { ApiService } from "../../../common/service/api.service";
import { Location } from "@angular/common";
import { LogsService } from "../../logs/logs.service";
import { Observable } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Email } from "../email";

@Component({
    selector: "app-emaildetail",
    // selector: 'load-table',
    templateUrl: "viewEmailDetails.component.html",
    styleUrls: ["viewEmailDetails.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class EmaildDetailComponent
    extends BaseListComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;

    constructor(
        private userSevice: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: EmailListService,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals,
        public apiService: ApiService,
        public logService: LogsService,
        public dialogRef: MatDialogRef<EmaildDetailComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: Email
    ) {
        super(snackBar, configService, globals);
        this._unsubscribeAll1 = new Subject();
    }
    private _unsubscribeAll1: Subject<any>;
    perPageData = this.globals.perPageData;
    columns = this.getColumn();
    displayedColumns = this.columns.map((c) => c.columnDef);
    dataSource = new MatTableDataSource<any>();

    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    protected extraCta = {
        visibility: false,
        text: "",
    };

    todayDate: Date = new Date();
    urlData = "";
    dataObj: {};

    emailDetailsTo: "";
    subject: "";
    message: "";
    labId: "";
    deliveryState: "";
    deliveryAttempts: number;
    deliveryEndTime: any;
    deliveryStartTime: any;
    deliveryError: "";
    leaseExpireTime: "";
    deliveryMessageId: "";
    deliveryAcceptedList: any;
    deliveryPendingList: any;
    deliveryRejectedList: any;
    errorData: any;

    ngOnInit(): void {
        // // console.log(a);
        this.page = 0;
        // this.urlData = this.route.snapshot.queryParams.urlData;
        // // // console.log(a);
        this.urlData = this.dialogRef.componentInstance.data.docId;
    }
    ngAfterViewInit(): void {
        // this.logService.getEmailDetailsById(this.urlData).then((data: any) => {
        //     this.dataObj = data;
        //     if (data) {
        //         this.setValueData(data);
        //     }
        //     else {
        //         this.errorData = 'Unable to find data.'
        //     }
        // }).catch(error => {
        //     // // console.log(r);
        // });
    }
    setValueData(resultData) {
        this.emailDetailsTo = resultData.to;
        this.subject = resultData.message && resultData.message.subject;
        this.message = resultData.message && resultData.message.html;
        this.labId = resultData.labId;
        this.deliveryState = resultData.delivery && resultData.delivery.state;
        this.deliveryAttempts =
            resultData.delivery && resultData.delivery.attempts;
        this.deliveryStartTime =
            resultData.delivery &&
            resultData.delivery.startTime &&
            this.apiService.getDateStringMomentByTzOnlyTimeHMS(
                resultData.delivery.startTime.seconds * 1000
            );
        // report.StartDateTime = getDateString(this.getAddedOn());
        this.deliveryEndTime =
            resultData.delivery &&
            resultData.delivery.endTime &&
            this.apiService.getDateStringMomentByTzOnlyTimeHMS(
                resultData.delivery.endTime.seconds * 1000
            );
        this.deliveryError = resultData.delivery && resultData.delivery.error;
        this.leaseExpireTime =
            resultData.delivery && resultData.delivery.info.leaseExpireTime;
        this.deliveryMessageId =
            resultData.delivery && resultData.delivery.info.messageId;
        this.deliveryAcceptedList =
            resultData.delivery && resultData.delivery.info.accepted;
        this.deliveryAcceptedList = this.deliveryAcceptedList.join(", ");
        this.deliveryPendingList =
            resultData.delivery && resultData.delivery.info.pending;
        this.deliveryPendingList = this.deliveryPendingList.join(", ");
        this.deliveryRejectedList =
            resultData.delivery && resultData.delivery.info.rejected;
        this.deliveryPendingList = this.deliveryRejectedList.join(", ");
    }

    onClosePopuu() {
        this.dialogRef.close();
    }

    getDateString(timestamp: number) {
        if (timestamp) {
            let unix_timestamp = timestamp;
            var date = new Date(unix_timestamp * 1000);
            var hours = date.getHours();
            var minutes = "0" + date.getMinutes();
            var seconds = "0" + date.getSeconds();
            var formattedTime =
                hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
            return formattedTime;
        }
        return "";
    }
    plus0(num: number): string {
        return `0${num.toString()}`.slice(-2);
    }
    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return "#FFFFFF";
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: EmailDto, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Date Time",
                header: "Date Time",
                cell: (element: EmailDto, index: number) =>
                    `${element.dateText + " " + element.timeText}`,
            },
            {
                columnDef: "Subject",
                header: "Subject",
                cell: (element: EmailDto, index: number) =>
                    `${element.message.subject}`,
            },
            {
                columnDef: "To",
                header: "To",
                cell: (element: EmailDto, index: number) => `${element.to}`,
            },
            {
                columnDef: "Body",
                header: "Body",
                cell: (element: EmailDto, index: number) =>
                    `${element.message.html}`,
            },
        ];
    }

    updateRow(row): any {
        row.dateText = moment(Math.abs(row.time)).format("DD-MM-YYYY");
        row.timeText = moment(Math.abs(row.time)).format("HH:mm");
    }

    getTitleMatIcon(): string {
        return "email";
    }

    getTitle(): string {
        return "Emails";
    }

    viewLOad(emailObj) {
        this.router.navigate(["/emaildetail"], {
            queryParams: { emailDetails: emailObj.id },
        });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    openDetail(Url) {
        window.open(Url, "_blank");
    }
    backButton() {
        this.router.navigate(["/list"]);
    }
    // backButton() {
    //     this.location.back();
    // }

    protected getPaginationService() {
        return this.paginationService;
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll1.next();
        this._unsubscribeAll1.complete();
    }
}
