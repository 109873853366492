import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { Globals } from "../../../../../main/module/common/global";
import { ConfigService } from "../../../../../main/module/common/config/config.service";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "app/main/module/common/service/storage.service";
import { ApiService } from "app/main/module/common/service/api.service";
import { ButtonService } from "app/@fuse/services/button.service";
import { ButtonServiceService } from "app/main/module/sterilwize/dashboard/button-service.service";
const { version } = require("../../../../../../../package.json");

@Component({
    selector: "navbar-vertical-style-1",
    templateUrl: "./style-1.component.html",
    styleUrls: ["./style-1.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    email = "";
    // labName = 'Hinduja';
    labName = "";
    labCentre = "";
    centerName = "Central A";
    logo = "";
    labsObjData: any = [];
    labId = "";
    staticData = "";
    public appVersion;
    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
    labCenterName: string;
    labsLogo: any;
    multiCenter: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        public globals: Globals,
        public configService: ConfigService,
        private cookieService: CookieService,
        private userService: StorageService,
        private apiService: ApiService,
        private buttonService: ButtonServiceService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.appVersion = version;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement(
                        "navbar .nav-link.active",
                        -120
                    );
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this.staticData = localStorage.getItem('staticData');
        // this.email = this.userService.getCookie("email");
        this.labId = this.userService.getCookie("lab_id");
        // this.labCentre=localStorage.getItem('lab');
        this.labName = this.userService.getCookie("lab_name");
        this.logo = this.userService.getCookie("lab_logo");
        this.getLabLogoLink(this.logo);
        // // console.log(o);
        this.multiCenter = this.userService.getCookie("multi_center");
        // // console.log(r);
        this.labCenterName = this.userService.getCookie("center");
        this.labCentre = localStorage.getItem("center");
        // // // console.log(e);
        // if(this.multiCenter == true){

        // }
        if (this.labId) {
            this.labName = this.userService.getCookie("lab_name");
        }

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar("navbar")) {
                    this._fuseSidebarService.getSidebar("navbar").close();
                }
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation =
                    this._fuseNavigationService.getCurrentNavigation();
            });
    }

    getLabLogoLink(logopath) {
        this.apiService.getLabLogo(logopath).subscribe(
            (a) => {
                // // console.log(a);
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        this.labsLogo = value;
                        // // console.log(o);
                    } else {
                        // // // console.log(e);
                    }
                });
            },

            (err) => {
                // this.errorMessage = err.error.message;
                // // // console.log(e);
                // if (err.status == 401) {
                //     // // // console.log(");,
                //         alert('Token is invalid or has expired Please relogin')
                //     this.logout()
                // }
                // this.isLoginFailed = true;
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this.buttonService.toggleSidebarOpened();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this.buttonService.toggleSidebarClosed();
    }
}
