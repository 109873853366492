import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ObjectUnsubscribedError, Observable } from "rxjs";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Globals } from "../../../common/global";
import { LabService } from "../../lab/lab.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiService } from "app/main/module/common/service/api.service";
import { I } from "@angular/cdk/keycodes";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { LoadverifiedService } from '../../loadverified/loadverified.service';
import { CassetteListService } from "../list/cassette-list.service";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { OpendialougeComponent } from "../opendialouge/opendialouge.component";
import * as es6printJS from "print-js";
import * as printJS from "print-js";
import {
    NgxQrcodeElementTypes,
    NgxQrcodeErrorCorrectionLevels,
    NgxQRCodeModule,
} from "@techiediaries/ngx-qrcode";

const timeZone = require("moment-timezone");
export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: "app-preparesets",
    templateUrl: "./preparesets.component.html",
    styleUrls: ["./preparesets.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PreparesetsComponent {
    name = "Angular ";
    elementType = NgxQrcodeElementTypes.IMG;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
    value = "";

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @Input()
    public content: any;
    public rows = new MatTableDataSource<any>();
    public columns = [];
    public selection = new SelectionModel<any>(true, []);
    departmentList: Array<any> = [];
    categoryList: Array<any> = [];
    settingFormOne: FormGroup;
    settingFormTwo: FormGroup;
    settingFormThree: FormGroup;
    settingFormFour: FormGroup;
    currentDepartment: any;
    currentUser = this.userService.getCookie("email");
    currentUserName = this.userService.getCookie("name");
    currentSubCategory: any;
    subCategoryList: Array<any> = [];
    currentCategory: any;
    // role = this.userService.getCookie('role');
    currentRoleData: any;
    getStatus: any;
    getError: any;

    displayedColumns: string[] = ["quantity", "name", "code", "selectAll"];

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    tableList: any = [];
    tableForm: FormGroup;
    setQuantity: Array<any> = [];
    setParticulars: Array<any> = [];
    setBulkReport: Array<any> = [];
    setCode: Array<any> = [];
    showTable: boolean = false;
    quantity: any;
    setsArray: any;
    finalBarcode: any;
    selectedLength: number;
    changesRowLength: any;
    lastId: any;
    getTemplateList: any;
    insertCheckedchecked: object;
    time: any;
    setsDisplayName: any;
    setsPrintTitle: any;
    midlevalue: any;
    finalBarcode2: any;
    instruments: Array<any> = [];
    selectionlength: number = 0;
    contentLength: any;
    setBulkReportForTrue: Array<any> = [];
    selectedDepartment: any;
    selectedDepartmentName: any;
    selectedCategoryName: any;
    selectedCategory: string;
    selectedSubCategoryName: string;
    selectedSubCategory: string;
    returnUpdatebarcode: any;
    templateLength: boolean;

    command_type: any;
    connect_type: any;
    barcode_type: any;
    barcodestringposition: any;
    print_copies: any;
    print_direction: any;
    print_rotation: any;

    viewPrinterCode: boolean = true;
    label_height: any;
    label_width: any;
    label_gap: any;
    printer_dpi: any;
    dpi: any;
    qrwidth: any;
    qrsize: any;
    qrheight: any;
    barcodewidth: any;
    barcodeheight: any;
    titleheight: any;
    // titlewidth: any;
    headingBarcode: any;
    headingTitle: any;
    rightContainer: any;
    titlemargintop: any;
    lablemargintop: any;
    labelmarginright: any;
    pagemargintop: any;
    pagemarginbottom: any;
    pagemarginleft: any;
    pagemarginright: any;
    titlefontsize: any;
    barcodefontsize: any;
    isInitComplete: boolean;
    currentType: any;
    quantityext: number;
    instrumentext: string = "";
    addmoreButton: boolean = true;
    userbarcode: any;
    errorMessage: any;

    constructor(
        public labService: LabService,
        private http: HttpClient,
        public apiservice: ApiService,
        private formBuilder: FormBuilder,
        private userService: StorageService,
        private cassetteservice: CassetteListService,
        private router: Router,
        public globals: Globals,
        public snackBar: MatSnackBar,
        // private loadservice: LoadverifiedService,
        public dialog: MatDialog
    ) {
        this.elementType = NgxQrcodeElementTypes.IMG;
        this.correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
        this.value = this.finalBarcode;
    }

    ngOnInit(): void {
        this.currentType = "Internal";
        this.selectionlength = 0;
        this.departmentList = [];
        this.lastId = 0;
        this.getDocuments();
        this.selection.clear();

        this.settingFormOne = this.formBuilder.group({
            departmentListName: ["", Validators.required],
        });

        this.settingFormTwo = this.formBuilder.group({
            categoryListName: ["", Validators.required],
        });

        this.settingFormThree = this.formBuilder.group({
            subcatListName: ["", Validators.required],
        });

        this.tableForm = this.formBuilder.group({
            setTemplateList: this.formBuilder.array([]),
        });

        this.getprinterconfig();
    }

    openDialogWithRefext(ref: TemplateRef<any>): void {
        this.addmoreButton = false;

        this.quantityext = 0;
        this.instrumentext = "";

        this.dialog.open(ref, { width: "450px", disableClose: true });
        // }
    }
    keyPressNumbers(event) {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    private setUsersForm() {
        const userCtrl = this.tableForm.get("setTemplateList") as FormArray;
        this.tableList.forEach((user) => {
            userCtrl.push(this.setUsersFormArray(user));
        });
    }
    private setUsersFormArray(user) {
        return this.formBuilder.group({
            quantity: [user.quantity],
            name: [user.name],
            code: [user.code],
            checkbox: [user.checkbox],
        });
    }

    public isAllSelected() {
        //  // // console.log(');
        const numSelected = this.selection.selected.length;
        // // // console.log(d);
        this.selectionlength = numSelected;
        // // // console.log(h);
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    public masterToggle() {
        // // // console.log(d);
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => {
                  this.selection.select(row);
                  //  // // console.log(h);
              });
    }

    public checkboxLabel(row?: any): string {
        // this.selectionlength=0
        // this.selectionlength = this.selection.selected.length
        return !row
            ? `${this.isAllSelected() ? "select" : "deselect"} all`
            : `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
                  row.position + 1
              }`;
    }

    private updateRows(): void {
        this.dataSource = new MatTableDataSource<any>(this.content);
        // // // console.log(e);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    private updateColumns(): void {
        this.columns = ["select"];
        // // // console.log(s);

        for (const column of Object.keys(this.content[0])) {
            this.columns.push(column);
            // // // console.log(s);
        }
    }

    private updateTable(): void {
        // // // console.log(t);
        if (this.content) {
            this.updateRows();
            this.updateColumns();
        }
    }

    printLabelConfirmation(
        ref: TemplateRef<any>,
        contentLength,
        selectionLength
    ) {
        sessionStorage.setItem("contentLength", contentLength);
        sessionStorage.setItem("selectionLength", selectionLength);
        sessionStorage.setItem("showFinalPrint", "false");
        const token = this.userService.getCookie("token");
        const labId = this.userService.getCookie("lab_id");
        this.apiservice.getLabIdDetail(token, labId).subscribe(
            (a) => {
                // // console.log(a);
                a["data"].forEach((element) => {
                    // // console.log(e);
                    this.getbarcode(element.statistics.last_cassette_barcode);
                });
            },

            (err) => {
                this.getStatus = err.error.message;
                this.errorMessage = err.error.message;
                // // console.log(e);
                if (err.status == 401) {
                    // // console.log(");,
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
            }

            // error: HttpErrorResponse => {
            //   this.getStatus = HttpErrorResponse.error.status;
            //   // // console.log(s);
            //   this.getError = HttpErrorResponse.error.error;
            //   // // console.log(r);
            // }
        );
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    getbarcode(userbarcode) {
        const contentLengthuser = sessionStorage.getItem("contentLength");
        const selectionLengthuser = sessionStorage.getItem("selectionLength");

        const dialogRef = this.dialog.open(OpendialougeComponent, {
            disableClose: true,
            data: {
                contentLength: contentLengthuser,
                selectionLength: selectionLengthuser,
                id: "test",
            },
            width: "450px",
        });
        dialogRef.afterClosed().subscribe((result) => {
            const kk = document.getElementById("kir");
            // // console.log(k);
            // this.dialogResult = result;
            if (result == true) {
                // // console.log(d);
                // // // console.log(d);
                this.viewPrinterCode = false;
                this.isInitComplete = true;
                this.onSubmit(userbarcode);
            } else {
                // // console.log(t);
            }
        });
    }

    getDocuments() {
        this.userbarcode = "";
        this.apiservice.getDocumentsApi().subscribe(
            (data) => {
                // // console.log(a);
                data["data"].forEach((element) => {
                    // // // console.log(e);
                    if (
                        element.display_name != undefined &&
                        element.display_name != null &&
                        element.display_name != ""
                    ) {
                        this.departmentList.push({
                            id: element.name,
                            name: element.display_name,
                        });
                        // // // console.log(t);
                        this.departmentList.sort(this.sortByName);
                    }
                    // else{
                    //   this.departmentList.push(element.name)
                    // }
                });
            },

            (err) => {
                this.getStatus = err.error.message;
                this.errorMessage = err.error.message;
                // // console.log(e);
                if (err.status == 401) {
                    // // console.log(");,
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
            }

            // error: HttpErrorResponse => {
            //   this.getStatus = HttpErrorResponse.error.status;
            //   // // console.log(s);
            //   this.getError = HttpErrorResponse.error.error;
            //   // // console.log(r);
            // }
        );
    }

    departOnChangeVal(deviceValue) {
        this.insertCheckedchecked = [];
        this.selectedDepartmentName = "";
        this.selectedDepartment = "";
        this.showTable = false;
        for (let key in deviceValue) {
            if (key == "name") {
                this.selectedDepartmentName = deviceValue[key];
                // // console.log(e);
            }
            if (key == "id") {
                this.selectedDepartment = deviceValue[key];
                // // console.log(t);
            }
        }
        //  // // console.log(t);
        this.categoryList = [];
        this.subCategoryList = [];

        this.apiservice.getCategoryApi().subscribe({
            next: (data) => {
                // // // console.log(]);
                data["data"].forEach((element) => {
                    if (this.selectedDepartment == element.departments) {
                        if (
                            element.display_name != undefined &&
                            element.display_name != null &&
                            element.display_name != ""
                        ) {
                            this.categoryList.push({
                                id: element.name,
                                name: element.display_name,
                            });
                            this.categoryList.sort(this.sortByName);
                            //  // // console.log(t);
                        }
                    }
                });
            },
            error: (HttpErrorResponse) => {
                this.getStatus = HttpErrorResponse.error.status;
                // // console.log(s);
                this.getError = HttpErrorResponse.error.error;
                // // console.log(r);
            },
        });
    }

    sortByName(a: any, b: any) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    }

    categoryOnChangeVal(deviceValue) {
        this.insertCheckedchecked = [];
        this.showTable = false;
        this.subCategoryList = [];
        this.selectedCategoryName = "";
        this.selectedCategory = "";

        for (let key in deviceValue) {
            if (key == "name") {
                this.selectedCategoryName = deviceValue[key];
                //  // // console.log(e);
            }
            if (key == "id") {
                this.selectedCategory = deviceValue[key];
                // // // console.log(y);
            }
        }

        this.apiservice.getSubCategoryApi().subscribe({
            next: (data) => {
                //  // // console.log(]);
                data["data"].forEach((element) => {
                    if (this.selectedCategory == element.categories) {
                        if (
                            element.display_name != undefined &&
                            element.display_name != null &&
                            element.display_name != ""
                        ) {
                            this.subCategoryList.push({
                                id: element.name,
                                name: element.display_name,
                            });
                            this.subCategoryList.sort(this.sortByName);
                            // // // console.log(t);
                        }
                        // else{
                        //   this.subCategoryList.push(element.name.trim());
                        // }
                        //  // // console.log(e);
                    }
                });
            },
            error: (HttpErrorResponse) => {
                this.getStatus = HttpErrorResponse.error.status;
                // // console.log(s);
                this.getError = HttpErrorResponse.error.error;
                // // console.log(r);
            },
        });
    }

    subCategoryOnChangeVal(deviceValue) {
        this.insertCheckedchecked = [];
        this.selection.clear();
        this.selectionlength = 0;
        this.showTable = true;
        this.setsDisplayName = "";
        this.setsPrintTitle = "";
        this.tableList = [];
        this.dataSource = new MatTableDataSource<any>();
        this.content = [];
        this.contentLength = 0;

        this.selectedSubCategoryName = "";
        this.selectedSubCategory = "";

        for (let key in deviceValue) {
            if (key == "name") {
                this.selectedSubCategoryName = deviceValue[key];
                // // console.log(e);
            }
            if (key == "id") {
                this.selectedSubCategory = deviceValue[key];
                // // console.log(y);
            }
        }

        // // // console.log(y);
        this.apiservice
            .getSet_templatesApi(
                this.selectedDepartment,
                this.selectedCategory,
                this.selectedSubCategory
            )
            .subscribe({
                next: (data) => {
                    // this.tableList=data['data'];
                    // // console.log(]);
                    if (data["data"].length == 0) {
                        //  this.templateLength = true;
                        //  this.openDialogWithRef()
                        window.alert(
                            "This template does not exist, please contact the administrator."
                        );
                    }
                    data["data"].forEach((element) => {
                        this.setsDisplayName = element.display_name;
                        this.setsPrintTitle = element.print_title;
                        // // console.log(e);
                        element.instruments.forEach((element) => {
                            this.tableList.push({
                                name: element.name,
                                code: element.code,
                                quantity: element.quantity,
                            });
                        });
                    });
                    this.dataSource = new MatTableDataSource<any>(
                        this.tableList
                    );
                    this.setUsersForm();
                    // this.content = this.tableList;
                    this.contentLength = this.tableList.length;
                },
                error: (HttpErrorResponse) => {
                    this.getStatus = HttpErrorResponse.error.status;
                    // // console.log(s);
                    this.getError = HttpErrorResponse.error.error;
                    // // console.log(r);
                },
            });
    }

    openDialogWithRef(ref: TemplateRef<any>): void {
        this.dialog.open(ref, { width: "400px" });
        // this.router.navigate(['/viewtool']);
    }

    createExtSets() {
        // this.tableList = [];
        var quantityexts = "";
        var instrumentexts = "";
        this.addmoreButton = false;
        this.insertCheckedchecked = [];
        this.selection.clear();
        this.selectionlength = 0;
        this.showTable = true;
        // this.setsDisplayName = '';
        // this.setsPrintTitle = '';
        // quantityexts = qwa;
        //  instrumentexts = inst;
        // this.tableList = [];
        // this.dataSource = new MatTableDataSource<any>();
        this.content = [];
        this.contentLength = 0;
        // // console.log(t);
        this.tableList.push({
            name: this.instrumentext,
            code: "",
            quantity: this.quantityext,
        });
        this.dataSource = new MatTableDataSource<any>(this.tableList);
        this.setUsersForm();
        // this.content = this.tableList;
        this.contentLength = this.tableList.length;
        // instrumentext = '';
        // quantityext = '';

        if (this.currentType == "External") {
            this.selectedDepartment = "external";
            this.selectedCategory = "external";
            this.selectedSubCategory = "external";
            this.selectedDepartmentName = "-";
            this.selectedCategoryName = "-";
            this.selectedSubCategoryName = "-";
        }

        this.apiservice
            .getSet_templatesApi(
                this.selectedDepartment,
                this.selectedCategory,
                this.selectedSubCategory
            )
            .subscribe({
                next: (data) => {
                    // this.tableList=data['data'];
                    // // console.log(]);
                    if (data["data"].length == 0) {
                        //  this.templateLength = true;
                        //  this.openDialogWithRef()
                        window.alert(
                            "This template does not exist, please contact the administrator."
                        );
                    }
                    data["data"].forEach((element) => {
                        this.setsDisplayName = element.display_name;
                        this.setsPrintTitle = element.print_title;
                        // // console.log(e);
                        // element.instruments.forEach(element => {
                        //   this.tableList.push({ name: element.name, code: element.code, quantity: element.quantity})

                        // });
                    });
                    // this.dataSource = new MatTableDataSource<any>(this.tableList)
                    // this.setUsersForm();
                    // // this.content = this.tableList;
                    // this.contentLength = this.tableList.length;
                },
                error: (HttpErrorResponse) => {
                    this.getStatus = HttpErrorResponse.error.status;
                    // // console.log(s);
                    this.getError = HttpErrorResponse.error.error;
                    // // console.log(r);
                },
            });
    }

    onSubmit(userbarcode) {
        this.insertCheckedchecked = [];
        this.instruments = [];
        this.lastId = 0;
        this.setBulkReport = [];
        this.setBulkReportForTrue = [];
        this.selectedLength = this.selection.selected.length;
        this.getTemplateList = this.tableForm.value;
        this.changesRowLength = this.getTemplateList["setTemplateList"].length;
        // // console.log(e);
        this.finalBarcode = "";
        var text = userbarcode;
        var getPart = text.replace(/[^\d.]/g, "");
        var num = parseInt(getPart);
        var newVal = (num + 1).toString().padStart(getPart.length, "0");
        this.finalBarcode = text.replace(getPart, newVal);
        // // console.log(e);

        this.getTemplateList["setTemplateList"].forEach((element) => {
            // // console.log(x);

            if (element.checkbox !== false && element.checkbox !== true) {
                //  // // console.log(');
                this.setBulkReport.push(element);
                // // // console.log(t);
            }
            // // // console.log(x);
            else if (element.checkbox == true) {
                // // // console.log(t);
                this.setBulkReportForTrue.push(element);
            }

            //   this.insertCheckedchecked=[]
            //   this.instruments=[];
            //   this.lastId=0;
            //   this.setBulkReport=[];
            //   this.setBulkReportForTrue=[];
            //   this.selectedLength = this.selection.selected.length;
            //    this.getTemplateList = this.tableForm.value;

            //   this.getTemplateList['setTemplateList'].forEach(element => {
            // // // // console.log(x);

            // if(element.checkbox == false && element.checkbox ==true && this.selectionlength ==this.contentLength  )
            //     {
            //               this.setBulkReport.push(element)
            //                // // console.log(');

            //     }
            //     if(element.checkbox == false && element.checkbox ==true && this.selectionlength !=this.contentLength  )
            //     {
            //               this.setBulkReport.push(element)
            //                // // console.log(');

            //     }

            //       if(element.checkbox == true && this.selectionlength != this.contentLength){
            //     //  // // console.log(t);
            //     // // console.log(');

            //     this.setBulkReportForTrue.push(element)
            //       }
            // if(this.selectionlength == this.contentLength && element.checkbox == true){
            //   this.setBulkReport.push(element)
            //   // // console.log(');
            // }
            // if(element.checkbox != false && element.checkbox != true &&  this.selectionlength == this.contentLength){
            //   this.setBulkReport.push(element)
            //   // // console.log(');
            // }
            // if(element.checkbox != false && element.checkbox != true &&  this.selectionlength != this.contentLength){
            //   this.setBulkReport.push(element)
            //   // // console.log(');
            // }
        });

        this.cassetteservice
            .getSetsDetailByBarcode(this.finalBarcode)
            .subscribe((a) => {
                // // console.log(e);
                Object.entries(a).find(([key, value]) => {
                    if (key == "status" && value == "success") {
                        // // console.log(e);
                        this.isInitComplete = false;
                        const message =
                            "This Barcode (" +
                            this.finalBarcode +
                            ") is already exist please enter new Barcode";
                        this.openSnackBar(message, "OK");
                    } else if (key == "message" && value == "No data Found") {
                        this.getlatestBarcode(this.finalBarcode);
                    }
                });
            });

        (err) => {
            this.errorMessage = err.error.message;
            this.errorMessage = err.error.message;
            // // console.log(e);
            if (err.status == 401) {
                // // console.log(");,
                alert("Token is invalid or has expired Please relogin");
                this.logout();
            }
        };
    }

    getlatestBarcode(userbarcode) {
        // this.loadservice.getLastusedBarcode().then(async (token) => {
        //   // // // console.log(d);
        //   this.finalBarcode = '';

        // });
        // this.apiservice.getLatestIdApi().subscribe({
        //   next: data => {
        // // // console.log(a);
        // this.lastId = 0;
        // data['data'].forEach(element => {
        //   let lastId2 = parseInt(element._id, 10);
        //   // // console.log(2);
        //   this.lastId = lastId2 + 1;
        //   // // console.log(d);

        // })
        // // // console.log(h);
        if (this.setBulkReportForTrue.length !== 0) {
            this.setBulkReportForTrue.forEach((element) => {
                this.time = new Date(new Date()).getTime();
                // // // console.log(e);
                this.instruments.push({
                    name: element.name,
                    quantity: element.quantity,
                    code: element.code,
                });
            });
        } else {
            this.setBulkReport.forEach((element) => {
                this.time = new Date(new Date()).getTime();

                // // // console.log(e);
                this.instruments.push({
                    name: element.name,
                    quantity: element.quantity,
                    code: element.code,
                });
            });
        }

        const created_by = {
            id: this.userService.getCookie("email"),
            name: this.currentUserName,
            timestamp: this.time.toString(),
        };
        this.insertCheckedchecked = {
            attached: false,
            barcode: userbarcode,
            categories: this.selectedCategoryName,
            created_by: created_by,
            departments: this.selectedDepartmentName,
            timestamp: this.time.toString(),
            display_name: this.setsDisplayName,
            print_title: this.setsPrintTitle,
            instruments: this.instruments,
            subcategories: this.selectedSubCategoryName,
            set_scope: this.currentType.toLowerCase(),
            verified: false,
        };
        // // // console.log(h);
        this.apiservice
            .insertCheckedTemplates(this.insertCheckedchecked)
            .subscribe({
                next: (data) => {
                    this.returnUpdatebarcode = "";
                    // // console.log(a);
                    // this.returnUpdatebarcode = this.loadservice.updateLastUsedBarcode(this.finalBarcode)
                    // this.returnUpdatebarcode.then(() => {
                    if (data) {
                        sessionStorage.setItem(
                            "successMessage",
                            "Label Printed Successfully "
                        );
                        sessionStorage.setItem("lastUsedBarcode", userbarcode);
                        sessionStorage.setItem("showFinalPrint", "true");
                        // // console.log(e);
                        sessionStorage.setItem(
                            "generatedPrintTitle",
                            this.setsPrintTitle
                        );
                        this.elementType = NgxQrcodeElementTypes.IMG;
                        this.correctionLevel =
                            NgxQrcodeErrorCorrectionLevels.LOW;
                        this.value = userbarcode;
                        setTimeout(() => {
                            const timeout = 5000;
                            this.printTest1();

                            const dialogRef = this.dialog.open(
                                OpendialougeComponent,
                                {
                                    disableClose: true,
                                    data: {
                                        showFinalPrint: "true",
                                        message: "Label Printed Successfully ",
                                        finalBarcode: userbarcode,
                                        generatedPrintTitle:
                                            this.setsPrintTitle,
                                    },
                                    width: "450px",
                                }
                            );
                            dialogRef.afterOpened().subscribe((gg) => {
                                setTimeout(() => {
                                    dialogRef.close();
                                    // this.isInitComplete = false;
                                }, timeout);
                            });

                            dialogRef.afterClosed().subscribe((result) => {});
                        }, 7000);
                    } else {
                        // this.openSnackBar(data.status, 'OK');
                        // // console.log(");
                    }
                    this.subCategoryList = [];
                    this.showTable = false;
                    this.ngOnInit();
                    // });
                    //   }
                    // })
                },
            });
        this.apiservice.updateLastBarcodeUsed(userbarcode).subscribe((a) => {
            // // console.log(a);
        });
    }

    getprinterconfig() {
        this.apiservice.getLabelprintersApi().subscribe({
            next: (data) => {
                this.lastId = 0;
                // // console.log(]);
                data["data"].forEach((element) => {
                    this.connect_type = element.connect_type;
                    this.barcode_type = element.barcode_type;
                    this.printer_dpi = element.printer_dpi;
                    this.dpi = element.dpi;
                    // let map=dpi.get(printer_dpi)
                    let jsonMap = new Map(Object.entries(this.dpi));
                    // // console.log(p);
                    for (var entry of jsonMap.entries()) {
                        let key = entry[0],
                            value = entry[1];
                        let innerJson = JSON.stringify(value);
                        let innerJsonObj = JSON.parse(innerJson);
                        // // // console.log(y);
                        // // console.log(j);
                        if (key == this.printer_dpi) {
                            this.qrwidth = innerJson["qrwidth"];
                            this.titlemargintop =
                                innerJsonObj["titlemargintop"]; // new field add as
                            this.lablemargintop =
                                innerJsonObj["lablemargintop"];
                            this.labelmarginright =
                                innerJsonObj["labelmarginright"];
                            this.titlefontsize = innerJsonObj["titlefontsize"];
                            this.barcodefontsize =
                                innerJsonObj["barcodefontsize"];
                            this.pagemargintop = innerJsonObj["pagemargintop"];
                            this.pagemarginbottom =
                                innerJsonObj["pagemarginbottom"];
                            this.pagemarginleft =
                                innerJsonObj["pagemarginleft"];
                            this.pagemarginright =
                                innerJsonObj["pagemarginright"];
                            this.rightContainer = {
                                "font-family":
                                    "Verdana, Geneva, Tahoma, sans-serif",

                                "margin-right": this.labelmarginright + "px", //50

                                "margin-top": "20px", //20
                            };

                            this.headingBarcode = {
                                // 'font-size': this.barcodefontsize + 'px',
                                "margin-top": "30px", //20
                                "font-size": "45px",
                            };
                            //style="margin-top:50px; font-size: 16px;
                            this.headingTitle = {
                                // 'font-family': 'Verdana, Geneva, Tahoma, sans-serif',
                                "margin-top": this.titlemargintop + "px",
                                // 'font-size': this.titlefontsize + 'px',
                                "font-size": "45px",
                            };
                        }
                    }
                });
            },
        });
    }

    openSnackBar(message: string, action: string): void {
        const currentTime = new Date().getTime();
        // this.lastSnackTime = currentTime;
        this.snackBar.open(message, action, {
            duration: 6000,
        });
    }

    printTest1() {
        this.pagemargintop = "0in";
        this.pagemarginbottom = "5in";
        this.pagemarginleft = "0.8in";
        this.pagemarginright = "0in";
        es6printJS({
            printable: "form1",
            type: "html",
            targetStyles: ["*"],

            style: `@page { margin-top: -0.6cm;

        margin-bottom: 8cm;

        margin-left: 1.9cm;

        margin-right: 0cm;   } @media print {

          body * {

            margin: 15mm 15mm 15mm 15mm;

          }

          #section-to-print, #section-to-print * {

            visibility: visible;

          }

          #section-to-print {

            position: absolute;

            left: 0;

            top: 0;

          }

        }`,
        });

        this.viewPrinterCode = true;
        this.isInitComplete = false;
    }
}
