import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewEncapsulation,
} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { Label } from "./label";
// import * as moment from 'moment';
import { MatTableDataSource } from "@angular/material/table";
import { LabelService } from "./label.service";
import { Observable, Subject } from "rxjs";
import { BaseListComponent } from "../../base/base-list.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigService } from "../../common/config/config.service";
import { Globals } from "../../common/global";
import { StorageService } from "../../common/service/storage.service";
import { ApiService } from "../../common/service/api.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
    FormBuilder,
    FormGroup,
    Validators,
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from "@angular/forms";
import { IpServiceService } from "../../common/service/ip-service.service";
const timeZone = require("moment-timezone");

@Component({
    selector: "label-table",
    templateUrl: "label.component.html",
    styleUrls: ["label.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class LabelComponent
    extends BaseListComponent
    implements OnInit, OnDestroy
{
    page = 0;
    // columns = this.getColumn();
    columns: Array<any> = [];

    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    // displayedColumns = this.columns.map(c => c.columnDef);
    displayedColumns: Array<any> = [];
    length: any;
    perPageData = this.globals.perPageData;
    protected extraCta = {
        visibility: false,
        text: "",
    };
    showHtmlTemplate = false;
    // enableInfoMsg : false;

    registerForm: FormGroup;
    registerFormEdit: FormGroup;

    loggedinUser = this.userService.getCookie("name");
    ipAddress = "";
    role = this.userService.getCookie("role");
    courses: any;
    tabledata: Label[] = [];
    barcodeList: Array<any> = [];
    // labelIdList: Array<any>;
    prev: number = 1;
    next: number = 1;
    count: number;
    totalPage: number;
    typeExist: boolean;
    // addlabelData: {
    //     lab_id: string; label_id: any; update_data: {
    //         // label_id: this.registerForm.value.labelId,
    //         current_count: number; minimum_count: any; reminder_count: any; added_from: any; added_to: any; added_by: any; added_on: number; used_by: any; used_on: number;
    //     };
    // };
    addlabelData: any;
    addCurrentCount: any;
    addLabelId: any;
    bcode: Array<any>;
    // current_count: number;
    // from: any;
    // to: any;

    constructor(
        public paginationService: LabelService,
        private changeDetectorRefs: ChangeDetectorRef,
        public snackBar: MatSnackBar,
        private router: Router,
        private configService: ConfigService,
        private globals: Globals,
        private userService: StorageService,
        private apiService: ApiService,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private ip: IpServiceService
    ) {
        super(snackBar, configService, globals);

        this.registerFormEdit = this._formBuilder.group(
            {
                currentCount: ["", Validators.required],
                lastPrintedFrom: ["", Validators.required],
                lastPrintedTo: ["", Validators.required],
                Type: ["", Validators.required],
                labelEdit: ["", Validators.required],
                minimumCount: [
                    "",
                    [
                        Validators.required,
                        Validators.pattern(
                            /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                        ),
                    ],
                ],
                // hasLimit: ['', Validators.required],
                // validTillDate: [{value :''}, ],
            },
            { validator: validateValues }
        );
    }

    /**
     * On init
     */
    ngOnInit(): void {
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.registerForm = this._formBuilder.group(
                {
                    lastPrintedFrom: ["", Validators.required],
                    lastPrintedTo: ["", Validators.required],
                },
                { validators: validateValuesAddLabel }
            );

            // this.registerForm = this._formBuilder.group({
            //     lastPrintedFrom: ['', Validators.required],
            //     lastPrintedTo: ['', Validators.required],
            // }, { validators: validateAddValues});
            // // console.log(e);
            this.prev = 1;
            this.next = 1;
            this.page = 0;
            this.count = 1;
            // // console.log(e);
            if (this.role === "support") {
                const initCol = this.getColumn();
                const deleteColumn = {
                    columnDef: "delete",
                    header: "Delete",
                    cell: (element: Label, index: number) => `${element.id}`,
                };

                const editColumn = {
                    columnDef: "edit",
                    header: "Edit",
                    cell: (element: Label, index: number) => `${element.id}`,
                };

                const viewColumn = {
                    columnDef: "view",
                    header: "View",
                    cell: (element: Label, index: number) => `view`,
                };

                // // console.log(n);
                // // console.log(n);
                // // console.log(n);
                // initCol.push(viewColumn);
                initCol.push(editColumn);
                this.showHtmlTemplate = true;
                this.columns = initCol;
                this.displayedColumns = this.columns.map((c) => c.columnDef);
                // // console.log(s);
            } else {
                this.showHtmlTemplate = true;
                this.columns = this.getColumn();
                this.displayedColumns = this.columns.map((c) => c.columnDef);
            }

            this.page = 0;
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
    }

    // loadButtonClick(value:any) {
    //     // // console.log(e);
    //     const lastPrintedFrom = this.registerForm.value.lastPrintedFrom;
    //     // // console.log(m);

    //     // this.router.navigateByUrl('/addLoad', { state: { sterilizerName: this.sterilizer.name } });
    // }

    // ngAfterViewInit(): void {
    //    // this.bcode=this.getTypefromLabConfig();
    //     }

    getColumn(): any[] {
        // // console.log(');

        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: Label, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "labelType",
                header: "Type",
                cell: (element: Label, index: number) => `${element.id}`,
            },
            {
                columnDef: "currentCount",
                header: "CurrentCount",
                cell: (element: Label, index: number) =>
                    `${element.currentCount}`,
            },
            {
                columnDef: "minimumCount",
                header: "Threshold",
                cell: (element: Label, index: number) =>
                    `${element.minimumCount}`,
            },
            {
                columnDef: "lastPrintFrom",
                header: "LastPrintedFrom",
                cell: (element: Label, index: number) =>
                    `${element.lastPrintFrom}`,
            },
            {
                columnDef: "lastPrintTo",
                header: "LastPrintedTo",
                cell: (element: Label, index: number) =>
                    `${element.lastPrintTo}`,
            },
            {
                columnDef: "lastPrintBy",
                header: "PrintedBy",
                cell: (element: Label, index: number) =>
                    `${element.lastPrintBy}`,
            },

            {
                columnDef: "lastPrintOn",
                header: "PrintedOn",
                cell: (element: Label, index: number) =>
                    `${this.getDate(element.lastPrintOn)}`,
            },

            {
                columnDef: "lastUsed",
                header: "LastUsed",
                cell: (element: Label, index: number) => `${element.lastUsed}`,
            },
            {
                columnDef: "lastUsedOn",
                header: "LastUsedOn",
                cell: (element: Label, index: number) =>
                    `${this.getDate(element.lastUsedOn)}`,
            },
        ];
    }

    updateRow(row): any {}

    getTitleMatIcon(): string {
        return "label";
    }

    getTitle(): string {
        return "Labels Replenishment";
    }

    getDate(numberValue): string {
        const value = parseInt(numberValue);
        if (value === 0) {
            return "-";
        } else {
            // return moment(Math.abs(numberValue)).format('DD-MM-YYYY | HH:mm');
            return this.apiService.getDateStringMomentByTz(value);
        }
    }

    getRowBackgroundColor(element): string {
        if (element.currentCount < element.minimumCount) {
            return "#F29E9E";
        } else {
            return "#FFFFFF";
        }
    }

    protected getPaginationService() {
        // return this.paginationService;
        return this.AllLables(1);
    }

    AllLables(page) {
        // // console.log(e);
        this.hasFirstDataShown = false;
        this.paginationService.getallLabelData(page).subscribe((categories) => {
            // // console.log(s);
            Object.entries(categories).find(([key, value]) => {
                // if(key == 'total_pages'){
                //     this.totalPage = value;
                //      }
                //      if(key == 'current_pages'){
                //         this.currentpage = value;
                //     }
                if (key == "data") {
                    this.courses = value;
                    // // console.log(s);
                    this.hasFirstDataShown = true;
                    this.courses.forEach((element) => {
                        // // // console.log(d);
                        if (element.added_from == undefined) {
                            element.added_from = "-";
                        }
                        if (element.added_to == undefined) {
                            element.added_to = "-";
                        }
                        this.tabledata.push({
                            currentCount: element.current_count,
                            lastUsed: element.used_by,
                            lastUsedOn: element.used_on,
                            lastPrintFrom: element.added_from,
                            lastPrintTo: element.added_to,
                            lastPrintBy: element.added_by,
                            lastPrintOn: element.added_on,
                            minimumCount: element.minimum_count,
                            reminderCount: element.reminder_count,
                            labelValue: "",
                            days: 0,
                            count: 0,
                            id: element.label_id,
                            docId: "",
                        });
                    });
                    this.dataSource.data = this.tabledata;
                } else {
                    // // console.log(e);
                }
            });
        });
    }

    getTypefromLabConfig(): any {
        this.apiService.getLabconfigdata().subscribe((config) => {
            // // console.log(g);
            Object.entries(config).find(([key, value]) => {
                // // console.log(e);
                if (key == "data") {
                    this.courses = value;
                    // // console.log(s);
                    this.courses.forEach((element) => {
                        this.barcodeList.push(element.label.barcode);
                        // return this.barcodeList
                        // // console.log(t);
                    });

                    // // console.log(t);
                }
                // else {
                //     // // console.log(e);
                // }
            });
        });
        // // console.log(t);
        return this.barcodeList;
    }

    deleteLabel(docId) {
        // this.paginationService.deleteLabelById(docId).then(result => {
        //     const msg = 'Label Deleted Successfully';
        //     this.openSnackBar(msg, 'OK');
        // }).catch((error) => {
        //     this.handleError(error);
        // });
    }

    editLabel(docId) {
        const lab_id = this.userService.getCookie("lab_id");
        // // console.log(d);
        const labelData = {
            lab_id: lab_id,
            label_id: docId,
            update_data: {
                current_count: Number(this.registerFormEdit.value.currentCount),
                used_by: this.registerFormEdit.value.lastUsed,
                used_on: this.registerFormEdit.value.lastUsedOn,
                added_from: this.registerFormEdit.value.lastPrintedFrom,
            },
        };
        // // console.log(a);
        this.paginationService
            .updateLabel(labelData)
            .subscribe((returnData) => {
                // // console.log(a);
                Object.entries(returnData).find(([key, value]) => {
                    if (key == "data") {
                        this.courses = value;
                        // // console.log(s););
                        this.hasFirstDataShown = true;

                        const msg = "Label Updated Successfully";
                        this.openSnackBar(msg, "OK");
                        window.location.reload();
                    } else {
                        // // console.log(e);
                    }
                });
            });
    }

    onNextPress() {
        // this.hasEditDataShown=false;
        // this.loadingSpinner=false;
        // this.isLoading = true;
        // this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1;
        this.page++;
        this.AllLables(this.next);
        // // console.log(v);
        this.next++;
        this.prev++;
        // this.scrollToBottom();
    }

    onPrevPress() {
        // this.hasEditDataShown=false;
        // this.loadingSpinner=false;
        this.count = this.count - 1;
        this.prev = this.next - 1;
        this.prev--;
        this.next--;
        this.page--;
        this.AllLables(this.prev);
        // // console.log(t);
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    openDialogWithRefDel(ref: TemplateRef<any>): void {
        this.dialog.open(ref, { width: "400px" });
    }

    openDialogWithRefEdit(ref: TemplateRef<any>, row): void {
        this.registerFormEdit.setValue({
            currentCount: row.currentCount,
            lastPrintedFrom: row.lastPrintFrom,
            lastPrintedTo: row.lastPrintTo,
            Type: row.id,
            labelEdit: "Edit label-" + row.id,
            minimumCount: row.minimumCount,
        });
        this.dialog.open(ref, { width: "400px" });
    }

    openDialogWithRefAdd(ref: TemplateRef<any>, row): void {
        // this.registerForm.setValue({
        //     lastPrintedFrom: row.lastPrintFrom,
        //     lastPrintedTo: row.lastPrintTo,
        //     Type: this.addLabelId,
        //     labelEdit: "Edit label-" + row.id,
        //     minimumCount: row.minimumCount
        // });
        // this.dialog.open(ref, { width: '400px' });
        // this.datePick = false;
        this.dialog.open(ref, { width: "320px", disableClose: true });
    }

    getDetail(id) {
        this.router.navigate(["/labeldetail"], {
            queryParams: { barcode: id },
        });
    }

    check(col) {
        // // console.log(");
        // // console.log(l);
        return false;
    }
    getIP() {
        this.ip.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res.ip;
            // // console.log(");
            // // console.log(s);
        });
    }

    addLabel() {
        const lab_id = this.userService.getCookie("lab_id");
        const name = this.userService.getCookie("name");
        const lastPrintedFrom = this.registerForm.value.lastPrintedFrom; //  BD12
        const lastPrintedTo = this.registerForm.value.lastPrintedTo;
        // // console.log(s);
        // // console.log(e);
        // var fromType, fromNo;
        var fromFlag = false;
        var from = 0;
        var to = 0;
        for (let i = 0; i < this.barcodeList.length; i++) {
            if (lastPrintedFrom.startsWith(this.barcodeList[i])) {
                from = lastPrintedFrom.replace(this.barcodeList[i], "");
                fromFlag = true;
            }
            if (lastPrintedTo.startsWith(this.barcodeList[i])) {
                to = lastPrintedTo.replace(this.barcodeList[i], "");
            }
        }
        const current_count = to - from + 1;

        this.courses.forEach((element) => {
            if (lastPrintedFrom.startsWith(element.label_id)) {
                // // console.log(d);
                this.typeExist = true;
                this.addCurrentCount = element.current_count;
                this.addLabelId = element.label_id;
            } else {
                // // console.log(d);
                // alert("Type Not exists to update");
            }
        });

        if (this.typeExist) {
            //in case of type exist , work for refill
            this.addlabelData = {
                lab_id: lab_id,
                label_id: this.addLabelId,
                update_data: {
                    current_count: Number(current_count + this.addCurrentCount),
                    added_from: this.registerForm.value.lastPrintedFrom,
                    added_to: this.registerForm.value.lastPrintedTo,
                    added_by: name,
                    added_on: (-1 * new Date().getTime()).toString(),
                },
            };
        } else {
            // in case type not exist , store new record
            for (let i = 0; i < this.barcodeList.length; i++) {
                if (lastPrintedFrom.startsWith(this.barcodeList[i])) {
                    this.addLabelId = this.barcodeList[i];
                }
            }
            const minimum_count = 10;
            const reminder_count = 10;
            this.addlabelData = {
                lab_id: lab_id,
                label_id: this.addLabelId,
                update_data: {
                    current_count: Number(current_count),
                    minimum_count: minimum_count.toString(),
                    reminder_count: reminder_count.toString(),
                    added_from: this.registerForm.value.lastPrintedFrom,
                    added_to: this.registerForm.value.lastPrintedTo,
                    added_by: name,
                    added_on: (-1 * new Date().getTime()).toString(),
                    used_by: "",
                    used_on: (-1 * new Date().getTime()).toString(),
                },
            };
        }

        this.paginationService
            .updateLabel(this.addlabelData)
            .subscribe((returnData) => {
                // // console.log(a);
                Object.entries(returnData).find(([key, value]) => {
                    if (key == "data") {
                        this.courses = value;
                        // // console.log(s););
                        this.hasFirstDataShown = true;

                        const msg = "Label Added Successfully";
                        this.openSnackBar(msg, "OK");
                        window.location.reload();
                    } else {
                        // // console.log(e);
                    }
                });
            });
    }

    FromType() {}

    onEditCancelFrm() {
        this.registerFormEdit.reset();
        // this.registerFormAdmin.reset();
        // this.daysArrayList = this.staticObjArr;
    }

    onAddCancelFrm() {
        this.registerForm.reset();
        // // console.log(m);
        this.registerForm.registerControl;

        // this.registerFormAdmin.reset();
        // this.daysArrayList = this.staticObjArr;
    }

    // validateValuesForAddLabel(){

    // }
}

export function validateValues(
    control: AbstractControl
): ValidationErrors | null {
    var fromType, toType, fromNo, toNo;
    // // // console.log("); = '+control.get("lastPrintedTo")+' , control.get("lastPrintedFrom") ='+control.get("lastPrintedFrom"))

    if (
        control &&
        control.get("lastPrintedTo") &&
        control.get("lastPrintedFrom")
    ) {
        var lastPrintedTo = control.get("lastPrintedTo").value;
        // // console.log(o);
        var lastPrintedFrom = control.get("lastPrintedFrom").value;
        var type = control.get("Type").value;
        fromType = lastPrintedFrom.substring(0, type.length);
        // // console.log(e);

        toType = lastPrintedTo.substring(0, type.length);
        fromNo = lastPrintedFrom.substring(type.length, lastPrintedFrom.length);
        toNo = lastPrintedTo.substring(type.length, lastPrintedTo.length);

        if (fromType != type || toType != type) {
            return { scoreErrorType: true };
        } else {
            var pattern = /^\d+$/;
            if (pattern.test(fromNo) != true || pattern.test(toNo) != true) {
                return { scoreErrorNumber: true };
            } else if (Number(fromNo) > Number(toNo)) {
                return { scoreError: true };
            }
        }
        //   return Number(fromNo) > Number(toNo) ? { scoreError: true } : null
        //   return fromType != toType ? { scoreErrorType: true } : null
    }
    return null;
}

export function validateValuesAddLabel(
    control: AbstractControl
): ValidationErrors | null {
    var fromType, toType, fromNo, toNo, typeFinal;
    if (
        control &&
        control.get("lastPrintedTo") &&
        control.get("lastPrintedFrom")
    ) {
        var lastPrintedTo = control.get("lastPrintedTo").value;
        // // console.log(o);
        var lastPrintedFrom = control.get("lastPrintedFrom").value;

        fromNo = lastPrintedFrom.replace(/\D/g, "");
        toNo = lastPrintedTo.replace(/\D/g, "");
        fromType = lastPrintedFrom.replace(fromNo, "");
        toType = lastPrintedTo.replace(toNo, "");

        if (fromType != toType || toType != fromType) {
            return { scoreErrorType: true };
        } else {
            var pattern = /^\d+$/;
            if (pattern.test(fromNo) != true || pattern.test(toNo) != true) {
                return { scoreErrorNumber: true };
            } else if (Number(fromNo) > Number(toNo)) {
                return { scoreError: true };
            }
        }
    }
    return null;
}
