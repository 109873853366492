import { Injectable } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseListService } from '../../../base/base-list.service';
import { StorageService } from 'app/main/module/common/service/storage.service';

@Injectable({ providedIn: 'root' })

export class LabelprefixService  {

    protected isOrderByNegative = true;
    constructor( private userServiceChild: StorageService) {
        // super(afsChild, userServiceChild);
        // this.table = 'config';
        // this.orderBy = '';
        // this.ROWS = 30;
    }
    // setTable(table, orderBy): void {
    //     this.table = table;
    //     this.orderBy = orderBy;
    // }

    // getFirstQuery(): any {
    //     return this.getCollection(this.getCollectionPath(), ref => ref
    //         .orderBy(this.orderBy)
    //         .limit(this.ROWS));
    // }

    // getNextQuery(): any {
    //     return this.getCollection(this.getCollectionPath(), ref => ref
    //         .orderBy(this.orderBy)
    //         .startAfter(this.latestEntry)
    //         .limit(this.ROWS));
    // }

    // getPrevQuery(): any {
    //     return this.getCollection(this.getCollectionPath(), ref => ref
    //         .orderBy(this.orderBy, 'desc')
    //         .limit(this.ROWS));

    // }

    // getCollectionPath(): string {
    //     return 'lab/' + this.userServiceChild.getCookie('lab') + '/' + this.table;
    // }

    // getSearchQueryWithOutText(): any {
    // }

    // getSearchQueryWithText(): any {
    // }


    // getConfigColumnLabel(){
    //  const configLabelPath= 'lab/' + this.userServiceChild.getCookie('lab') + '/config';
    //  return new Promise((res, rej )=>{

    //      this.afsChild.collection(configLabelPath).doc('label').get().subscribe(labelData =>{
    //          res(labelData.data().prefix);
    //      })

    //  })
    // }

    // getLabelPrefixById(docId) {

    //     const consentCollectionPath = this.getCollectionPath();
    //     return new Promise((resolve, reject) => {
    //         this.afsChild.collection(consentCollectionPath).doc(docId).valueChanges().subscribe(data => {

    //             resolve(data);
    //         });
    //     });
    // }

    // deleteLabelPrefixById(docId) {
    //     const collectionPath = `lab/${this.userServiceChild.getCookie('lab')}/covid/constant/${this.table}`;

    //     return new Promise<any>((resolve, reject) => {
    //         this.afsChild.collection(collectionPath).doc(docId).delete().then((result) => {
    //             const msg = 'Label Successfully Deleted';
    //             this.currentPage = 0;
    //             resolve(msg);
    //         }).catch((error) => {
    //             reject('Error removing document');
    //         });
    //     });
    // }

    // updateLabelPrefix(docId, consentData) {
    //     const consentCollectionPath = this.getCollectionPath();
    //     return new Promise((resolve, reject) => {
    //         this.afsChild.collection(consentCollectionPath).doc(docId).set(consentData).then((resultData) => {
    //             // window.alert(`Team Member ${teamData.name} Added Successfully`);
    //             const data = { msg: 'Updated SuccessFully', status: 'success' };
    //             resolve(data);

    //         }).catch((error) => {
    //             reject(error);
    //         });
    //     });
    // }

}
