import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    Validators,
} from "@angular/forms";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { AnalyticsService } from "../home/analytics.service";
import { throwError } from "rxjs";
import {
    DateAdapter,
    ErrorStateMatcher,
    MAT_DATE_FORMATS,
} from "@angular/material/core";
import * as moment from "moment";
import { APP_DATE_FORMATS, AppDateAdapter } from "./format-datepicker";
import { ApiService } from "../../common/service/api.service";
import { StorageService } from "app/main/module/common/service/storage.service";
import { ReportService } from "./report.service";
import { DatePipe } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

declare var google: any;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

@Component({
    selector: "alert",
    templateUrl: "./report.component.html",
    styleUrls: ["./report.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [
        DatePipe,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
})
export class ReportComponent implements OnInit {
    reportForm: FormGroup;
    reportId: any;
    patientName: any;
    loadStatus: boolean;
    // analyticService: any;
    range: Range;
    labs: string[] = []; // ['DownTown'];
    dateRange: any;
    colorGreen = "green";

    @Input()
    max: "D" | null;
    today = new Date();

    @Input()
    min: "D" | null;
    startAt: any;
    onclicktrue = false;
    filename: string;
    myDate: any;
    centerName: any;
    pdfFileName: string;
    apiName: string;
    allData: any;
    errorMessage: any;
    isLoginFailed: boolean;
    http: any;
    // filteredPatients: any;
    filteredPatients: Array<any> = [];

    getStatus: any;
    getError: any;
    default_time_zone: any;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public reportService: ReportService,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private userService: StorageService,
        private datePipe: DatePipe,
        public snackBar: MatSnackBar,
        public storageServise: StorageService,
        public router: Router
    ) {
        this.today.setDate(this.today.getDate());
        this.myDate = new Date();

        this.myDate = this.datePipe
            .transform(this.myDate, "yyyy-MM-dd_hh-mm-ss_a")
            .trim();
    }

    reportType = [
        { name: "Cassette Report", abbrev: "cassetteReport" },
        { name: "Sterilizer Load Report", abbrev: "loadReport" },
        { name: "Master Report", abbrev: "masterReport" },
        { name: "Assign Cassette Report", abbrev: "assignCassetteReport" },
        { name: "Alert Report", abbrev: "alertReport" },
        { name: "BI Alert Report", abbrev: "BIAlertReport" },
        { name: "Availability Report", abbrev: "availabilityReport" },
        { name: "SOD and EOD Report", abbrev: "sodEodReport" },
        {
            name: "Sterilizer Maintenance Load Report",
            abbrev: "sterilizerMaintenanceLoadReport",
        },
        { name: "Other Maintenance Report", abbrev: "otherMaintenanceReport" },
        { name: "Control BI Report", abbrev: "controlBIReport" },
        // { name: 'Sets Returned TO CSSD Report', abbrev: 'setsReturnedCSSD' },
    ];

    ngOnInit() {
        const labName = this.userService.getCookie("lab_id");
        if (labName) {
            this.labs.push(labName);
            this.reportForm = this.formBuilder.group({
                labListName: ["", Validators.required],
                // departmentName: [''],
                from: ["", Validators.required],
                to: ["", Validators.required],
                patientId: [""],
                patientName: [""],
            });
            this.onclickgetDocuments();
        } else {
            this.logout();
        }
    }

    onclickgetDocuments() {
        this.apiService.getDocumentsApi().subscribe({
            next: (data) => {
                data["data"].forEach((element) => {
                    if (element.display_name != undefined) {
                        var displayName = element.display_name;
                        this.filteredPatients.push(displayName);
                    } else {
                        this.openSnackBar("Department Name Not Found.", "OK");
                    }
                });
            },
            error: (HttpErrorResponse) => {
                this.getStatus = HttpErrorResponse.error.status;
                this.getError = HttpErrorResponse.error.error;
            },
        });
        return this.filteredPatients;
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    fromDateVal(value) {
        this.startAt = value;
    }

    reportOnChangeVal(reportId): void {
        this.reportId = reportId;
    }

    departmentOnChangeVal(patientName): void {
        this.patientName = patientName;
    }

    updateRange(range: Range) {
        this.range = range;
    }

    downPdf1(fromD, toD, patientId, patientName): void {
        this.default_time_zone = this.storageServise.getCookie("defaultTz");
        if (this.default_time_zone.includes("America/Toronto")) {
            fromD = fromD
                ? moment
                      .tz(fromD, "DD/MM/YYYY", this.default_time_zone)
                      .format("DD/MM/YYYY")
                : moment().format("DD/MM/YYYY");
            toD = toD
                ? moment
                      .tz(toD, "DD/MM/YYYY", this.default_time_zone)
                      .format("DD/MM/YYYY")
                : moment().format("DD/MM/YYYY");
        } else {
            // fromD = fromD ? moment.tz(fromD, 'DD/MM/YYYY', this.default_time_zone).add(1, 'day').format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
            // toD = toD ? moment.tz(toD, 'DD/MM/YYYY', this.default_time_zone).add(1, 'day').format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
            fromD = fromD
                ? moment
                      .tz(fromD, "DD/MM/YYYY", this.default_time_zone)
                      .format("DD/MM/YYYY")
                : moment().format("DD/MM/YYYY");
            toD = toD
                ? moment
                      .tz(toD, "DD/MM/YYYY", this.default_time_zone)
                      .format("DD/MM/YYYY")
                : moment().format("DD/MM/YYYY");
        }

        const fromTimestamp = moment
            .tz(fromD, "DD/MM/YYYY", this.default_time_zone)
            .valueOf();
        const toTimestamp =
            moment.tz(toD, "DD/MM/YYYY", this.default_time_zone).valueOf() +
            86309000;

        // // console.log(p);
        // console.log(t);:", toTimestamp);
        // const from = moment(fromD, 'DD/MM/YYYY').format('MM/DD/YYYY');
        // const to = moment(toD, 'DD/MM/YYYY').format('MM/DD/YYYY');
        //  const fromTimestamp = (new Date(from).getTime())+8.64e+7;
        // const toTimestamp = new Date(to).getTime() + 8.64e+7;
        // let afterfromTimestamp;
        // let aftertoTimestamp;
        // if(fromTimestamp == toTimestamp){
        //     // // console.log(p);
        //     afterfromTimestamp = new Date(to).getTime();
        //     aftertoTimestamp = (new Date(to).getTime() + 86309000);
        //     // console.log(p);
        // }else{
        //     afterfromTimestamp = fromTimestamp - 86309000 ;
        //     aftertoTimestamp = toTimestamp - 86309000;
        //     // console.log(p);
        // }

        this.loadStatus = true;
        if (true) {
            this.centerName = this.getStringOrDefault(
                this.userService.getCookie("center"),
                ""
            );
            // // // console.log(e);
            this.onclicktrue = true;
            this.filename = (
                this.reportId.trim() +
                "_" +
                this.myDate +
                ".pdf"
            ).trim();
            //  // // console.log(e);
            if (this.reportId == "cassetteReport") {
                this.apiName = "cassettes/report?";
                this.pdfFileName = "CR";
            } else if (this.reportId == "loadReport") {
                this.apiName = "sterilizerload/report?";
                this.pdfFileName = "SLR";
            } else if (this.reportId == "masterReport") {
                this.apiName = "master/report?";
                this.pdfFileName = "MR";
            } else if (this.reportId == "assignCassetteReport") {
                this.apiName =
                    "assignsets/report?patient_id=" +
                    patientId +
                    "&patient_name=" +
                    patientName +
                    "&";
                this.pdfFileName = "ACR";
            } else if (this.reportId == "alertReport") {
                this.apiName = "alert/report?";
                this.pdfFileName = "Alert";
            } else if (this.reportId == "BIAlertReport") {
                this.apiName = "bialert/report?";
                this.pdfFileName = "BiAlert";
            } else if (this.reportId == "availabilityReport") {
                this.apiName = "availability/report?";
                this.pdfFileName = "AVR";
            } else if (this.reportId == "sodEodReport") {
                this.apiName = "dailytask/report?";
                this.pdfFileName = "SOD_EOD";
            } else if (this.reportId == "sterilizerMaintenanceLoadReport") {
                this.apiName = "mnloads/report?";
                this.pdfFileName = "SMnLR";
            } else if (this.reportId == "otherMaintenanceReport") {
                this.apiName = "othermn/report?";
                this.pdfFileName = "OMnR";
            } else if (this.reportId == "controlBIReport") {
                this.apiName = "cbis/report?";
                this.pdfFileName = "CBI";
            }
            // else if (this.reportId == 'setsReturnedCSSD') {//Sets Returned to CSSD
            //     this.apiName = 'returnedsets/report?'
            //     this.pdfFileName = 'CSSD'
            // }
            this.filename = (
                "WEB_" +
                this.pdfFileName +
                fromD +
                "to" +
                toD +
                ".pdf"
            ).trim();
            // // console.log(p);
        }

        this.reportService
            .getPdfUrlReportByReportId(fromTimestamp, toTimestamp, this.apiName)
            .subscribe(
                (a) => {
                    // // console.log(e);
                    Object.entries(a).find(([key, value]) => {
                        if (key == "data" && value != "") {
                            this.allData = value;
                            const msg = "Data Found";
                            if (msg == "Data Found") {
                                this.downloadPdf(this.allData, this.filename);
                            }
                            //   this.reportForm.reset();
                        } else if (key == "status" && value == "failed") {
                            this.loadStatus = false;
                            // // // console.log(e);
                            const msg = "No Data Found";
                            this.openSnackBar(msg, "OK");
                            // this.reportForm.reset();
                        }
                    });
                },
                (err) => {
                    this.errorMessage = err.error.message;
                    this.loadStatus = false;
                    // this.openSnackBar("Report Download Succesfully", 'OK');
                    this.isLoginFailed = true;
                }
            );
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 1000,
        });
    }

    getTimestampData(dateRangeVal: any) {
        const rangeVal = dateRangeVal;
        const rangeDatesArr = rangeVal.split("-");
        const dFrom = rangeDatesArr[0].split("-");
        const dTo = rangeDatesArr[1].split("-");
        return [new Date(dFrom).getTime(), new Date(dTo).getTime()];
    }
    getStringOrDefault(reqkey: any, defaulvalue: any) {
        const rg = defaulvalue;
        try {
            if (reqkey === undefined || reqkey === null) {
                return defaulvalue;
            } else {
                return reqkey;
            }
        } catch (error) {
            rg == defaulvalue;
        }
    }

    downloadPdf(pdfUrl, filename): void {
        this.reportService.downloadPdf(pdfUrl).subscribe((response: Blob) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const file = new File([blob], filename, {
                type: "application/pdf",
            });
            const url = URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.title = filename;
            // // console.log(e);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            this.openSnackBar("Report Download Successfully", "OK");
            this.loadStatus = false;
        });
    }
}
