import { Injectable } from "@angular/core";
import { StorageService } from "app/main/module/common/service/storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
@Injectable({ providedIn: "root" })
export class CassetteListService {
    protected searchBy = "barcode";

    baseUrl = environment.baseUrl;

    apiUrl = this.baseUrl + "/api/v1/";
    constructor(
        private userServiceChild: StorageService,
        private httpClient: HttpClient
    ) {}

    getSetsDetailByBarcode(barcode) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        // // console.log(e);
        //   const headers = new HttpHeaders().set('Authorization', `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this.httpClient.get(
            this.apiUrl + "cassettes?lab_id=" + lab_id + "&barcode=" + barcode,
            httpOptions
        );
    }

    getSetsListByBarcodes(barcodeList) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this.httpClient.post(
            this.apiUrl + "cassettes?lab_id=" + lab_id,
            barcodeList,
            { headers }
        );
    }

    getAllCassetteData(page, attached) {
        const token = this.userServiceChild.getCookie("token");
        // // // console.log(n);
        const lab_id = this.userServiceChild.getCookie("lab_id");
        //   const headers = new HttpHeaders().set('Authorization', `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this.httpClient.get(
            this.apiUrl +
                "cassettes?lab_id=" +
                lab_id +
                "&attached=" +
                attached +
                "&page=" +
                page,
            httpOptions
        );
    }

    getAllPatientCheckoutData(page) {
        const token = this.userServiceChild.getCookie("token");
        // // // console.log(n);
        const lab_id = this.userServiceChild.getCookie("lab_id");
        const status = "Assigned";
        const status2 = "Used";
        //   const headers = new HttpHeaders().set('Authorization', `${token}`); /api/v2/cassettesper_page=1&page=1&lab_id=kirtilab11333&set_status=Ready
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this.httpClient.get(
            this.apiUrl +
                "cassetteslab_id=" +
                lab_id +
                "&list_set_status=" +
                status +
                "&list_set_status=" +
                status2 +
                "&page=" +
                page,
            httpOptions
        );
    }

    searchCasset(searchText) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        // // // console.log(d);
        const page = 1;
        //  // // console.log(t);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this.httpClient.get(
            this.apiUrl +
                "cassettes?lab_id=" +
                lab_id +
                "&barcode=" +
                searchText,
            httpOptions
        );
    }
}
