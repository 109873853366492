import { L } from "@angular/cdk/keycodes";
import { Cassette } from "../../cassette/cassette";
import { Load } from "../load";
import { StatusService } from "../../status/status.service";
import { StorageService } from "app/main/module/common/service/storage.service";
import { ApiService } from "app/main/module/common/service/api.service";
import * as moment from "moment";
const timeZone = require("moment-timezone");

export const customLoadType = (type: string): string => {
    if (type === "BD_Test" || type === "BI_Test" || type === "AR_Test") {
        const a = type.split("_", 1);

        return a[0];
    } else {
        return type;
    }
};

export const getStatus = (value: string): EStatus => {
    switch (value) {
        case "S":
            return EStatus.SUCCESS;
        case "Scheduled":
            return EStatus.SCHEDULED;
        case "I":
            return EStatus.IN_PROGRESS;
        case "P":
            return EStatus.PENDING;
        case "Started":
            return EStatus.STARTED;
        case "F":
            return EStatus.FAIL;
    }
    return EStatus.NOT_APPLICABLE;
};

export const getColorLoadList = (status: EStatus): string => {
    switch (status) {
        case EStatus.SUCCESS:
            return StatusColor.green;
        case EStatus.SCHEDULED:
            return StatusColor.blueviolet;
        case EStatus.IN_PROGRESS:
            return StatusColor.blue;
        case EStatus.PENDING:
            return StatusColor.dark_orange;
        case EStatus.STARTED:
            return StatusColor.blue;
        case EStatus.FAIL:
            return StatusColor.red;
        case EStatus.SPOILED:
            return StatusColor.light_gray;
    }
    return StatusColor.gray;
};

export const getMessageLoadList = (status: EStatus): string => {
    switch (status) {
        case EStatus.SUCCESS:
            return StatusMessage.SUCCESS;
        case EStatus.SCHEDULED:
            return StatusMessage.SCHEDULED;
        case EStatus.IN_PROGRESS:
            return StatusMessage.IN_PROGRESS;
        case EStatus.PENDING:
            return StatusMessage.PENDING;
        case EStatus.STARTED:
            return StatusMessage.STARTED;
        case EStatus.FAIL:
            return StatusMessage.FAIL;
        case EStatus.SPOILED:
            return StatusMessage.SPOILED;
    }
    return StatusColor.gray;
};

export const getStatusNew = (
    statusBd: string,
    statusBi: string,
    statusEi: string
): EStatus => {
    // // // console.log(');
    if (isAnyFail(statusBd, statusBi, statusEi)) {
        return EStatus.FAIL;
    }
    if (isEiInProgress(statusEi)) {
        return EStatus.IN_PROGRESS;
    }

    if (isBiPending(statusBi)) {
        return EStatus.PENDING;
    }

    if (isAllSuccess(statusBd, statusBi, statusEi)) {
        return EStatus.SUCCESS;
    }

    return EStatus.IN_PROGRESS;
};
export const hasNewStatus = (
    statusBd: string,
    statusBi: string,
    statusEi: string
) => {
    return (
        getStringOrDefault(statusBd, "") ||
        getStringOrDefault(statusBi, "") ||
        getStringOrDefault(statusEi, "")
    );
};
export const getStringOrDefault = (reqkey: any, defaulvalue: any) => {
    const rg = defaulvalue;
    try {
        if (reqkey === undefined || reqkey === null) {
            return defaulvalue;
        } else {
            return reqkey;
        }
    } catch (error) {
        rg == defaulvalue;
    }
};
export const getStatusNewLoadList = (
    status: string,
    statusBd: string,
    statusBi: string,
    statusEi: string
): EStatus => {
    if (status == "Scheduled") return EStatus.SCHEDULED;
    if (hasNewStatus(statusBd, statusBi, statusEi))
        return getStatusNew(statusBd, statusBi, statusEi);

    switch (status) {
        case "Incubator_Pending":
        case "Started":
        case "InProcess":
        case "IncubatorPending":
            return EStatus.PENDING;
        case "Failed":
        case "Incubator_Failed":
            return EStatus.FAIL;
        case "Success":
        case "Ready":
        case "Assigned":
        case "Incubator_Success":
            return EStatus.SUCCESS;

        default:
            return EStatus.IN_PROGRESS;
    }
    // // // console.log(s);
    // if (isAnyFail(statusBd, statusBi, statusEi)) {
    //     return EStatus.FAIL;
    // }
    // if (isStatusInScheduled(status)) {
    //     // // console.log(s);
    //     return EStatus.SCHEDULED;
    // }
    // if (isEiInProgress(statusEi)) {
    //     return EStatus.IN_PROGRESS;
    // }

    // if (isBiPending(statusBi)) {
    //     return EStatus.PENDING;
    // }

    // if (isAllSuccess(statusBd, statusBi, statusEi)) {
    //     return EStatus.SUCCESS;
    // }

    return EStatus.IN_PROGRESS;
};
export const isStatusInScheduled = (status: string): boolean => {
    if (status && status === EStatus.SCHEDULED) {
        return true;
    } else {
        return false;
    }
};

export const defaulttimezone = (timestamp: string): string => {
    // // console.log(p);
    if (timestamp) {
        // new Date('03/17/2021');
        // return timeZone(timestamp).tz(StorageService.getCookie('defaultTz')).format('DD-MM-YYYY');
        // ApiService.getDateStringMomentByTz(t)
        // // console.log(p);.format("DD-MM-YYYY"));

        return moment(timestamp).format("DD-MM-YYYY");
    }
    return "";
};

export const isAnyFail = (
    statusBd: string,
    statusBi: string,
    statusEi: string
): boolean => {
    if (statusBd && statusBd === "F") {
        return true;
    }

    if (statusBi && statusBi === "F") {
        return true;
    }

    if (statusEi && statusEi === "F") {
        return true;
    }

    return false;
};

export const isEiInProgress = (statusEi: string): boolean => {
    if (statusEi && statusEi === EStatus.IN_PROGRESS) {
        return true;
    } else {
        return false;
    }
};

export const isBiPending = (statusBi: string): boolean => {
    return statusBi && statusBi === EStatus.PENDING;
};

export const isAllSuccess = (statusBd, statusBi, statusEi): boolean => {
    if (statusBd && statusBd !== EStatus.SUCCESS) {
        return false;
    }

    if (statusBi && statusBi !== EStatus.SUCCESS) {
        return false;
    }
    if (statusEi && statusEi !== EStatus.SUCCESS) {
        return false;
    }

    return true;
};

export const getCassetteStatus = (cassette: Cassette): CassetteStatus => {
    if (cassette.isSpoiled) {
        return CassetteStatus.RESET;
    }

    const eStatus: EStatus = getStatusNew(
        cassette.bd_status,
        cassette.bi_status,
        cassette.ei_status
    );

    const isAssignDone = cassette.isAssignDone;
    switch (eStatus) {
        case EStatus.FAIL:
            return isAssignDone
                ? CassetteStatus.USED_FAILED
                : CassetteStatus.Failed;
        case EStatus.PENDING:
            return isAssignDone
                ? CassetteStatus.USED_IN_PROGRESS
                : CassetteStatus.IN_PROGRESS_BI_PENDING;
        case EStatus.IN_PROGRESS:
            return CassetteStatus.IN_PROGRESS;
        case EStatus.SUCCESS:
            return isAssignDone
                ? CassetteStatus.USED
                : CassetteStatus.AVAILABLE;
        case EStatus.SPOILED:
            return CassetteStatus.RESET;

        default:
            return CassetteStatus.IN_PROGRESS;
    }
};

export const getCassetteResult = (status: EStatus): string => {
    switch (status) {
        case EStatus.SUCCESS:
            return CassetteResult.SUCCESS;
        case EStatus.IN_PROGRESS:
            return CassetteResult.IN_PROGRESS;
        case EStatus.PENDING:
            return CassetteResult.PENDING;
        case EStatus.STARTED:
            return CassetteResult.STARTED;
        case EStatus.FAIL:
            return CassetteResult.FAIL;
        case EStatus.SPOILED:
            return CassetteResult.SPOILED;
    }
    return CassetteResult.NOT_APPLICABLE;
};

export const getLoadStatus = (load: Load): string => {
    // return getMessageLoadList(getStatusNew(load.statusBd, load.statusBi, load.statusEi));
    return getMessageLoadList(
        getStatusNewLoadList(
            load.load_status,
            load.statusBd,
            load.statusBi,
            load.statusEi
        )
    );
};

export const getLoadColor = (load: Load): string => {
    return getColorLoadList(
        getStatusNewLoadList(
            load.load_status,
            load.statusBd,
            load.statusBi,
            load.statusEi
        )
    );
};

export const getSingleLoadColor = (load: string): string => {
    if (load) {
        return getColorLoadList(getStatus(load));
    } else {
        return "#111111";
    }
};

export const customProgram = (type, program) => {
    if (type === "BI_Test") {
        return type;
    } else {
        return program;
    }
};
