import { Routes } from "@angular/router";
import { AuthGuard } from "./main/module/auth/auth.guard";
import { PatientComponent } from "./main/module/sterilwize/patient/list/patient.component";
import { LabelComponent } from "./main/module/sterilwize/label/label.component";
import { MaintenanceComponent } from "./main/module/auth/maintenance/maintenance.component";
import { AddLoadComponent } from "./main/module/sterilwize/load/addload/addload.component";
import { ViewcassettedetailsComponent } from "./main/module/sterilwize/cassette/detail/viewcassettedetails.component";
import { LoaddetailComponent } from "./main/module/sterilwize/load/loaddetail/loaddetail.component";
import { EmaildDetailComponent } from "./main/module/sterilwize/email/viewemaildetails/vieweEmailDetails.component";
import { PiComponent } from "./main/module/sterilwize/pi/detail/pi.component";
import { ProfileComponent } from "./main/module/sterilwize/profile/profile.component";
import { SetsdetailComponent } from "./main/module/sterilwize/cassette/setsdetail/setsdetail.component";
import { PatientdetailComponent } from "./main/module/sterilwize/patient/patientdetail/patientdetail.component";

export const APP_ROUTES: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "login",
    },
    {
        path: "patient",
        component: PatientComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "viewcassettedetails",
        component: ViewcassettedetailsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "checkout",
        redirectTo: "checkout",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "label",
        redirectTo: "label",
        canActivate: [AuthGuard],
    },

    {
        path: "alert",
        redirectTo: "alert",
        canActivate: [AuthGuard],
    },
    {
        path: "login",
        redirectTo: "login",
    },
    {
        path: "register",
        redirectTo: "register",
    },
    {
        path: "forgot-password",
        redirectTo: "forgot-password",
    },
    //   {
    //     path: 'inventory',
    //     component: InventoryComponent,
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'viewtoolsdetail/: equipname',
    //     component: ViewtoolsdetailComponent,
    //     canActivate: []
    // },
    {
        path: "label",
        component: LabelComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "validate",
        component: MaintenanceComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "addload",
        component: AddLoadComponent,
        canActivate: [AuthGuard],
    },

    // {
    //     path: 'loadverifiedmongo',
    //     component: LoadverifiedmongoComponent,
    // },
    {
        path: "loaddetail",
        component: LoaddetailComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "setsdetail",
        component: SetsdetailComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "connect",
        component: PiComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "support",
        loadChildren: "./main/module/support/support.module#SupportModule",
        canActivate: [AuthGuard],
    },
    {
        path: "rcenter",
        loadChildren:
            "./main/module/sterilwize/rcenter/rcenter.module#RcenterModule",
        canActivate: [AuthGuard],
    },
    {
        path: "email",
        loadChildren: "./main/module/sterilwize/email/Email.module#EmailModule",
        canActivate: [AuthGuard],
    },
    {
        path: "cassette",
        loadChildren:
            "./main/module/sterilwize/cassette/cassette.module#CassetteModule",
        canActivate: [AuthGuard],
    },

    // {
    //     path: 'inoffice',
    //     component: InofficeComponent,
    //     canActivate: [AuthGuard],
    // },
    {
        path: "emaildetail",
        component: EmaildDetailComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
    },
    // {
    //     path: "patientdetail",
    //     component: PatientdetailComponent,
    //     canActivate: [AuthGuard],
    // },
    // {
    //     path: 'Paginationtest',
    //     component: PaginationtestComponent,
    //     canActivate: [AuthGuard]
    // },
    {
        path: "**",
        redirectTo: "login",
    },
];
