import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewEncapsulation,
} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import * as moment from "moment";
import { Globals } from "../../../common/global";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { PiService } from "./pi.service";
import { Pi } from "./pi";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Cassette } from "../../cassette/cassette";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { StorageService } from "app/main/module/common/service/storage.service";

@Component({
    selector: "base-table",
    templateUrl: "pi.component.html",
    styleUrls: ["pi.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PiComponent implements OnInit, OnDestroy, AfterViewInit {
    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map((c) => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    length: any;
    perPageData = this.global.perPageData;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;
    protected extraCta = {
        visibility: false,
        text: "",
    };
    urlData = "";
    isLoading = true;
    hasFirstDataShown = false;
    docId: any;

    cstObservableData: Observable<any>;
    constructor(
        public paginationService: PiService,
        private userService: StorageService,
        private changeDetectorRefs: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private global: Globals,
        public snackBar: MatSnackBar,
        public dialog: MatDialog
    ) {
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.urlData = this.route.snapshot.queryParams.center;
    }

    ngAfterViewInit(): void {
        this.page = 0;
        this.paginationService.setTable(
            this.getTableName(),
            this.getOrderByColumn()
        );

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        // this.paginationService.first();

        this.paginationService.pageObservable.subscribe((pageNumber) => {
            this.page = pageNumber;
            // this.changeDetectorRefs.detectChanges();
        });

        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe((data) => {
            if (data && data.length > 0) {
                this.length = data.length;
                data.forEach((value) => this.updateRow(value));
                this.dataSource.data = data;
                this.hasFirstDataShown = true;
            } else {
                if (this.hasFirstDataShown) {
                    this.openSnackBar("No data available", "OK");
                }
            }
            this.isLoading = false;
        });
    }

    updateRow(row): any {
        row.date = moment(Math.abs(row.dateTimeNum)).format("DD-MM-YYYY");
        row.time = moment(Math.abs(row.dateTimeNum)).format("HH:mm");
    }

    nextPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        // this.paginationService.next();
    }

    prevPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        // this.paginationService.prev();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: Pi, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "FileName",
                header: "File Name",
                cell: (element: Pi, index: number) => `${element.fileName}`,
            },
            {
                columnDef: "Sterilizer",
                header: "Sterilizer",
                cell: (element: Pi, index: number) =>
                    `${element.machineName ? element.machineName : ""}`,
            },
            {
                columnDef: "DateTime",
                header: "Upload DateTime",
                cell: (element: Pi, index: number) =>
                    `${-1 * element.dateTimeNum}`,
            },
            {
                columnDef: "loadNo",
                header: "Load No",
                cell: (element: Pi, index: number) =>
                    `${element.loadNo ? element.loadNo : ""}`,
            },
            {
                columnDef: "cycleNo",
                header: "Cycle No",
                cell: (element: Pi, index: number) =>
                    `${element.cycleNo ? element.cycleNo : ""}`,
            },
            {
                columnDef: "barcode",
                header: "Barcode",
                cell: (element: Pi, index: number) =>
                    `${element.barcode ? element.barcode : ""}`,
            },
            {
                columnDef: "platform",
                header: "Platform",
                cell: (element: Pi, index: number) =>
                    `${element.platform ? element.platform : ""}`,
            },
            {
                columnDef: "verifiedBy",
                header: "Verified By",
                cell: (element: Pi, index: number) =>
                    `${element.verifiedBy ? element.verifiedBy : ""}`,
            },
            {
                columnDef: "verifiedOn",
                header: "Verified On",
                cell: (element: Pi, index: number) =>
                    `${element.verifiedOn ? element.verifiedOn : ""}`,
            },
            {
                columnDef: "verify",
                header: "Verify",
                cell: (element: Pi, index: number) =>
                    this.verifyData(element.isVerified, element.id),
            },
            {
                columnDef: "view",
                header: "View",
                cell: (element: Pi, index: number) =>
                    `${element.fileUrl ? element.fileUrl : ""}`,
            },
            // {columnDef: 'Platform', header: 'Platform', cell: (element: Cassette , index: number) => `${element.platform?element.platform:''}`},
        ];
    }

    getTableName(): string {
        // return 'piFiles';
        return "machineFiles";
    }

    getTitleMatIcon(): string {
        return "label";
    }

    getTitle(): string {
        return "Wize Connect";
    }

    getOrderByColumn(): string {
        return "dateTimeNum";
    }

    openDetail(row) {
        window.open(row.fileUrl, "_blank");
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    viewLOad(barcode: any) {
        this.router.navigate(["/loaddetail"], {
            queryParams: { barcode: barcode },
        });
    }

    verifyData(verified, docId) {
        if (!verified) {
            return docId;
        } else {
            return "";
        }
    }

    openDialogWithRefUpdate(ref: TemplateRef<any>): void {
        this.dialog.open(ref, { width: "400px" });
    }

    verifyAndUpdate(docId) {
        const userName = this.userService.getCookie("name");
        const updateData: any = {
            verifiedBy: userName,
            verifiedOn: new Date().getTime(),
            isVerified: true,
        };
        // this.paginationService.updateMachineFiles(docId, updateData).then(response => {
        //     this.openSnackBar(`Data verified by ${userName}`, 'OK');
        // })
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }
}
