import { Injectable } from "@angular/core";
import { StorageService } from "../../common/service/storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class MaintenanceService {
    baseUrl = environment.baseUrl;

    apiUrl = this.baseUrl + "/api/v1/";
    constructor(
        private userServiceChild: StorageService,
        private httpClient: HttpClient
    ) {}

    getAllDatamaintenanceHistory(page) {
        // // // console.log(e);

        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        // // // console.log(d);
        return this.httpClient.get(
            this.apiUrl + "maintenanceloads?lab_id=" + lab_id + "&page=" + page,
            httpOptions
        );
    }

    getmaintenanceById(loadId) {
        // // console.log(d);

        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        // // // console.log(d);
        return this.httpClient.get(
            this.apiUrl +
                "maintenanceloads?lab_id=" +
                lab_id +
                "&load_id=" +
                loadId,
            httpOptions
        );
    }

    getAllDataOtheremaintenace(page) {
        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        // // // console.log(d);
        return this.httpClient.get(
            this.apiUrl + "othermaintenance?lab_id=" + lab_id + "&page=" + page,
            httpOptions
        );
    }

    getAllDataWaterLinemaintenace(page) {
        // // // console.log(e);

        const token = this.userServiceChild.getCookie("token");
        const lab_id = this.userServiceChild.getCookie("lab_id");
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        // // // console.log(d);
        return this.httpClient.get(
            this.apiUrl +
                "maintenancehistory?lab_id=" +
                lab_id +
                "&type=Waterline" +
                "&page=" +
                page,
            httpOptions
        );
    }
}
