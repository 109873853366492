import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ContentObserver } from "@angular/cdk/observers";
import { fuseAnimations } from "@fuse/animations";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { StatusService } from "../../status/status.service";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { EmailListService } from "./EmailList.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from "../../../base/base-list.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { EmailDto } from "../email.dto";
import { Load } from "../../load/load";
import { Observable, Subject, Subscription } from "rxjs";
import { TextUtils } from "../../../../util/TextUtils";
import { BaseComponent } from "../../../base/base.component";
import { noop as _noop } from "lodash-es";
import { ShareAssessmentDialogComponent } from "../../patient/shareAssessmentLink/share-assessment-dialog.component";
const timeZone = require("moment-timezone");
import { MatDialog } from "@angular/material/dialog";
import { EmaildDetailComponent } from "../viewemaildetails/vieweEmailDetails.component";

@Component({
    selector: "load-table",
    templateUrl: "EmailList.component.html",
    styleUrls: ["EmailList.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class EmailListComponent
    extends BaseComponent
    implements OnDestroy, AfterViewInit, OnInit
{
    isLoading = true;
    page = 0;
    itemsObservable: Observable<any[]>;
    public dataCount: number;
    dataSource: MatTableDataSource<Element>;
    isInitComplete = false;
    isMoreDataAvailable = true;
    limit = 10;
    perPageData = this.globals.perPageData;
    columns = this.getColumn();
    displayedColumns = this.columns.map((c) => c.columnDef);

    subscription: Subscription;
    dataN: Array<any> = [];

    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    isNextPressed = false;
    todayDate: Date = new Date();

    constructor(
        private userSevice: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: EmailListService,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals,
        private readonly contentObserver: ContentObserver,
        private dialog: MatDialog
    ) {
        super(configService, globals);
    }

    ngOnInit(): void {
        this.initData();
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: EmailDto, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Date Time",
                header: "Date Time",
                cell: (element: EmailDto, index: number) =>
                    `${element.dateText + " " + element.timeText}`,
            },
            {
                columnDef: "Subject",
                header: "Subject",
                cell: (element: EmailDto, index: number) =>
                    `${element.message.subject}`,
            },
            {
                columnDef: "To",
                header: "To",
                cell: (element: EmailDto, index: number) => `${element.to}`,
            },
            {
                columnDef: "Body",
                header: "Body",
                cell: (element: EmailDto, index: number) =>
                    `${element.message.html}`,
            },
        ];
    }

    updateRow(row): any {
        row.dateText = timeZone(Math.abs(row.time))
            .tz(this.userSevice.getCookie("defaultTz"))
            .format("DD-MM-YYYY");
        row.timeText = timeZone(Math.abs(row.time))
            .tz(this.userSevice.getCookie("defaultTz"))
            .format("HH:mm");
    }

    viewLOad(emailObj) {
        const urlData = emailObj.doc.id;
        this.router.navigate(["/emaildetail"], { queryParams: { urlData } });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    openDetail(Url) {
        window.open(Url, "_blank");
    }

    initData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.paginationService.init("mail", this.limit, "time", {
            reverse: false,
            prepend: false,
        });

        this.subscription = this.paginationService.data.subscribe((data) => {
            this.dataN = data;
            if (
                this.dataSource &&
                this.dataN &&
                this.dataSource.data.length == this.dataN.length
            ) {
                this.isMoreDataAvailable = false;
            }
            data.forEach((value) => this.updateRow(value));
            this.dataSource = new MatTableDataSource(this.dataN);
            this.dataSource.data = this.dataN;
            if (!this.dataN || data.length === 0) {
                setTimeout(() => {
                    this.isInitComplete = true;
                }, 6000);
            }
            // document.querySelector('mat-table').scrollBy(0, 10000);

            setTimeout(() => {
                this.scrollToBottom();

                this.isLoading = false;
            }, 1000);
        });
        this.page = 0;
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector("mat-table");
            table.scrollBy({
                behavior: "smooth",
                left: 0,
                top: table.scrollHeight,
            });

            // this.contentObserver.observe(document.querySelector('#tableWrapper'))
            //     .subscribe(this.tableContentChanged);
        }
    }

    tableContentChanged() {
        const table = document.querySelector("mat-table");
        table.scrollBy(0, table.scrollHeight);
    }

    ngAfterViewInit(): void {
        // this.initData();
    }

    ngOnDestroy(): void {
        this.page = 0;
    }

    // handleScroll = (scrolled:boolean ) => {
    //     // // console.log(d);
    //     scrolled ? this.getData() : _noop();
    //     // console.time('lastScrolled');
    // }
    hasMore = () => {
        return !this.dataSource || this.dataSource.data.length < this.limit;
    };
    getData() {
        this.isLoading = true;
        this.isNextPressed = true;
        // this.paginationService.more();
    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        // this.paginationService.more();
    }

    isDataAvailable() {
        if (this.dataSource && this.dataSource.data.length > 0) {
            return true;
        }
        return false;
    }

    isMoreDataAvailableFun() {
        if (
            this.isMoreDataAvailable &&
            this.dataSource.data.length % this.limit === 0
        ) {
            return true;
        }
        return false;
    }

    openRefDialog(row) {
        // // console.log(d);
        const dialogRef = this.dialog.open(EmaildDetailComponent, {
            width: "60%",
            data: { docId: row.doc.id },
            maxHeight: "90vh",
        });
        dialogRef.afterClosed().subscribe((data) => {
            // this.initData();
        });
    }
}
