import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewEncapsulation,
} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { ActivatedRoute, Router } from "@angular/router";
import { StatusService } from "../../status/status.service";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { LoadService } from "../load.service";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "../../../common/service/api.service";
import { CassetteListService } from "../../cassette/list/cassette-list.service";
import { Cassette } from "../../cassette/cassette";
import {
    getLoadStatus,
    getMessageLoadList,
    getStatusNewLoadList,
} from "../loadlist/loadutils";
import { StorageService } from "app/main/module/common/service/storage.service";

import { Load } from "../load";

export interface instrumentsdetail {
    barcode: string;
    cassettetype: string;
    status: string;
}
@Component({
    selector: "app-loaddetail",
    templateUrl: "./loaddetail.component.html",
    styleUrls: ["./loaddetail.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class LoaddetailComponent implements OnDestroy, OnInit, AfterViewInit {
    // dataSource = new MatTableDataSource<any>();
    // dataSource: any[];
    loadedcassetteDatadetail: Cassette[] = [];
    loaddetailsStatusData: Load[] = [];
    displayedColumns: string[] = ["barcode", "cassettetype", "status"];
    urlData = "";
    loadObservableData: Observable<any>;
    cstObservableData: Observable<any>;
    program = "";
    status = "";
    loadDetailsData;
    any;
    aiImageUrl = "";
    machineReportUrl = "";
    barcode = "";
    barcodeBd = "";
    barcodeBi = "";
    barcodeEi = "";
    aiValue = 0;
    startedName = "";
    startedTime = "";
    endedName = "";
    endedTime = "";
    loadNo: number;
    cycleNo: number;
    machineName = "";
    cassetteObj = {};
    cassetteObjArr: Array<any> = [];
    isLoading: boolean;
    objData = {};
    reportImageUrl = "";
    public href: string = "";
    currentPageURL: string;
    loadDetail: any;
    isLoginFailed: boolean;
    errorMessage: any;
    getbarcodefromParam: string;
    departments: any;
    categories: any;
    subcategories: any;
    cassetteDetail: Array<any>;
    // cassetteDetailnew: []=[{barcode:string,}];
    instruments: any = [];
    cassette_ids: any;
    InstrumentBarcode: any;
    set_status: any;
    assignedBy: any;
    instrument_data: boolean;
    cassetteDetailnew: instrumentsdetail[] = [];
    dataSource = new MatTableDataSource<any>(this.cassetteDetailnew);
    verified: string;
    hasFirstDataShown: boolean = false;
    result: any;

    constructor(
        public cassetteListService: CassetteListService,
        public loadListService: LoadService,
        private route: ActivatedRoute,
        private router: Router,
        public statusService: StatusService,
        private userService: StorageService,
        private location: Location,
        public dialog: MatDialog,
        public apiService: ApiService
    ) {
        this.isLoading = true;
    }
    ngOnDestroy(): void {
        this.loadObservableData;
    }

    ngOnInit(): void {
        this.hasFirstDataShown = false;
        // this.urlData = this.route.snapshot.queryParams.load_barcode;
        // // // console.log(a);
        this.href = this.router.url;
        // // // console.log(l);
        this.currentPageURL = window.location.href.toString();

        this.getbarcodefromParam = this.currentPageURL.split("=")[1];
        //   // // console.log(m);
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.getAllData(this.getbarcodefromParam);
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
    }

    getAllData(loadId) {
        this.loadListService.getLoadDetailByLabId(loadId).subscribe((a) => {
            //    // // console.log(a);
            Object.entries(a).find(([key, value]) => {
                //     if(key == 'total_pages'){
                //    this.totalPage = value;
                //     }
                if (key == "data") {
                    this.loadDetail = value;
                    // // console.log(l);
                    this.loadDetail.forEach((element) => {
                        this.hasFirstDataShown = true;
                        this.cassette_ids = element.cassette_ids;
                        //  // // console.log(d);
                        this.isLoading = false;
                        //  this.loadDetailsData = resultData[0];
                        if (element.verified == false) {
                            this.verified = "No";
                        } else if (element.verified == true) {
                            this.verified = "Yes";
                        }
                        this.barcode = element.load_id;
                        this.program = element.load_program;
                        this.loadNo = element.load_number;
                        this.status = this.getloadStatus(
                            element.load_status,
                            element.bd_status,
                            element.bi_status,
                            element.ei_status
                        );
                        this.machineName = element.machine_name;
                        this.cycleNo = element.cycle_number;
                        this.aiImageUrl = element.image_url;
                        this.barcodeBi = element.bi_barcode;
                        this.barcodeEi = element.ei_barcode;
                        //  this.machineReportUrl = resultData[0].machineReportUrl;
                        this.reportImageUrl = element.load_report_url;
                        if (element.started_by) {
                            this.startedName = element.started_by.name;
                            // this.startedTime = resultData[0].startedBy.time;
                            const date = parseInt(element.started_by.timestamp);
                            this.startedTime =
                                this.apiService.getDateStringMomentByTz(date);
                        }

                        if (element.updated_by) {
                            this.endedName = element.updated_by.name;
                            // this.endedTime = resultData[0].updatedBy.time;
                            const date = parseInt(element.updated_by.timestamp);
                            this.endedTime =
                                this.apiService.getDateStringMomentByTz(date);
                        }

                        // // console.log(e);
                        // this.getInstruments(this.cassette_ids)
                    });
                } else {
                    // // console.log(");
                }
            });
        });

        (err) => {
            this.errorMessage = err.error.message;
            this.isLoginFailed = true;
        };
    }

    getloadStatus(load_status, statusBd, statusBi, statusEi) {
        return getMessageLoadList(
            getStatusNewLoadList(load_status, statusBd, statusBi, statusEi)
        );
    }

    back(): void {
        this.location.back();
    }
    getLoadStatus(load: Cassette): string {
        // // // console.log(d);)
        return this.statusService.getCassetteStatus(load);
    }

    ngAfterViewInit(): void {
        // this.getAllData(this.getbarcodefromParam)
        this.loadListService
            .getLoadDetailByLabId(this.getbarcodefromParam)
            .subscribe((a) => {
                //    // // console.log(a);
                Object.entries(a).find(([key, value]) => {
                    //     if(key == 'total_pages'){
                    //    this.totalPage = value;
                    //     }
                    if (key == "data") {
                        this.loadDetail = value;
                        // // console.log(l);
                        this.loadDetail.forEach((element) => {
                            //  // // console.log(d);
                            // // console.log(s);
                            this.isLoading = false;
                            //  this.loadDetailsData = resultData[0];
                            this.barcode = element.load_id;
                            this.program = element.load_program;
                            this.loadNo = element.load_number;
                            if (element.verified == false) {
                                this.verified = "No";
                            } else if (element.verified == true) {
                                this.verified = "Yes";
                            }
                            // this.status = resultData[0].status;
                            this.status = this.getloadStatus(
                                element.load_status,
                                element.bd_status,
                                element.bi_status,
                                element.ei_status
                            );
                            this.machineName = element.machine_name;
                            this.cycleNo = element.cycle_number;
                            this.aiImageUrl = element.image_url;
                            this.barcodeBi = element.bi_barcode;
                            this.barcodeEi = element.ei_barcode;
                            //  this.machineReportUrl = resultData[0].machineReportUrl;
                            this.reportImageUrl = element.load_report_url;
                            if (element.started_by) {
                                this.startedName = element.started_by.name;
                                // this.startedTime = resultData[0].startedBy.time;
                                const date = parseInt(
                                    element.started_by.timestamp
                                );
                                this.startedTime =
                                    this.apiService.getDateStringMomentByTz(
                                        date
                                    );
                            }

                            if (element.updated_by) {
                                this.endedName = element.updated_by.name;
                                // this.endedTime = resultData[0].updatedBy.time;
                                const date = parseInt(
                                    element.updated_by.timestamp
                                );
                                this.endedTime =
                                    this.apiService.getDateStringMomentByTz(
                                        date
                                    );
                            }

                            if (element.cassette_ids) {
                                // // console.log(s);

                                this.apiService
                                    .getCassetteByBarcodeAndLoadNo(
                                        element.cassette_ids
                                    )
                                    .subscribe({
                                        next: (a) => {
                                            // console.log(a);
                                            Object.entries(a).find(
                                                ([key, value]) => {
                                                    if (key == "data") {
                                                        // console.log(e);
                                                        value.forEach(
                                                            (element) => {
                                                                // this.cassetteObjArr.push({barcode:element.barcode,cassettetype:element.cassette_type,status:element.cassette_status})

                                                                this.objData = {
                                                                    cassettetype:
                                                                        element.cassette_type,
                                                                    barcode:
                                                                        element.barcode,
                                                                    status: this.statusService.getCassetteStatus(
                                                                        element
                                                                    ),
                                                                };
                                                                this.cassetteObjArr.push(
                                                                    this.objData
                                                                );
                                                                const sorted =
                                                                    this.cassetteObjArr.sort(
                                                                        (
                                                                            t1,
                                                                            t2
                                                                        ) => {
                                                                            const name1 =
                                                                                t1.barcode.toLowerCase();
                                                                            const name2 =
                                                                                t2.barcode.toLowerCase();
                                                                            if (
                                                                                name1 >
                                                                                name2
                                                                            ) {
                                                                                return 1;
                                                                            }
                                                                            if (
                                                                                name1 <
                                                                                name2
                                                                            ) {
                                                                                return -1;
                                                                            }
                                                                            return 0;
                                                                        }
                                                                    );
                                                                // this.dataSource.data = this.cassetteObjArr;
                                                                this.dataSource.data =
                                                                    sorted;
                                                                this.objData =
                                                                    {};
                                                            }
                                                        );
                                                    }
                                                }
                                            );

                                            // data.array.forEach(element => {

                                            // });

                                            //         this.cstObservableData.subscribe(async data => {
                                            //             // this.cassetteObjArr.length = 0;
                                            //             const allData: any = data[0];
                                            //             // const eStatus: EStatus = this.statusService.getStatusNew(resultData[0].barcodeBd, resultData[0].barcodeBi, resultData[0].barcodeEi);
                                            //             // const customStat = this.statusService.getCassetteResult(eStatus);
                                            //             this.objData = { cassettetype: allData.type, barcode: allData.barcode, status: this.statusService.getCassetteStatus(allData) };
                                            //              this.cassetteObjArr.push(this.objData);
                                            //             const sorted = this.cassetteObjArr.sort((t1, t2) => {
                                            //                 const name1 = t1.barcode.toLowerCase();
                                            //                 const name2 = t2.barcode.toLowerCase();
                                            //                 if (name1 > name2) { return 1; }
                                            //                 if (name1 < name2) { return -1; }
                                            //                 return 0;
                                            //             });
                                            //             // this.dataSource.data = this.cassetteObjArr;
                                            //             this.dataSource.data = sorted;
                                            //             this.objData = {};

                                            //         });
                                            //     });
                                            // }
                                        },
                                    });
                            }
                        });
                    } else {
                        // // console.log(");
                    }
                });
            });

        (err) => {
            this.errorMessage = err.error.message;
            this.isLoginFailed = true;
        };
        // this.loadObservableData = this.loadListService.getLoadById(this.urlData);
        // this.loadObservableData.subscribe(resultData => {
        //     this.loadDetailsData = resultData[0];
        //     this.barcode = resultData[0].barcode;
        //     this.program = resultData[0].program;
        //     this.loadNo = resultData[0].loadNo;
        //     // this.status = resultData[0].status;
        //     this.status = this.getLoadStatus(resultData[0]);
        //     this.machineName = resultData[0].machineName;
        //     this.cycleNo = resultData[0].cycleNo;
        //     this.aiImageUrl = resultData[0].aiImageUrl;
        //     this.barcodeBi = resultData[0].barcodeBi;
        //     this.barcodeEi = resultData[0].barcodeEi;
        //     this.machineReportUrl = resultData[0].machineReportUrl;
        //     this.reportImageUrl = resultData[0].reportImageUrl;
        //     if (resultData[0].startedBy) {
        //         this.startedName = resultData[0].startedBy.name;
        //         // this.startedTime = resultData[0].startedBy.time;
        //         this.startedTime = this.apiService.getDateStringMomentByTz(resultData[0].startedBy.time);
        //     }
        //     if (resultData[0].updatedBy) {
        //         this.endedName = resultData[0].updatedBy.name;
        //         // this.endedTime = resultData[0].updatedBy.time;
        //         this.endedTime = this.apiService.getDateStringMomentByTz(resultData[0].updatedBy.time);
        //     }

        //     if (resultData[0].cassetteIds) {
        //         const cassetteBc = resultData[0].cassetteIds.map(data => data.split('_')[0]);
        //         cassetteBc.map(barcode => {
        //             this.cstObservableData = this.loadListService.getCassetteByBarcodeAndLoadNo(barcode, this.loadNo);
        //             this.cstObservableData.subscribe(async data => {
        //                 // this.cassetteObjArr.length = 0;
        //                 const allData: any = data[0];
        //                 // const eStatus: EStatus = this.statusService.getStatusNew(resultData[0].barcodeBd, resultData[0].barcodeBi, resultData[0].barcodeEi);
        //                 // const customStat = this.statusService.getCassetteResult(eStatus);
        //                 this.objData = { cassettetype: allData.type, barcode: allData.barcode, status: this.statusService.getCassetteStatus(allData) };
        //                  this.cassetteObjArr.push(this.objData);
        //                 const sorted = this.cassetteObjArr.sort((t1, t2) => {
        //                     const name1 = t1.barcode.toLowerCase();
        //                     const name2 = t2.barcode.toLowerCase();
        //                     if (name1 > name2) { return 1; }
        //                     if (name1 < name2) { return -1; }
        //                     return 0;
        //                 });
        //                 // this.dataSource.data = this.cassetteObjArr;
        //                 this.dataSource.data = sorted;
        //                 this.objData = {};

        //             });
        //         });
        //     }

        // });
    }

    // getLoadStatus(load): string {
    //     return this.statusService.getMessage(this.statusService.getStatusNewTest(load.status,load.statusBd, load.statusBi, load.statusEi));
    // }

    goBack() {
        this.router.navigate(["/load"]).then(() => {
            //  this.ngOnDestroy();
            this.ngOnInit();
            //  window.location.reload();
        });
    }
    hideLoader() {
        this.isLoading = false;
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.dialog.open(ref, {
            disableClose: true,
            width: "50%",
        });
    }
}
