import { AfterViewInit, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ConfigService } from "../common/config/config.service";
import { Globals } from "../common/global";

export class BaseComponent implements OnInit, AfterViewInit {
    constructor(private baseConfigService: ConfigService,
        private baseGlobals: Globals) {
    }


    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        // if (this.baseGlobals.cassetteConfig) {
        //     this.ngAfterViewInitViaBase();
        // } else {
        //     this.baseConfigService.getCassetteConfig('cassette').valueChanges().subscribe((cassetteConfig: any) => {

        //         this.baseGlobals.cassetteConfig = cassetteConfig;
        //         this.ngAfterViewInitViaBase();
        //     });

        //     this.baseConfigService.getLabConfig('lab').valueChanges().subscribe((labConfig: any) => {

        //         this.baseGlobals.labConfig = labConfig;
        //     });

        // }
    }


    ngAfterViewInitViaBase(): void {

    }





}
