import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    HostListener,
} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import { HubwizeDto } from "./hubwizeDto";
import * as moment from "moment";
import { MatTableDataSource } from "@angular/material/table";
import { HubwizeService } from "./hubwize.service";
import { Observable, Subject, Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from "../../../base/base-list.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { BaseComponent } from "../../../base/base.component";
import { noop as _noop } from "lodash-es";
// import { PaginationService} from '../../../common/pagination/pagination.service';
import { FromEventTarget } from "rxjs/internal/observable/fromEvent";

@Component({
    selector: "label-table",
    templateUrl: "hubwize.component.html",
    styleUrls: ["hubwize.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class HubwizeComponent
    extends BaseComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    constructor(
        public paginationService: HubwizeService,
        private changeDetectorRefs: ChangeDetectorRef,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals
    ) {
        super(configService, globals);
    }
    dataSource: MatTableDataSource<Element>;
    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map((c) => c.columnDef);
    length: any;
    perPageData = this.globals.perPageData;
    protected extraCta = {
        visibility: false,
        text: "",
    };
    limit = 1000;
    enableInfoMsg = false;
    full = false;
    hasFirstDataShown = true;
    dataN: Array<any> = [];

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
    }

    subscription: Subscription;
    initData() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        // this.paginationServiceV2.reset();
        // this.paginationServiceV2.init(this.paginationService.getCollectionPath(), 'date', { reverse: false, prepend: false })

        // this.subscription = this.paginationServiceV2.data.subscribe(data => {
        //    // // // console.log(a);
        //     this.dataN = data;
        //     this.dataSource = new MatTableDataSource(this.dataN);
        //     this.dataSource.data = this.dataN;
        //     if(!this.dataN || data.length === 0){
        //         this.enableInfoMsg = false;
        //         this.hasFirstDataShown = false;
        //         setTimeout(()=> {
        //             this.enableInfoMsg = true;
        //             this.hasFirstDataShown = true;
        //         },3000)
        //     }
        // });
        // this.page = 0;
    }

    ngAfterViewInit(): void {
        // super.ngAfterViewInit();
        this.initData();
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: HubwizeDto, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "location",
                header: "Location",
                cell: (element: HubwizeDto, index: number) =>
                    `${element.location}`,
            },
            {
                columnDef: "barcode",
                header: "Barcode",
                cell: (element: HubwizeDto, index: number) =>
                    `${element.query}`,
            },
            {
                columnDef: "dateTime",
                header: "Date Time",
                cell: (element: HubwizeDto, index: number) =>
                    `${this.getDate(element.date)}`,
            },
        ];
    }

    updateRow(row): any {}

    getTitleMatIcon(): string {
        return "label";
    }

    getTitle(): string {
        return "HubWize Listener";
    }

    getDate(numberValue): string {
        if (numberValue === 0) {
            return "-";
        } else {
            return moment(Math.abs(numberValue)).format("DD-MM-YYYY | HH:mm");
        }
    }

    getRowBackgroundColor(element): string {
        if (element.currentCount < 10) {
            return "#F29E9E";
        } else {
            return "#FFFFFF";
        }
    }

    protected getPaginationService() {
        return this.paginationService;
    }

    /**
     * On ngAfterViewChecked
     */
    ngAfterViewChecked() {
        // your code to update the model
        this.changeDetectorRefs.detectChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {}

    handleScroll = (scrolled: boolean) => {
        // // console.log(d);
        scrolled ? this.getData() : _noop();
        // console.time('lastScrolled');
    };
    hasMore = () => {
        return !this.dataSource || this.dataSource.data.length < this.limit;
    };
    getData() {
        // this.paginationServiceV2.more();
        // this.dataSource.sort = this.sort;
    }
}
