import { Component, HostListener, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Patient } from "../patient";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PatientService } from "../patient.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TextUtils } from "../../../../util/TextUtils";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "app/main/module/common/service/storage.service";
import * as moment from "moment";
import {
    APP_DATE_FORMATS,
    AppDateAdapter,
} from "../../report/format-datepicker";
import {
    DateAdapter,
    ErrorStateMatcher,
    MAT_DATE_FORMATS,
} from "@angular/material/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from "../../../common/global";
import { Router } from "@angular/router";

const timeZone = require("moment-timezone");
@Component({
    selector: "add-patient-dialog",
    templateUrl: "add-patient-dialog.component.html",
    styleUrls: ["add-patient-dialog.component.scss"],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
})
export class AddPatientDialogComponent implements OnInit {
    [x: string]: any;
    patientForm: FormGroup;
    platform = "web";
    @Input()
    min: "D" | null;
    today = new Date();
    isEnterPressed = false;
    hasFirstDataShown: Boolean;
    dobStamp = 0;
    dobString = "";
    deleted: boolean;
    rowId: any;
    memberInfo: any;
    errorMessage: any;

    constructor(
        public dialogRef: MatDialogRef<AddPatientDialogComponent>,
        private _formBuilder: FormBuilder,
        public patientService: PatientService,
        public snackBar: MatSnackBar,
        private userService: StorageService,
        private cookieService: CookieService,
        private global: Globals,
        private router: Router,
        private httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: Patient
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Validators.pattern(/^\S*$/)
        this.patientForm = this._formBuilder.group({
            // Validators.pattern(/^\S+/)
            firstName: [
                "",
                [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]/)],
            ],
            id: ["", [Validators.required, Validators.pattern(/^\S*$/)], ""],
            lastName: ["", [Validators.required]],
            emailId: [
                "",
                [
                    Validators.email,
                    Validators.pattern(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                    ),
                ],
            ],
            mobileNo: [
                "",
                [
                    // Validators.required,
                    Validators.pattern("^((\\+91-?))?[0-9]{10}$"),
                ],
            ],
            // mobileNo: ["", [Validators.min(7)]],
            // mobileNo: ['', [Validators.pattern(/^\d{10}$/)], ''],
            dob: ["", ""],
        });

        // this.setFocus();
    }

    addPatientDetail() {
        this.hasFirstDataShown = false;
        const dobDateMonthYear = [];
        const patientid = this.patientForm.value.id.trim().toUpperCase();
        if (this.patientForm.value.dob) {
            // // // console.log(b);

            const date = new Date(this.patientForm.value.dob);
            this.dobString = moment(
                this.patientForm.value.dob,
                "DD/MM/YYYY"
            ).format("DD/MM/YYYY");
            this.dobStamp = date.getTime();
        }
        this.patientService.getPatientdataById(patientid).subscribe(
            (a) => {
                // // console.log(a);
                Object.entries(a).find(([key, value]) => {
                    var Message = "";
                    if (key == "message") {
                        Message = value;
                    }
                    this.memberInfo = value;
                    if (key == "data" && !this.memberInfo.isNullOrEmpty) {
                        // this.memberInfo = value;
                        // // console.log(o);
                        if (!this.memberInfo.isNullOrEmpty) {
                            this.memberInfo.forEach((element) => {
                                // // console.log(t);
                                if (element && element.deleted == true) {
                                    const msg =
                                        "The Patient id '" +
                                        element.patient_id +
                                        "' already exists and it was deleted earlier. To add this patient again, please contact SterilWize Support Team.";
                                    this.openSnackBar(msg, "OK");
                                    this.hasFirstDataShown = true;
                                    // this.patientForm.focus()
                                    if (this.patientForm.value.id != "") {
                                        this.setFocus(
                                            this.patientForm.value.id
                                        );
                                    }
                                } else if (element) {
                                    const msg = "Patient ID Already Exist. ";
                                    this.openSnackBar(msg, "OK");
                                    this.hasFirstDataShown = true;
                                    if (this.patientForm.value.id != "")
                                        this.setFocus(
                                            this.patientForm.value.id
                                        );
                                }
                            });
                        } else {
                        }
                    } else if (Message == "No data Found") {
                        const patientData = {
                            patient_id: this.patientForm.value.id
                                .trim()
                                .toUpperCase(),
                            first_name: this.patientForm.value.firstName
                                .trim()
                                .toUpperCase(),
                            last_name: this.patientForm.value.lastName
                                .trim()
                                .toUpperCase(),
                            email_id: this.patientForm.value.emailId,
                            mobile_number: this.patientForm.value.mobileNo
                                ? this.patientForm.value.mobileNo.toString()
                                : "",
                            date_of_birth: this.dobString,
                            // docId: this.patientForm.value.id + this.patientForm.value.firstName + this.patientForm.value.lastName,
                            // idMap: {}, // initialized
                            platform: this.platform,
                            added_by: this.userService.getCookie("email")
                                ? this.userService.getCookie("email")
                                : "NA",
                            added: (1 * new Date().getTime()).toString(),
                            // dobString: this.dobString,
                            deleted: false,
                            updated_by: this.userService.getCookie("email")
                                ? this.userService.getCookie("email")
                                : "NA",
                            updated: (1 * new Date().getTime()).toString(),
                            // rowId: this.rowId,
                        };
                        this.patientService
                            .addPatient(patientData)
                            .subscribe((data) => {
                                // // console.log(a);
                                Object.entries(data).find(([key, value]) => {
                                    if (
                                        key == "status" &&
                                        (value == "success" ||
                                            value == "Success")
                                    ) {
                                        const msg =
                                            "Patient Added Successfully";
                                        this.openSnackBar(msg, "OK");
                                        // this.loadingSpinner=false;
                                        this.patientForm.reset();
                                        this.goBack();
                                        // this.router.navigate(['/patient']);
                                        // this.patientForm.reset();
                                        this.hasFirstDataShown = true;
                                        window.location.reload();
                                        // this.ngOnInit();
                                    } else {
                                    }
                                });
                            });
                    } else {
                        // // console.log(e);
                    }
                });
            },

            (err) => {
                this.hasFirstDataShown = true;

                this.errorMessage = err.error.message;
            }
        );
    }

    goBack() {
        this.router.navigate(["/patient"]).then(() => {
            // this.ngOnDestroy();
            this.ngOnInit();
            // window.location.reload();
        });
    }
    toUpperCaseString(str) {
        return str.trim().toUpperCase();
    }

    openSnackBar(message: string, action: string): void {
        this.hasFirstDataShown = true;
        this.snackBar.open(message, action, {
            duration: 10000,
        });
    }

    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    getMapValue(stringData) {
        const param = stringData.replace(/[^a-zA-Z0-9]/g, "");
        const obj = {};
        for (let j = 0; j <= param.length - 1; j++) {
            let emptyString = "";
            for (let k = 0; k <= j; k++) {
                emptyString += param[k];
            }

            obj[emptyString] = true;
        }
        return obj;
    }

    numericOnly(event): boolean {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key.includes("Enter") && event.code.includes("Enter")) {
            this.isEnterPressed = true;
            if (this.patientForm.valid) {
                // this.addPatientDetail();
                // this.patientForm.disable();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    isNullOrEmpty(event, firstName) {
        if (event.keyCode === 32 && firstName.length + 1 === 1) {
            const patt = /^\S+/;
            const result = patt.test(event.key);
            return result;
        } else {
            return event.key;
        }
    }

    setFocus(id) {
        const element = document.getElementById("id");
        element.focus();
    }

    // isNullOrEmpty(event): boolean {
    //     const patt = /^([0-9])$/;
    //     const result = patt.test(event.key);
    //     return result;
    // }
}
