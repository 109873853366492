import {Injectable} from '@angular/core';
// import {AngularFirestore} from '@angular/fire/firestore';
// import {AngularFireAuth} from '@angular/fire/auth';
import {StorageService} from '../../common/service/storage.service';
// import {AngularFireFunctions} from '@angular/fire/functions';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../common/global';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';


@Injectable()
export class AnalyticsService {

    labs: string[] = [];
    chartPath = 'lab/' + this.userService.getCookie("lab") + '/report/chart/yearlyLoadChart';
    http: any;
    date = new Date();
    from = this.date.getFullYear() - 2;
    to = this.date.getFullYear();
    countVal: Array<any> = [];
    allData: any;
    result: any;
    private itemsSubject: BehaviorSubject<any>;
    public itemsObservable: Observable<any>;

    constructor(
       
        public userService: StorageService,
        public httpClient: HttpClient,
        public globals: Globals,
    ) {}

    // getThreeYearsDataChart(labsList) {
    //     const data = { labsList: labsList };
    //     return this.httpClient.post(this.globals.constUrl + 'getYearlyFinalData', data);
    // }

    // getLoadchartYears(): Observable<any[]> {

    //     this.itemsSubject = new BehaviorSubject([]);
    //     this.itemsObservable = this.itemsSubject.asObservable();

    //     return this.afs.collection(this.chartPath, ref => ref.orderBy('month')).snapshotChanges()
    //         .pipe(map(actions => {
    //             return actions.map((a: any) => {
    //                 return a.payload.doc.data();
    //             });

    //         }));
    // }
}
