import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { AsapMaterialModule } from '../../../../material-module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {DashboardComponent} from './dashboard.component';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {AgmCoreModule} from '@agm/core';
import {SharedModule} from '../../common/shared.module';
import {CellService} from './cell/cell.service';
import {CellComponent} from './cell/cell.component';
import {ImageComponent} from './cell/image/image.component';
import {WasherComponent} from './cell/washer/washer.component';
import {SterilizerComponent} from './cell/sterilizer/sterilizer.component';
import {LoadService} from '../load/load.service';
import {IncubatorComponent} from './cell/incubator/incubator.component';
import {TutorialComponent} from './cell/tutorial/tutorial.component';
import {AuthGuard} from '../../auth/auth.guard';
import { ReloadGuard } from './reload.guard';
import { LoadchartdashboardComponent } from './loadchartdashboard/loadchartdashboard.component';
// import { ChartsModule } from 'ng2-charts';

const routes = [
    {
        path     : 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        DashboardComponent,
        CellComponent,
        WasherComponent,
        ImageComponent,
        SterilizerComponent,
        IncubatorComponent,
        TutorialComponent,
        LoadchartdashboardComponent,
        // ChartsModule
        
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        AsapMaterialModule,
        NgxDaterangepickerMd,
        SharedModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),

        FuseSharedModule,
        FuseWidgetModule,

    ],
    exports     : [
        DashboardComponent
    ],
    providers   : [
        CellService,
        LoadService
]
})

export class DashboardModule
{
}
